import MabinsoModel from '../../app/models/MabinsoModel';
import Globals from '../../../utils/Globals';

class RoleModel extends MabinsoModel {
    backend = true;
    urlRoot = Globals.baseUrl + 'roles/role';
    defaults = () => ({
        name: '',
        description: '',
    });
}
export default RoleModel;
