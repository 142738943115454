import $ from 'jquery';
import Backbone from 'backbone';
import InputView from '../../app/views/InputView';

const EditReportCategoryView = Backbone.View.extend({
    initialize(options) {
        this.options = options || [];
    },
    render() {
        const view = this;
        InputView.show({
            title: $._('report.alert.title.add.reportcategory'),
            yesString: $._('report.list.button.add.reportcategory'),
            model: view.model,
            yesFunc(modal) {
                view.saveCategory(modal);
                // view.uploadReport();
            }
        });
        view.setElement('.modalMessage');
    },
    events: {
        'click .modalYes': 'saveCategory',
        'submit form': 'saveCategory'
    },
    saveCategory(modal) {
        const view = this;
        view.setElement('.modalMessage');
        if (!this.model.setForm(view.$('form'))) {
            modal.$('.modalButtons').children().removeAttr('disabled');
        }
        this.model.unset('reportsJson');
        this.model.save({
            success(formMessage) {
                if (formMessage.showErrors()) {
                    view.options.reportListView.render();
                    $.modal.close();
                    view.remove();
                } else {
                    modal.$('.modalButtons').children().removeAttr('disabled');
                }
            }
        });
        return false;
    }
});
export default EditReportCategoryView;
