import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import { Ajax } from '@mabinso-registry/module-commons';
import FormMessage from '../../../utils/FormMessage';
import Globals from '../../../utils/Globals';

const getUrl = (object) => {
    if (!(object && object.url)) return null;
    return _.isFunction(object.url) ? object.url() : object.url;
};

const urlError = () => {
    throw new Error('A "url" property or function must be specified');
};

class MabinsoModel extends Backbone.Model {
    modelName = 'model';
    urlRoot: (() => string) | string = '';
    form: any = null;
    doValidate = true;
    backend = false;
    static getBaseWS() {
        return Globals.baseUrl.replace('http:', 'ws:').replace('https:', 'wss:');
    }
    getId(): number {
        return this.get('id');
    }
    setId(id: number) {
        this.set('id', id);
    }
    setForm(form: any, validate?: boolean) {
        this.doValidate = !(validate !== undefined && validate === false);
        const items = this.serializeObject(form);
        // console.log(items);
        this.set(items, { silent: true });
        this.form = form;
    }
    validate(): string | undefined {
        if (this.form != null) {
            if (this.doValidate && !this.form.validationEngine('validate')) {
                this.form.validationEngine('detach');
                this.form.validationEngine('attach', { scroll: false });
                return 'form.error6';
            }
        }
    }
    async fetchAsync(options?: Backbone.ModelFetchOptions | undefined): Promise<this> {
        if (options == null) {
            options = {};
        }
        let url = getUrl(this) || urlError();
        if (options.data) {
            url += '?' + new URLSearchParams(options.data).toString();
        }
        const ajax = await new Ajax({ baseUrl: '' }).get({
            url,
        });
        const data = await ajax.json();
        if (!this.set(data, options)) return null;
        this.trigger('sync', this, data, options);
        return this;
    }
    fetchPromise(options?: Backbone.ModelFetchOptions | undefined): Promise<this> {
        if (options == null) {
            options = {};
        }
        const prom = new Promise<this>((resolve, reject) => {
            this.fetch({
                ...options,
                success: (model) => {
                    resolve(model);
                },
                error: (err, err2) => {
                    reject(err2);
                },
            });
        });
        return prom;
    }
    save(options: any) {
        if (_.isFunction(options)) {
            console.log('----------> Save is a function');
            options = {
                success: options,
            };
        }
        const mythis = this;
        if (this.form != null) {
            const oldSuccess = options.success;
            options.success = (model: any, response: any) => {
                const formMessage = new FormMessage(response, mythis.modelName);
                if (_.isFunction(oldSuccess)) oldSuccess(formMessage, model);
            };
            options.error = (err: any, err2: any) => {
                console.log(err2);
            };
            return super.save({}, options);
        }
        return super.save({}, options);
    }
    /*
    get: function(key){
        var model = this;
        var value = Backbone.Model.prototype.get.call(this,key);
        if (_.isUndefined(value)){
            var myfunc = "get"+key.charAt(0).toUpperCase()+key.slice(1,key.length);
            if (!_.isUndefined(model[myfunc]) && _.isFunction(model[myfunc])){
                return model[myfunc]();
            }
        }else{
            return value;
        }
    }, */
    getDate(key: string) {
        const value = this.get(key);
        console.log(value);
        const date = Date.parse(value);
        return date == null ? null : date.toString((Date as any).CultureInfo.formatPatterns.shortDate);
    }
    getTime(key: string) {
        const value = this.get(key);
        const date = Date.parse(value);
        return date == null ? null : date.toString((Date as any).CultureInfo.formatPatterns.shortTime);
    }
    serializeObject(form: any) {
        if (!_.isUndefined(this.backend) && this.backend) {
            const o: any = {};
            const a = $(form).serializeArray();
            // var view = this;
            $.each(a, function serialize() {
                const myvalue = this.value;
                /* if (view.isNumeric(this.value)){
                    myvalue = parseFloat(this.value);
                } */
                let myname = this.name;
                let myInnername = 'id';
                let isArray = false;
                const matches = myname.match(/\[(.*?)\]/);
                let arrayNumber = null;
                if (matches) {
                    const splited = myname.split('.');
                    const splitedName = myname.split('[');
                    if (splited.length > 1 && splitedName.length > 0) {
                        [, arrayNumber] = matches;
                        isArray = true;
                        [myname] = splitedName;
                        [, myInnername] = splited;
                    }
                }
                if (isArray) {
                    if (o[myname] === undefined) {
                        o[myname] = [];
                    }
                    const innerName: any = {};
                    if (arrayNumber !== undefined && arrayNumber != null && arrayNumber !== '') {
                        if (o[myname][arrayNumber] !== undefined) {
                            o[myname][arrayNumber][myInnername] = myvalue || '';
                        } else {
                            innerName[myInnername] = myvalue || '';
                            o[myname][arrayNumber] = innerName;
                        }
                    } else {
                        if (!o[myname].push) {
                            o[myname] = [o[myname]];
                        }

                        innerName[myInnername] = myvalue || '';
                        o[myname].push(innerName);
                    }
                } else {
                    o[myname] = myvalue;
                }
            });
            _.each(o, (item, idx) => {
                if (_.isArray(item)) {
                    // console.log(_.compact(item));
                    o[idx] = _.compact(item);
                }
            });
            return o;
        }
        return (_ as any).serializeObject(form);
    }
    static isNumeric(n: string) {
        return !_.isNaN(parseFloat(n)) && _.isFinite(n);
    }
}
export default MabinsoModel;
