import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import UserCollection from '../models/UserCollection';
import DetailView from './DetailView';
import ConfirmView from '../../app/views/ConfirmView';
import AlertView from '../../app/views/AlertView';
import html from '../templates/list_view.template';
import Globals from '../../../utils/Globals';

const UserView = Backbone.View.extend({
    detailView: null,
    initialize() {
        $('body').scrollTo(0, 0);
    },
    render() {
        const collection = new UserCollection();
        const view = this;
        $(this.el).spinner();
        collection.fetch({
            success(collection2) {
                view.$el.html(_.template(html)({ models: collection2, $, Actions: Globals.actions }));
                view.setElement('#user-list-view');
                $('.spalte_links_280 li').uiAktivAnzeiger('init');
                view.detailView = new DetailView({ listView: view });
                if (collection2.length === 1 && !Globals.actions.can('user.admin.global') && !Globals.actions.can('user.admin.schema')) {
                    // var fmodel = collection.at(0);
                    // view.detailView.render(fmodel.id);
                    $('.spalte_links_280 li').first().trigger('click');
                }
            }
        });
        collection.sortByField('lastname', 'asc');
    },
    setName(model) {
        const view = this;
        const li = view.$('#user' + model.id);
        const fullname = model.get('lastname');
        li.find('.name').html(fullname);
        li.find('.rolle').html(model.get('username'));
        if ($('#user').data('id') === model.id) {
            $('#user').html(fullname);
        }
    },
    events: {
        'click .benutzerliste li': 'showDetail',
        'click .delete-user': 'deleteUser'
    },

    showDetail(event) {
        const id = $(event.target).closest('li').data('id');
        this.detailView.render(id);
    },
    deleteUser(event) {
        const view = this;
        const toBeDeletedId = $(event.target).closest('li').data('id');
        const loggedInId = $('#user').data('id');

        if (toBeDeletedId === loggedInId) {
            AlertView.show({
                title: $._('alert.action.nosuccess'),
                message: $._('user.alert.delete.nosuccess'),
                okFunc() {
                    view.render();
                }
            });
        } else {
            ConfirmView.show({
                message: $._('user.form.message.delete'),
                title: $._('alert.action.delete'),
                yesString: $._('user.form.delete'),
                yesFunc() {
                    view.detailView.model.destroy({
                        success() {
                            AlertView.show({
                                title: $._('alert.action.success'),
                                message: $._('user.alert.delete.success'),
                                okFunc() {
                                    view.render();
                                }
                            });
                        }
                    });
                }
            });
        }
    }
});
export default UserView;
