import $ from 'jquery';
import _ from 'underscore';
import MabinsoModel from '../../app/models/MabinsoModel';
import Globals from '../../../utils/Globals';

class UserModel extends MabinsoModel {
    backend = true;
    urlRoot = Globals.baseUrl + 'users/user';
    isAdmin: boolean = false;
    defaults = () => ({
        username: '',
        lastname: '',
        firstname: '',
        email: '',
    });
    validate() {
        if (!_.isUndefined(Globals.actions)) {
            this.isAdmin = !!(Globals.actions.can('user.admin.global') || Globals.actions.can('user.admin.schema'));
            if (this.form != null) {
                this.form.validationEngine('hideAll');
                let myreturn;
                if (this.isAdmin) {
                    if (_.isUndefined(this.get('roles'))) {
                        ($('.rolesCheckbox') as any).validationEngine(
                            'showPrompt',
                            ($ as any)._('validation.required.roles.id'),
                            'error',
                            'topRight',
                            true
                        );
                        myreturn = 'form.error';
                    }
                    if (Globals.actions.can('user.admin.global')) {
                        if (_.isUndefined(this.get('schemas'))) {
                            ($('.schemasCheckbox') as any).validationEngine(
                                'showPrompt',
                                ($ as any)._('validation.required.schemas.id'),
                                'error',
                                'topRight',
                                true
                            );
                            myreturn = 'form.error';
                        }
                    }
                }
                if (!this.form.validationEngine('validate')) {
                    myreturn = 'form.error';
                }
                return myreturn;
            }
        }
    }
}
export default UserModel;
