import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import html from '../templates/input_view.template';

const InputView = Backbone.View.extend({
    el: 'div#hauptrahmen',
    initialize(options) {
        this.options = options || [];
        this.render();
    },
    render() {
        const view = this;
        const { content } = view.options;
        const compiled = _.template(html)({
            $,
            title: view.options.title,
            content,
            no: _.isUndefined(view.options.cancelString) ? $._('alert.action.cancel') : view.options.cancelString,
            yes: _.isUndefined(view.options.yesString) ? $._('alert.action.yes') : view.options.yesString,
            model: view.options.model
        });
        $.modal(
            compiled,
            {
                overlayId: 'simplemodal-overlay',
                minHeight: '200px',
                minWidth: '400px',
                maxWidth: '400px',
                containerId: 'modalInput',
                overlayClose: false,
                onShow() {
                    // var modal = this;
                    view.setElement($('.simplemodal-container'));
                    view.$('.spinner').spinner();
                    view.delegateEvents(this.events);
                },
                onClose() {
                    if (_.isFunction(view.options.cancelFunc)) {
                        view.options.cancelFunc();
                    }
                    view.$el.validationEngine('hideAll');
                    $.modal.close();
                }
            }
        );
        return view;
    },
    setContent(content) {
        const view = this;
        view.$('.spinner').remove();
        view.$('.modalInner').html(content);
        view.$('.modalButtons').children().removeAttr('disabled');
    },
    showButtons(what) {
        const view = this;
        if (what) {
            view.$('.modalButtons').children().removeAttr('disabled');
        } else {
            view.$('.modalButtons').children().attr('disabled', 'disabled');
        }
    },
    yes() {
        const view = this;
        view.$('.icon').addClass('wait');
        view.showButtons(false);
        view.$('.icon').pnganimation('init', { time: 50 });
        // call the callback
        if (_.isFunction(view.options.yesFunc)) {
            view.options.yesFunc(view);
        } else {
            // close the dialog
            $.modal.close();
        }
    },
    events: {
        'click .modalYes': 'yes',
        keydown: 'keyDown'
    },
    keyDown(evt) {
        if (evt.which === 13) {
            evt.preventDefault();
            this.yes();
        }
    },
    remove() {
        $.modal.close();
    }
}, {
    show: options => new InputView(options)
});
export default InputView;
