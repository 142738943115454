import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import { Ajax } from '@mabinso-registry/module-commons';
import RoleModel from '../models/RoleModel';
import ActionCollection from '../../action/models/ActionCollection';
import ReportCollection from '../../report/models/ReportCollection';
import AlertView from '../../app/views/AlertView';
import ConfirmView from '../../app/views/ConfirmView';
import html from '../templates/detail_view.template';
import Globals from '../../../utils/Globals';

const DetailView = Backbone.View.extend({
    model: null,
    initialize(options) {
        this.options = options;
    },
    render(id) {
        this.$('form').validationEngine('hideAll');
        this.model = new RoleModel({ id });
        $.scrollTo('#hauptrahmen', 300);
        const view = this;
        $(this.el).spinner();
        if (_.isUndefined(id)) {
            view.renderTemplate(html);
        } else {
            view.model.fetch({
                success() {
                    view.renderTemplate(html);
                },
            });
        }
    },
    renderTemplate() {
        const view = this;
        view.options.listView.$('div.roles-content-detail').html(_.template(html)({ model: view.model, $ }));
        view.setElement('#role-detail-view');
        view.renderActions();
        view.renderReports();
        view.renderModules({
            success() {
                view.$el.fadeIn();
            },
        });
    },
    renderActions() {
        const actions = new ActionCollection();
        const roleactions = new ActionCollection(this.model.get('actions'));
        const view = this;
        actions.fetch({
            success() {
                _.renderMultiCheckbox('actions[].id', roleactions, actions, view.$('.actionsCheckbox'), 'code');
                view.$('.actionsCheckbox').parent().uicheckbox('init');
            },
        });
        actions.sortByField('code', 'asc');
    },
    renderReports() {
        const reports = new ReportCollection();
        reports.url = Globals.baseUrl + 'roles/reports';
        const roleReports = new ReportCollection(this.model.get('reports'));
        const view = this;
        reports.fetch({
            // data: {role_id:view.model.id},
            success() {
                const reps = reports.groupBy((m) => m.get('reportCategory').name);
                const reps2 = Object.keys(reps)
                    .sort((a, b) => {
                        return a.localeCompare(b);
                    })
                    .map((key) => reps[key]);
                const reps3 = new ReportCollection();
                reps2.forEach((m) => {
                    m.sort((a, b) => a.get('name').localeCompare(b.get('name')));
                    reps3.push(m);
                });
                _.renderMultiCheckbox({
                    inputName: 'reports[].id',
                    selectModels: roleReports,
                    models: reps3,
                    appendEle: view.$('.reportsCheckbox'),
                    valueName: 'name',
                    category: 'reportCategory',
                    showIdent: true,
                });
                view.$('.reportsCheckbox').parent().uicheckbox('init');
            },
        });
    },
    renderModules(options) {
        const modules = Globals.user.get('modules');
        const view = this;
        import('../../interface/views/InterfaceListView').then((IView) => {
            console.log('IView', IView);
            this.addModule(IView.default, true);
            _.each(modules, async (module) => {
                // Leider anderer Dynamischer Import mit den alten Modulen nicht möglich
                switch (module.name) {
                    case 'olap':
                        if (!module.role) break;
                        import('../../../modules/olap/views/' + module.viewname).then((MView) => {
                            view.addModule(MView.default, false);
                        });
                        break;
                    case 'dashboard':
                        if (!module.role) break;
                        import('../../../modules/dashboard/views/' + module.viewname).then((MView) => {
                            view.addModule(MView.default, false);
                        });
                        break;
                    case 'multiedit':
                        if (!module.role) break;
                        import('../../../modules/multiedit/views/' + module.viewname).then((MView) => {
                            view.addModule(MView.default, false);
                        });
                        break;
                    case 'editor':
                    case 'etl':
                        if (module.role === false && module.roles === undefined) {
                            const ajax = new Ajax({ module: module.name });
                            const info = await ajax.getJson('');
                            module.role = info.roles;
                            module.roles = info.roles;
                        }
                        if (module.role) {
                            window.setTimeout(() => {
                                view.addModule(view.getMicroserviceModule(module.name));
                            }, 200);
                        }
                        break;
                    default:
                }
            });
            options.success();
        });
    },
    getMicroserviceModule(moduleName) {
        return {
            async getForRole(view, appendEl) {
                const roleId = view.model.id;
                console.log(moduleName);
                console.log(roleId);
                const odiv = $(_.make('div', { class: 'input checkbox listed searchable' }));
                odiv.append(_.make('label', {}, $._('role.form.' + moduleName)));
                const innerDiv = _.make('div', { class: 'checkboxGroup ' + moduleName + 'Checkbox' });
                odiv.append(innerDiv);
                appendEl.append(odiv);
                // eslint-disable-next-line no-unused-vars
                const ajax = new Ajax({ module: moduleName });
                const roles = await ajax.getJson('getForRole');
                roles.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
                roles.forEach((model, idx) => {
                    const div = _.make('div', { class: 'option' });
                    const inName = moduleName + '[' + idx + '].id';
                    const input = _.make('input', {
                        type: 'checkbox',
                        name: inName,
                        id: inName + model.id,
                        value: model.id,
                    });
                    if (model.roles.includes(roleId)) {
                        $(input).attr({ checked: 'checked' });
                    }
                    $(div).append(input);
                    const span = _.make('span', { class: 'optBeschriftung' }, model.name);
                    $(div).append(span);
                    $($(innerDiv)).append(div);
                });
                $(innerDiv).parent().uicheckbox('init');
            },
            async saveToRole(model) {
                const ids = [];
                console.log('saveToRole', moduleName);
                _.each(model.get(moduleName), (item) => {
                    ids.push(item.id);
                });
                const ajax = new Ajax({ module: moduleName });
                await ajax.post({
                    url: 'saveToRole',
                    json: {
                        role: model.id,
                        ids,
                    },
                });
            },
        };
    },
    addModule(Module, left) {
        const view = this;
        let appendName = '.fieldset-right';
        if (left) {
            appendName = '.fieldset-left';
        }
        const appendEl = view.$(appendName);
        Module.getForRole(view, appendEl);
        view.saveModules.push(Module.saveToRole);
    },
    events: {
        'click .rolesave': 'saveRole',
        'click #delete-role': 'deleteRole',
    },
    saveModules: [],

    saveRole() {
        this.model.unset('actions', { silent: true });
        this.model.unset('reports', { silent: true });
        this.model.setForm(this.$('form'));
        const view = this;
        this.model.save((formMessage) => {
            if (formMessage.showErrors()) {
                const noId = _.isUndefined(view.model.id);
                if (noId) {
                    view.model.id = formMessage.getGid();
                }
                const alertView = () => {
                    AlertView.show({
                        message: $._('role.alert.save.success'),
                        okFunc() {
                            if (noId) {
                                view.options.listView.render();
                            } else {
                                view.render(view.model.id);
                            }
                        },
                    });
                };
                if (_.isUndefined(view.saveModules) || view.saveModules.length === 0) {
                    alertView();
                } else {
                    const allModules = view.saveModules.length;
                    let startSave = 0;
                    const afterSave = () => {
                        if (startSave >= allModules) {
                            alertView();
                        }
                    };
                    _.each(view.saveModules, (saveModul) => {
                        startSave += 1;
                        saveModul(view.model, {
                            success() {
                                view.saveModules = [];
                                afterSave();
                            },
                        });
                    });
                }
            }
        });
        return false;
    },
    deleteRole() {
        const view = this;
        this.$('form').validationEngine('hideAll');
        ConfirmView.show({
            message: $._('role.message.delete'),
            title: $._('alert.action.delete'),
            yesFunc() {
                view.model.destroy({
                    success(mode, data) {
                        AlertView.show({
                            message: data.hasErrors ? $._('role.alert.delete.nosuccess', data.errors[0].message) : $._('role.alert.delete.success'),
                            title: data.hasErrors ? $._('alert.action.nosuccess') : $._('alert.action.success'),
                            okFunc() {
                                if (!data.hasErrors) {
                                    view.options.listView.render();
                                }
                            },
                        });
                    },
                });
            },
        });
    },
});
export default DetailView;
