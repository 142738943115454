import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Collection from '../models/InterfaceParameterCollection';
import AlertView from '../../app/views/AlertView';
import WaitView from '../../app/views/WaitView';
import html from '../templates/config_view.template';
import params from '../templates/config_parameter.template';

const View = Backbone.View.extend({
    initialize(options) {
        this.options = options || [];
    },
    render() {
        const view = this;
        const onExec = _.isUndefined(view.options.onExec) ? false : view.options.onExec;
        const etlName = _.isUndefined(view.options.name) ? '' : ': ' + view.options.name;
        view.collection = new Collection();
        const wait = new WaitView({
            message: $._('interface.alert.report.wait')
        });
        view.collection.fetch({
            data: { interface_id: view.model.get('interface_id') },
            success() {
                let compiled = _.template(html)({
                    $,
                    col: view.collection,
                    title: $._('interface.config.title') + etlName,
                    cancel: $._('dialog.cancel'),
                    ok: onExec ? $._('interface.start') : $._('interface.config.save')
                });
                compiled = $(compiled);
                view.collection.each(item => {
                    const name = item.get('name');
                    if (name.startsWith('depend') || name.startsWith('on_exec.depend')) {
                        const nameSplit = name.split('.');
                        let ino = nameSplit[2];
                        if (name.startsWith('on_exec.depend')) {
                            // eslint-disable-next-line prefer-destructuring
                            ino = nameSplit[3];
                        }
                        item.set('isDepend', true);
                        if (item.get('hasDepend')) {
                            item.set('className', 'rerender');
                        }
                        item.set('dependNo', ino);
                    } else {
                        item.set('isDepend', false);
                        item.set('className', '');
                    }
                    item.set('onExec', onExec);
                    const compiledParams = _.template(params)({ item });
                    compiled.find('.params').append(compiledParams);
                });
                compiled.find('.input').uiselectfield('init');
                compiled.find('.input').uicheckbox('init');
                wait.remove();
                $.modal(
                    compiled,
                    {
                        overlayId: 'simplemodal-overlay',
                        minWidth: '680px',
                        maxWidth: '680px',
                        containerId: 'modalWidget',
                        persist: true,
                        overlayClose: false,
                        autoResize: true,
                        onShow() {
                            view.setElement('.modalWidget');
                            // $(".txtDate").focus();
                            setTimeout(() => {
                                view.$('input.datepicker').datepicker({
                                    changeMonth: true,
                                    changeYear: true,
                                    // close: true,
                                    autoOpen: false
                                });
                                view.$('input.datepicker').blur();
                            }, 10);
                            // view.$("form").validationEngine();
                        },
                        onClose() {
                            $.modal.close();
                            view.$el.validationEngine('hideAll');
                        }
                    }
                );
            }
        });
    },
    events: {
        'click .modalYes': 'btSave',
        'change .rerender': 'reRender'
    },
    reRender() {
        this.model.setForm(this.$('form'), false);
        this.model.save(() => {
            $.modal.close();
            this.render();
        });
        return false;
    },
    btSave() {
        const view = this;
        view.model.setForm(view.$('form'));
        if (view.options.onExec) {
            this.model.set('onExec', true);
        }
        this.model.save(formMessage => {
            console.log('after Save ');
            if (formMessage.showErrors()) {
                $.modal.close();
                if (_.isFunction(view.options.success)) {
                    view.options.success();
                } else {
                    // Bestaetigungsfenster
                    AlertView.show({
                        message: $._('interface.config.success'),
                        title: $._('interface.config.success.title'),
                    });
                }
            }
        });
        return false;
    }
});
export default View;
