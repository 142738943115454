import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import FinishedView from './FinishedView';
import PlanView from './PlanView';
import PlanListView from './PlanListView';
import ConfigView from './ConfigView';
import AlertView from '../../app/views/AlertView';
import UploadView from './UploadView';
import WaitView from '../../app/views/WaitView';
import ConfirmView from '../../app/views/ConfirmView';
import Collection from '../models/InterfaceFinishedCollection';
import InterfaceJobModel from '../models/InterfaceJobModel';
import InterfaceModel from '../models/InterfaceModel';
import InterfaceParameterModel from '../models/InterfaceParameterModel';
import html from '../templates/interface_view.template';
import Globals from '../../../utils/Globals';

const View = Backbone.View.extend({
    tagName: 'div',
    className: 'reportvorlage',
    _modelBinder: undefined,
    initialize(options) {
        this.options = options;
        this._modelBinder = new Backbone.ModelBinder();
    },
    render() {
        const view = this;
        const tstring = _.template(html)({
            model: this.model, $, sessionUser: Globals.user, Actions: Globals.actions
        });
        this.$el.html(tstring);
        this.setEnable(this.model.get('isEnable'));
        this._modelBinder.bind(this.model, this.el, Backbone.ModelBinder.createDefaultBindings(this.el, 'data-name'));
        view.collection = new Collection();
        view.collection.fetch({
            data: { id: view.model.id },
            success(collection) {
                view.$el.uiselectfield('init');
                const viewCreator = model => { model.view = new FinishedView({ model, parent: view }); return model.view; };
                const elManagerFactory = new Backbone.CollectionBinder.ViewManagerFactory(viewCreator);
                view._collectionBinder = new Backbone.CollectionBinder(elManagerFactory, { autoSort: true });
                view._collectionBinder.bind(collection, view.$('table tbody'));
                view.collectionEvents();
                if (collection.isEmpty()) {
                    view.hasFinished(false);
                    view.setRun(false);
                } else {
                    view.hasFinished(true);
                    view.setRun();
                }
                // alert(collection.first().get('isRun'));
            }
        });
        return this;
    },
    collectionEvents() {
        const view = this;
        this.collection.on('remove', () => {
            if (view.collection.isEmpty()) {
                view.close();
                view.hasFinished(false);
            }
        });
        this.collection.on('add', model => {
            view.setRun(model.get('isRun'));
            view.hasFinished(true);
            view.open();
        });
        this.collection.on('change', model => {
            view.setRun(model.get('isRun'));
        });
    },
    hasFinished(value) {
        if (value) {
            this.$('.reporttitel').addClass('hasReportsSaved');
            this.$el.closest('.reportvorlage').addClass('hasReportsSaved');
            this.$('div.reporttitel div').removeClass('waitreport');
            this.$('div.reporttitel div').removeClass('noreport');
        } else {
            this.$('div.reporttitel div').pnganimation('stop', { left: -915, top: -235 });
            this.$('.reporttitel').removeClass('hasReportsSaved');
            this.$('div.reporttitel div').removeClass('waitreport');
            this.$('div.reporttitel div').addClass('noreport');
            this.$('div.reporttitel div').addClass('sprites_main');
        }
    },
    setRun(value) {
        const view = this;
        if (_.isUndefined(value)) {
            value = view.collection.first().get('isRun');
        }
        if (value) {
            view.$('.reporttitel').addClass('inaction');
            view.$('.reporttitel.inaction .statusBackground').pnganimation('init', { time: 50, step: 1 });
            // view.$(".start-interface").attr('disabled','disabled');
            view.$('.stop-interface').show();
        } else {
            view.$('.reporttitel').removeClass('inaction');
            // view.$(".start-interface").removeAttr('disabled');
            view.$('.reporttitel.inaction .statusBackground').pnganimation('stop');
            view.$('.stop-interface').hide();
        }
    },
    setEnable(value) {
        const view = this;
        if (value) {
            // view.$('.actionbox').show();
        } else {
            // view.$('.actionbox').hide();
            view.$('.reporttitel').addClass('deleted');
            view.$('.reporttitel').parent().addClass('deleted');
        }
    },
    events: {
        // 'click .reporttitel.hasReportsSaved.active': 'close',
        // 'click .reporttitel.hasReportsSaved:not(.active)': 'open',

        'click .reporttitel.hasReportsSaved.active:not(.deleted) .openvorlage': 'close',
        'click .reporttitel.hasReportsSaved:not(.active, .deleted) .openvorlage': 'open',

        'click .delete-interface': 'delete',
        'click .delete-interface-all': 'deleteAll',
        'click .start-interface': 'start',
        'click .stop-interface': 'stop',
        'click .plan-interface': 'plan',
        'click .config-interface': 'config',
        'click .plan-list-interface': 'planList'
    },
    config() {
        const view = this;
        if (_.isUndefined(view.options.configView)) {
            view.options.configView = new ConfigView({ name: view.model.attributes.name });
        }
        view.options.configView.model = new InterfaceParameterModel({ interface_id: view.model.id });
        view.options.configView.render();
    },
    plan() {
        const view = this;
        if (_.isUndefined(view.options.planView)) {
            view.options.planView = new PlanView({ name: view.model.attributes.name });
        }
        view.options.planView.model = new InterfaceJobModel({ interface_id: view.model.id });
        view.options.planView.render();
    },
    planList() {
        const view = this;
        if (_.isUndefined(view.options.planListView)) {
            view.options.planListView = new PlanListView({ name: view.model.attributes.name });
        }
        view.options.planListView.model = view.model;
        view.options.planListView.render();
    },
    start() {
        const view = this;
        const lockFree = view.model.getConfigByName('intern.lock_free');
        if ((lockFree === undefined || lockFree === null) && view.$('.reporttitel').hasClass('inaction')) {
            AlertView.show({ title: $._('interface.start.isrunning'), message: $._('interface.start.isrunning.message') });
        } else {
            const startInter = () => {
                const wview = new WaitView({
                    title: $._('interface.start.title'),
                    message: $._('interface.start.message')
                });
                view.model.startInterface({
                    success(data) {
                        wview.remove();
                        if (data == null) {
                            AlertView.show({ title: $._('interface.start.isrunning'), message: $._('interface.start.isrunning.message') });
                        }
                        /* else{
                         view.collection.add(data,{at:0});
                         } */
                    }
                });
            };
            const setConfig = () => {
                let onExec = view.model.getConfigByName('intern.onexec');
                if (!onExec) { onExec = view.model.getConfigByName('intern.on_exec'); }
                if (onExec) {
                    if (_.isUndefined(view.options.execConfigView)) {
                        view.options.execConfigView = new ConfigView({ success: startInter, onExec: true, name: view.model.attributes.name });
                    }
                    view.options.execConfigView.model = new InterfaceParameterModel({ interface_id: view.model.id });
                    view.options.execConfigView.render();
                } else {
                    startInter();
                }
            };
            if (view.model.getConfigByName('intern.fileupload') != null) {
                const uploadView = new UploadView({ model: view.model, success: setConfig });
                uploadView.render();
            } else {
                setConfig();
            }
        }
    },
    stop() {
        const view = this;
        const wview = new WaitView({
            title: $._('interface.stop.title'),
            message: $._('interface.stop.message')
        });
        view.model.stopInterface({
            success(data) {
                if (data == null) {
                    console.log('interface not stoped');
                } else {
                    view.collection.get(data.id).set(data);
                }
                wview.remove();
            }
        });
    },
    delete() {
        const view = this;
        const cview = new ConfirmView({
            message: $._('interface.alert.confirm.delete'),
            title: $._('interface.alert.confirm.delete.title'),
            yesString: $._('interface.alert.delete.confirm'),
            yesFunc() {
                view.model.destroy({
                    success() {
                        cview.remove();
                        view.options.parent.options.interfaceListView.render();
                    }
                });
            }
        });
    },
    deleteAll() {
        const that = this;
        let ids = null;
        if (that.collection != null) { ids = that.collection.pluck('id'); }
        if (ids == null || _.size(ids) === 0) {
            AlertView.show({ title: $._('interface.deleteFilterItems.noitems.title') + ': ' + that.model.attributes.name, message: $._('interface.deleteFilterItems.noitems.message') });
        } else {
            ConfirmView.show({
                title: $._('interface.deleteFilterItems.delete.title') + ': ' + that.model.attributes.name,
                message: $._('interface.deleteFilterItems.delete.message', _.size(ids)),
                yesFunc() {
                    const waitView = new WaitView({
                        message: $._('interface.deleteFilterItems.delete.wait')
                    });
                    InterfaceModel.deleteItems({
                        items: ids,
                        success() {
                            waitView.remove();
                            AlertView.show({
                                message: $._('interface.deleteFilterItems.delete.success'),
                                okFunc() {
                                    that.render();
                                }
                            });
                        }
                    });
                }
            });
        }
    },
    open() {
        const root = this.$el.closest('.reportvorlage');
        $('.reports', root).slideDown('fast');
        $('.reporttitel', root).addClass('active');
    },
    close() {
        const root = this.$el.closest('.reportvorlage');
        $('.reports', root).slideUp('fast');
        $('.reporttitel', root).removeClass('active');
    }
});
export default View;
