import MabinsoModel from '../../app/models/MabinsoModel';
import Globals from '../../../utils/Globals';

class SchemaUserModel extends MabinsoModel {
    urlRoot = Globals.baseUrl + 'schemas/userSchema';
    urlSocket = 'interfaces/socket/status';
    urlFallback = Globals.baseUrl + 'interfaces/status';
}

export default SchemaUserModel;
