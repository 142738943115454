import MabinsoModel from '../../app/models/MabinsoModel';
import Globals from '../../../utils/Globals';

class Model extends MabinsoModel {
    urlRoot = Globals.baseUrl + 'interfaces/config';
    isType(value: string) {
        if (this.get('type') === value) return true;
        return false;
    }
    getLabel() {
        const mylabel = Globals.i18n.data['form.' + this.get('name')];
        if (mylabel !== undefined && mylabel !== false && mylabel !== null) {
            return mylabel;
        }
        return this.get('label');
    }
    isIntern() {
        const onExec = this.get('onExec');
        if (onExec) {
            if (this.get('name').indexOf('intern') === -1 && this.get('name').indexOf('on_exec') !== -1) {
                return false;
            }
        } else if (this.get('name').indexOf('intern') === -1 && this.get('name').indexOf('on_exec') === -1) {
            return false;
        }

        return true;
    }
}
export default Model;
