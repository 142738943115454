import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import ActionCollection from '../models/ActionCollection';
import AlertView from '../../app/views/AlertView';
import DetailView from './DetailView';
import ConfirmView from '../../app/views/ConfirmView';
import html from '../templates/list_view.template';

const ActionView = Backbone.View.extend({
    initialize(options) {
        $('body').scrollTo(0, 0);
        this.options = options || [];
    },
    render() {
        const collection = new ActionCollection();
        this.collection = collection;
        const view = this;
        view.$el.spinner();
        collection.fetch({
            success(collection2) {
                view.$el.html(_.template(html)({ models: collection2, $ }));
                view.setElement('#action-list-view');
                view.options.detailView = new DetailView({ listView: view });
                $('.spalte_links_280 li').uiAktivAnzeiger('init');
            }
        });
        collection.sortByField('code', 'asc');
    },
    events: {
        'click .rolelist li:not(#reset)': 'showDetail',
        'click .rolelist li#reset': 'reset',
    },
    showDetail(event) {
        const id = $(event.target).closest('li').data('id');
        this.options.detailView.render(id);
    },
    reset() {
        const view = this;
        ConfirmView.show({
            message: $._('action.message.reset'),
            title: $._('alert.action.reset'),
            yesFunc() {
                view.collection.resetAll({
                    success() {
                        AlertView.show({
                            message: $._('action.alert.reset.success'),
                            okFunc() {
                                view.render();
                            }
                        });
                    }
                });
            }
        });
    }
});
export default ActionView;
