/* eslint-disable wrap-iife */
import jQuery from 'jquery';
import _ from 'underscore';

(function uiselect($) {
    const methods = {
        init(options) {
            const settings = {
                color: '#0a193a',
                changeButtonTitle: true,
                mark: true,
            };
            if (options) {
                $.extend(settings, options);
            }
            const aussenrahmen = $(this);
            const createIt = (mabinsoSelect) => {
                let setValue = mabinsoSelect.find('option[selected]').attr('value');
                let setValueString = mabinsoSelect.find('option[selected]').html();
                if (setValue == null) {
                    setValue = mabinsoSelect.find('option').attr('value');
                    setValueString = mabinsoSelect.find('option').html();
                }
                if (mabinsoSelect.attr('title')) {
                    setValue = mabinsoSelect.attr('title');
                    setValueString = mabinsoSelect.attr('title');
                }
                const optionArray = [];
                $.each(mabinsoSelect.find('option'), function each() {
                    optionArray.push({
                        value: $(this).attr('value'),
                        id: $(this).attr('id'),
                        cssclass: $(this).attr('class'),
                        label: $(this).html(),
                    });
                });

                mabinsoSelect.uiselectfield('createDom', {
                    optionArray,
                    className: aussenrahmen.hasClass('rerender') ? 'rerender' : '',
                    fieldName: mabinsoSelect.attr('name'),
                    fieldId: mabinsoSelect.attr('id'),
                    setValue,
                    setValueString,
                    mark: settings.mark,
                    changeButtonTitle: settings.changeButtonTitle,
                });
            };

            if (aussenrahmen.hasClass('mabinsoSelect')) {
                createIt(aussenrahmen);
            } else {
                $.each(aussenrahmen.find('.mabinsoSelect'), function each() {
                    createIt($(this));
                });
            }
        },
        createDom(options) {
            const settings = {
                optionArray: '',
                fieldName: '',
                fieldId: '',
                setValue: '',
                className: '',
                setValueString: '',
                changeButtonTitle: 'true',
                trennerArray: 'false',
                mark: true,
                isMenu: false,
                data_help: '',
            };
            if (options) {
                $.extend(settings, options);
            }

            // alert(settings.setValueString);
            if (settings.setValueString == null) {
                settings.setValueString = '';
            }
            if (!settings.changeButtonTitle) {
                settings.mark = false;
            }

            const uiSelect = $('<div class="uiSelect"><ul></ul></div>');
            $(this).after(uiSelect);
            const fieldName = settings.fieldName !== '' ? settings.fieldName : 'modul';
            for (let i = 0; i < settings.optionArray.length; i += 1) {
                const oVal = settings.optionArray[i].value;
                uiSelect
                    .find('ul')
                    .append(
                        '<li id="' +
                            fieldName +
                            '_' +
                            (oVal !== undefined ? oVal : i) +
                            '" class="' +
                            (settings.optionArray[i].cssclass ? settings.optionArray[i].cssclass : '') +
                            ' ' +
                            (settings.optionArray[i].value === settings.setValue && settings.mark ? 'choosen' : '') +
                            '">' +
                            settings.optionArray[i].label +
                            '</li>'
                    );

                // Unterteilungen machen
                if (settings.trennerArray !== 'false') {
                    for (let u = 0; u < settings.trennerArray.length; u += 1) {
                        if (i === settings.trennerArray[u].positionAfter) {
                            uiSelect.find('ul').append('<li class="trenner"><span>' + settings.trennerArray[u].label + '</span></li>');
                        }
                    }
                }
            }
            let dataHelp;
            if (settings.data_help !== '') {
                dataHelp = 'data-help="' + settings.data_help + '"';
            } else {
                dataHelp = '';
            }
            uiSelect.append(
                '<input type="hidden" class="' +
                    settings.className +
                    '" value="' +
                    settings.setValue +
                    '" name="' +
                    settings.fieldName +
                    '" id="' +
                    settings.fieldId +
                    '"/><button data-id="' +
                    settings.setValue +
                    '" ' +
                    dataHelp +
                    '>' +
                    settings.setValueString +
                    '</button>'
            );

            $.each(uiSelect.find('li'), function each() {
                $(this).mousedown(() => {
                    uiSelect.find('button').unbind('focusout');
                    uiSelect.find('.choosen').removeClass('choosen');
                });

                $(this).click(function myClick(evt, noClose) {
                    if (!$(this).hasClass('trenner')) {
                        uiSelect.find('ul li.choosen').removeClass('choosen');
                        if (settings.changeButtonTitle) {
                            uiSelect.find('button').html($(this).html());
                        }
                        uiSelect.find('button').data('id', $(this).attr('id').substr(6));
                        const inp = uiSelect.find('input');
                        if (noClose) {
                            $(this).addClass('choosen');
                            if (settings.isMenu) {
                                clearTimeout(uiSelect.data('mytimeout'));
                                const mytimeout = setTimeout(() => {
                                    const fli = uiSelect.find('ul li[id="' + fieldName + '_' + inp.val() + '"]');
                                    fli.trigger('click');
                                }, 3000);
                                uiSelect.data('mytimeout', mytimeout);
                            }
                            return false;
                        }
                        if (settings.mark) {
                            $(this).addClass('choosen');
                        }
                        inp.attr(
                            'value',
                            $(this)
                                .attr('id')
                                .substr(fieldName.length + 1)
                        );
                        inp.trigger('change', $(this).text());
                        $(this).uiselectfield('zu', { wen: uiSelect });
                    }
                });
            });
            $(this).detach();
            uiSelect.uiselectfield('createActions', { fieldName });
        },
        createSelect(options) {
            const settings = {
                optionArray: '',
                fieldName: '',
                fieldId: '',
                setValue: '',
                setValueString: '',
                position: 'append',
                changeButtonTitle: 'true',
                trennerArray: 'false',
                isMenu: false,
                data_help: '',
            };
            if (options) {
                $.extend(settings, options);
            }

            const newdiv = $('<div/>');
            if (settings.position === 'append') {
                $(this).append(newdiv);
            }
            newdiv.uiselectfield('createDom', {
                optionArray: settings.optionArray,
                fieldName: settings.fieldName,
                fieldId: settings.fieldId,
                setValue: settings.setValue,
                setValueString: settings.setValueString,
                changeButtonTitle: settings.changeButtonTitle,
                trennerArray: settings.trennerArray,
                data_help: settings.data_help,
                isMenu: settings.isMenu,
            });
        },
        createActions(options) {
            const settings = {
                foo: 'bar',
            };
            if (options) {
                $.extend(settings, options);
            }
            // Hover out, dann zu

            const uiSelect = $(this); // .find(".uiSelect");
            const uiSelectButton = uiSelect.find('button');
            const uiSelectUl = uiSelect.find('ul');
            uiSelect.hover(
                () => {},
                () => {
                    // if($(this).find('ul').css('display')=='block'){
                    //  $(this).uiselectfield('zu', {'wen' : $(this)});
                    // }
                }
            );

            // Klappen-Mechanismus

            const clip = () => {
                const completeheight = uiSelectUl.height() + uiSelectUl.parent().find('button').height() + 22;
                uiSelectUl.parent().css({ height: completeheight });
                uiSelectUl.parent().hover(
                    () => {},
                    function myHover() {
                        $(this).find('ul').uiselectfield('zu', { wen: uiSelect });
                        uiSelectUl.parent().css({ height: 'auto' });
                    }
                );

                uiSelectUl.hover(
                    () => {
                        // $(this).parent().css({'border':'solid 1px #f00'});
                        // uiSelectButton.unbind('focusout');
                    },
                    () => {
                        uiSelectButton.focus();
                        uiSelectButton.focusout(() => {
                            // const dieser = $(this);
                            // dieser.uiselectfield('zu', {'wen' : uiSelect});
                        });
                    }
                );

                const vonOben = uiSelectButton.height() + 2;
                const breite = uiSelectButton.width();

                const scrollpos = window.pageYOffset;
                const windowPosition = uiSelectButton.offset().top - scrollpos;
                let maxHeightUl = parseInt(uiSelectUl.css('max-height'), 10);
                if (_.isNaN(maxHeightUl)) {
                    maxHeightUl = 0;
                }
                if (windowPosition > $(window).height() - maxHeightUl) {
                    // uiSelectUl.parent().css({'margin-top': completeheight*-1});

                    uiSelectUl.css('top', '');
                    uiSelectUl.css('bottom', completeheight).css('min-width', breite + 'px');
                } else {
                    uiSelectUl.css('bottom', '');
                    uiSelectUl.css('top', vonOben + 'px').css('min-width', breite + 'px');
                }
                uiSelectUl.slideDown('fast', () => {
                    // Wenn irgendein input-Feld einen Focus bekommt, gehen alle uiSelect zu
                    const myFocusInHandler = function focus() {
                        $.each($('.uiSelect'), function each() {
                            const dieser = $(this);
                            dieser.uiselectfield('zu', { wen: dieser });
                        });
                    };
                    $('input').one('focusin', myFocusInHandler);
                    // $("input").bind("focusin",myFocusInHandler);

                    uiSelect.find('ul').scrollTo(uiSelect.find('li.choosen:first'));
                    uiSelectButton.focusout(() => {
                        // const dieser = $(this);
                        // dieser.uiselectfield('zu', {'wen' : uiSelect});
                    });
                });
                uiSelect.addClass('active');

                // Speichert wert, dass Feld offen ist
                uiSelect.data('istoffen', 1);
            };

            // Bei texteingabe zu Eintrag springen
            uiSelect.uiselectfield('keysearch', { fieldName: settings.fieldName });

            /* uiSelectButton.focusout(function(){
                var dieser=$(this);
                //dieser.uiselectfield('zu', {'wen' : dieser.parent()});
            }); */

            // Bei Klick
            uiSelectButton.mousedown(function myMouseDown() {
                if (uiSelectUl.css('display') === 'block') {
                    // Schließe wieder
                    $(this).uiselectfield('zu', { wen: uiSelect });
                } else {
                    // $(this).trigger('focusin');
                    clip();
                }
            });

            uiSelectButton.click(() => false);
        },
        zu(options) {
            const settings = {
                wen: 'foo',
            };
            if (options) {
                $.extend(settings, options);
            }
            // console.log(settings.wen);
            settings.wen.find('ul').unbind('hover');
            settings.wen.find('button').unbind('focusout');

            settings.wen.find('ul').slideUp('fast', () => {
                settings.wen.find('ul li').removeClass('hidden');
            });
            settings.wen.data('istoffen', 0);
            settings.wen.find('button').removeClass('active');
            settings.wen.find('button').removeClass('focus');
        },
        keysearch(options) {
            const settings = {
                foo: 'bar',
            };
            if (options) {
                $.extend(settings, options);
            }
            const { fieldName } = settings;

            if (!$(this).hasClass('focus')) {
                $(this).addClass('focus');
                $(this).focus();
            }
            const uiSelect = $(this);
            const keyBind = uiSelect.find('button'); // $('body');

            // $("button:eq(0)").focus();
            keyBind.data('phrase', '');

            keyBind.unbind('keydown');

            keyBind.keydown(function myKeyDown(event) {
                event.preventDefault();
                const { which } = event;
                const inp = uiSelect.find('button');
                let fli = uiSelect.find('ul li[id="' + fieldName + '_' + inp.data('id') + '"]').prev();
                while (fli && fli.hasClass('trenner')) {
                    fli = fli.prev();
                }
                let fliNext = uiSelect.find('ul li[id="' + fieldName + '_' + inp.data('id') + '"]').next();
                while (fliNext && fliNext.hasClass('trenner')) {
                    fliNext = fliNext.next();
                }
                const characterPressed = String.fromCharCode(which).toLowerCase();
                const dieser = $(this);
                const timeoutPhrase = window.setTimeout(() => {
                    dieser.data('phrase', '');
                }, 1000);
                switch (which) {
                    case 27:
                    case 13:
                        uiSelect.find('li.choosen:first').trigger('click');
                        break;
                    case 37:
                    case 38:
                        fli.trigger('click', true);
                        break;
                    case 39:
                    case 40:
                        fliNext.trigger('click', true);
                        // inp.attr('value', $(this).attr('id').substr(6));
                        break;
                    case 16:
                        break;
                    default: {
                        // Phrase wird gebaut
                        if (which === 8) {
                            $(this).data(
                                'phrase',
                                $(this)
                                    .data('phrase')
                                    .substring(0, $(this).data('phrase').length - 1)
                            );
                        } else {
                            $(this).data('phrase', $(this).data('phrase') + characterPressed);
                        }

                        // Phrasentimeout wird gelöscht falls vorhanden
                        if ($(this).data('timeoutPhrase') != null) {
                            window.clearTimeout($(this).data('timeoutPhrase'));
                        }

                        // Timeout um Phrase wieder zu leeren
                        $(this).data('timeoutPhrase', timeoutPhrase);

                        // Suchstrimg definieren
                        const suchstring = $(this).data('phrase');

                        // $.each($(this).parent().find('li'), function(index, value) {
                        $.each(uiSelect.find('ul li'), function each() {
                            const myregex = new RegExp('^' + suchstring, 'i');
                            // alert($(this).html().search(myregex));
                            if ($(this).html().search(myregex) !== -1) {
                                $(this).removeClass('choosen');
                                if (suchstring.length > 0) {
                                    $(this).addClass('choosen');
                                } else {
                                    uiSelect.find('button').html(suchstring);
                                }
                                $(this).removeClass('hidden');
                            } else {
                                $(this).removeClass('choosen');
                                $(this).addClass('hidden');
                            }
                        });

                        // uiSelect.find('ul').scrollTo(uiSelect.find('li.choosen:first'));
                        if (uiSelect.find('li.choosen').length === 1) {
                            uiSelect.find('li.choosen:first').trigger('click', true);
                        }
                    }
                }
                uiSelect.find('ul').scrollTo(uiSelect.find('li.choosen:first'));
                return false;
            });
        },
        setValue(value) {
            if (this.is('input')) {
                this.val(value);
                this.next().text(value);
                this.prev().find('li.choosen').removeClass('choosen');
                this.prev()
                    .find('li:contains("' + value + '")')
                    .removeClass('choosen');
            }
        },
    };
    $.fn.uiselectfield = function myStart(method) {
        // Method calling logic
        if (methods[method]) {
            // eslint-disable-next-line prefer-rest-params
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            // eslint-disable-next-line prefer-rest-params
            return methods.init.apply(this, arguments);
        }

        $.error('Method ' + method + ' does not exist on jMaBinso.uiselectfield');
    };
})(jQuery);
