import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Download from '../../../utils/Download';
import ConfirmView from '../../app/views/ConfirmView';
import WaitView from '../../app/views/WaitView';
import AlertView from '../../app/views/AlertView';
import LogListView from './LogListView';
import html from '../templates/finished_view.template';

const View = Backbone.View.extend({
    tagName: 'tr',
    className: 'report hover',
    _modelBinder: undefined,
    initialize(options) {
        this.options = options;
        this._modelBinder = new Backbone.ModelBinder();
    },
    render() {
        const view = this;
        const tstring = _.template(html)({ model: this.model, $ });
        this.$el.html(tstring);
        this.setStatus(this.model);
        this.model.on('change', (model, value) => {
            view.options.parent.setRun();
            view.setStatus(model, value);
        });
        this._modelBinder.bind(this.model, this.el, Backbone.ModelBinder.createDefaultBindings(this.el, 'data-name'));
        return this;
    },
    setStatus(model) {
        const view = this;
        const status = _.make('span', {
            class: 'status_' + model.getStatus().code + ' sprites_main hint',
            title: model.getStatus().msg
        }, model.getStatus().msg);
        this.$('td.status').html(status);
        if (model.hasStatus('fehler')) {
            // view.$('.bearbeiten').hide();
            view.$('.bearbeiten').show();
            view.$('.loeschen').show();
        } else if (model.hasStatus('ok_empty')) {
            view.$('.show-buttons').hide();
            view.$('.bearbeiten').show();
            view.$('.loeschen').show();
        } else if (model.hasStatus('inaktion')) {
            // view.$('.show-buttons').hide();
            view.$('.bearbeiten').hide();
            view.$('.loeschen').hide();
        } else {
            view.$('.show-buttons').show();
            view.$('.bearbeiten').show();
            view.$('.loeschen').show();
        }
    },
    hideForSearch(value) {
        const view = this;
        if (value) {
            view.$el.hide();
        } else {
            view.$el.show();
        }
    },
    events: {
        'click .loeschen:not(.abbrechen)': 'delete',
        'click .bearbeiten:not(.active)': 'edit',
        'click .loeschen.abbrechen': 'cancelEdit',
        'keydown input': 'keyDownEdit',
        'click .bearbeiten.active': 'save',
        'click a.link-csv': 'getCSV',
        'click a.link-xls': 'getXlsSavedReport',
        'click a.link-html': 'getHtmlSavedReport',
        'click .listSelector': 'toggleSelectReport'
    },
    delete() {
        const view = this;
        if (view.$el.hasClass('selected')) {
            const wait = new WaitView({
                message: $._('interface.alert.report.delete.wait')
            });
            view.model.destroy({
                success() {
                    wait.remove();
                }
            });
        } else {
            ConfirmView.show({
                message: $._('interface.alert.report.delete.wait'),
                title: $._('alert.action.delete'),
                yesString: $._('interface.alert.report.delete.confirm'),
                yesFunc() {
                    // cview.remove();
                    const wait = new WaitView({
                        message: $._('interface.alert.report.delete.wait')
                    });
                    view.model.destroy({
                        success() {
                            wait.remove();
                        }
                    });
                }
            });
        }
        view.closeSelectModeComplete();
    },
    showEditModus() {
        const view = this;
        // Abblenden für edit modus
        $(document).abblender({ onClick() {}, scrolling: false });
        view.$el.css('z-index', '1002').css('position', 'absolute');// addClass('uiOverAbblender');
        // REALLY REALLY BAD but this is a table...
        view.$el.after('<tr><td>&nbsp;</td></tr>');
        view.options.parent.$('table').addClass('edit');
    },
    hideEditModus() {
        const view = this;
        $(document).abblender('close');
        view.$el.css('z-index', '').css('position', '');
        view.options.parent.$('table').removeClass('edit');
        view.$el.next().remove();
    },
    edit(evt) {
        const view = this;
        const target = $(evt.target);
        $(evt.target).closest('td').find('.listSelector').hide();
        view.showEditModus();

        let i = 0;
        const editables = view.$('.editable');
        editables.each(function each() {
            if ($(this).hasClass('simple')) {
                const thisWidth = $(this).width() - 15;
                const getName = $(this).data('input-name');
                const newDom = _.make('input', {
                    type: 'text',
                    name: getName,
                    class: 'textinput last',
                    value: view.model.get(getName) != null ? view.model.get(getName).trim().replace(/ +/, ' ') : '',
                    style: 'z-index:2000'
                });
                if ($(this).data('maxlength')) {
                    $.attr(newDom, 'maxlength', $(this).data('maxlength'));
                }
                // var newDom='<input type="text" name="'+getName+'" class="textinput last" value="'++'"/>';
                $(this).html(newDom);
                const newInput = $(this).find('input');
                newInput.css('width', thisWidth + 'px');
                $(this).addClass('active');
                if (i === 0) {
                    newInput.focus();
                    newInput.select();
                }
            }
            i += 1;
        });
        target.attr('title', $._('dialog.save'));
        target.addClass('active');
        view.$('.loeschen').attr('title', $._('dialog.cancel'));
        view.$('.loeschen').addClass('abbrechen');
        view.$el.removeClass('hover');
        view.$el.addClass('showHover');
    },
    keyDownEdit(evt) {
        const view = this;
        const target = $(evt.target);
        evt.stopPropagation();
        if (target.hasClass('last')) {
            if (evt.keyCode === 9) {
                evt.preventDefault();
                target.focus();
            }
        }
        if (evt.keyCode === 13) {
            evt.preventDefault();
            view.$('.bearbeiten.active').trigger('click');
        }
        if (evt.keyCode === 27) {
            view.$('.abbrechen').trigger('click');
        }
    },
    toggleSelectReport(e) {
        if (!$(e.target).closest('.report').is(':visible')) {
            return false;
        }
        // var view=this;
        const vorlage = $(e.target).closest('.reportvorlage');
        const category = $(e.target).closest('.reportcategory');

        // Geklickter Report
        if ($(e.target).hasClass('false')) {
            $(e.target).removeClass('false');
            $(e.target).addClass('true');
            $(e.target).closest('.report').addClass('selected');
        } else {
            $(e.target).removeClass('true');
            $(e.target).addClass('false');
            $(e.target).closest('.report').removeClass('selected');
        }

        // Testen ob Vorlage komplett aktiviert
        vorlage.find('.reporttitel, .buttonsammlung_reportzeilen .listSelector').removeClass('true').removeClass('some').removeClass('false');
        let lengthSelected = vorlage.find('.listSelector.true').length;
        let lengthReports = vorlage.find('.reports .listSelector').length;
        let newstate = 'false';
        switch (lengthSelected) {
            case 0:
                newstate = 'false';
                break;
            case lengthReports:
                newstate = 'true';
                break;
            default:
                newstate = 'some';
        }
        vorlage.find('.buttonsammlung_reportzeilen .listSelector, .reporttitel, .typbeschriftung').addClass(newstate);

        // Testen ob Kategorie komplett aktiviert
        category.find('.typbeschriftung, .typbeschriftung .listSelector').removeClass('true').removeClass('some').removeClass('false');
        lengthSelected = category.find('.reports .listSelector.true').length;
        lengthReports = category.find('.reports .listSelector').length;
        switch (lengthSelected) {
            case 0:
                newstate = 'false';
                break;
            case lengthReports:
                newstate = 'true';
                break;
            default:
                newstate = 'some';
        }
        category.find('.typbeschriftung, .typbeschriftung .listSelector').addClass(newstate);

        // Testet ob der Auswahlmoduks überhaupt aktiv ist
        lengthSelected = $('#reportzeilen').find('.listSelector.true').length;
        if (lengthSelected > 0) {
            $('#reportzeilen:not(.selectmode)').addClass('selectmode');
        } else {
            $('#reportzeilen.selectmode').removeClass('selectmode');
        }
    },
    closeSelectModeComplete() {
        $('#reportzeilen.selectmode').removeClass('selectmode');
        $('.reportvorlage .reporttitel, .buttonsammlung_reportzeilen .listSelector').removeClass('true').removeClass('some').removeClass('false');
        $('.reportcategory .typbeschriftung, .typbeschriftung .listSelector').removeClass('true').removeClass('some').removeClass('false');
        $('.listSelector:not(.false)').addClass('false');
    },
    cancelEdit(evt) {
        const view = this;
        const target = $(evt.target);
        view.$('.editable').each(function each() {
            const getName = $(this).data('input-name');
            $(this).html(view.model.get(getName) != null ? view.model.get(getName).trim().replace(/ +/, ' ') : '');
        });
        view.$('.bearbeiten').attr('title', $._('dialog.edit'));
        view.$('.bearbeiten').removeClass('active');
        target.attr('title', $._('dialog.delete'));
        target.addClass('loeschen');
        target.removeClass('abbrechen');
        view.$el.addClass('hover');
        view.$el.removeClass('showHover');
        $(evt.target).closest('td').find('.listSelector').show();
        view.hideEditModus();
    },
    save() {
        const view = this;
        view.model.setForm(view.options.parent.$('form'));
        const wait = new WaitView({
            message: $._('interface.alert.report.save'),
            title: $._('alert.wait.title')
        });
        view.model.save({
            success(formMessage) {
                if (formMessage.hasErrors()) {
                    formMessage.showErrors();
                } else {
                    view.$('.abbrechen').trigger('click');
                }
                wait.remove();
                $.modal.close();
            }
        });
    },
    getCSV() {
        this.embedSavedReport('csv');
        return false;
    },
    getXlsSavedReport() {
        this.embedSavedReport('xls');
        return false;
    },
    getHtmlSavedReport() {
        // eslint-disable-next-line no-new
        new LogListView({ model: this.model });
        return false;
    },
    embedSavedReport(type) {
        const view = this;
        const wait = new WaitView({
            message: $._('interface.alert.get.csv'),
            title: $._('alert.wait.title')
        });
        const name = view.model.get('name') != null ? view.model.get('name') : view.options.parent.model.get('name');
        const startTime = view.model.get('startTime');
        const filename = startTime.replace(new RegExp('\\D', 'g'), '').replace(new RegExp('(\\d{2})(\\d{2})(\\d{4})(\\d{6})', 'g'), '$3$2$1$4') + '_' + name.replace(new RegExp('\\W', 'g'), '_') + '.' + type;
        Download.do({
            url: view.model.urlContent(type),
            filename,
            success() {
                wait.remove();
            },
            error(json) {
                wait.remove();
                AlertView.show({
                    title: $._('alert.error.general'),
                    message: $._('alert.error.what') + '<br>' + json.errorMsg,
                    okFunc() {

                    }
                });
            }
        });
    },
    close() {
        this.$el.remove();
    }
});
export default View;
