import _ from 'underscore';
import Backbone from 'backbone';
import ReportPropertyModel from '../models/ReportPropertyModel';
import Globals from '../../../utils/Globals';
import MabinsoCollection from '../../app/models/MabinsoCollection';

class ReportPropertyCollection extends MabinsoCollection<ReportPropertyModel> {
    url = Globals.baseUrl + 'reportProperties/reportProperty';
    model = ReportPropertyModel;
    // eslint-disable-next-line class-methods-use-this
    comparator = (item: any) => {
        if (_.isUndefined(item.get('dialog_slide'))) {
            return item.get('dialog_seq');
        }
        return [parseInt(item.get('dialog_slide'), 10), parseInt(item.get('dialog_seq'), 10)];
    };
    getGrouped() {
        const slideG: any = this.groupBy((item) => {
            if (_.isUndefined(item.get('dialog_slide'))) {
                return 0;
            }
            return parseInt(item.get('dialog_slide'), 10);
        });
        _.each(slideG, (item, index) => {
            slideG[index] = _.groupBy(item, (item2) => item2.get('dialog_seq'));
        });
        return slideG;
    }
    slides() {
        return _.size(this.getGrouped());
    }
    slideSize(slide: any) {
        const size = this.size(slide);
        console.log('size:' + size);
        return size;
    }
    size(slide?: any) {
        const grouped = this.getGrouped();
        let size = 0;
        _.each(grouped, (slides, index) => {
            if (_.isUndefined(slide) || slide === index) {
                size += _.size(slides);
            }
        });
        return size;
    }
    // eslint-disable-next-line no-unused-vars
    eachSlide(slide: number, func: (model: ReportPropertyModel, index: number) => void): void {
        // console.log(this.first());
        const grouped = this.getGrouped();
        _.each(grouped, (slides, index) => {
            if (_.isUndefined(slide) || slide === parseInt(index, 10)) {
                _.each(slides, (models, index2) => {
                    const model = _.first(models);
                    model.set('propertyArray', models);
                    if (_.isFunction(func)) {
                        func(model, index2);
                    }
                });
            }
        });
    }
    each(slide?: any, func?: any): any {
        // console.log(this.first());
        const grouped = this.getGrouped();
        _.each(grouped, (slides, index) => {
            if (_.isUndefined(slide) || slide === parseInt(index, 10)) {
                _.each(slides, (models, index2) => {
                    const model = _.first(models);
                    model.set('propertyArray', models);
                    if (_.isFunction(func)) {
                        func(model, index2);
                    }
                });
            }
        });
    }
}
export default ReportPropertyCollection;
