import React from 'react';
import $ from 'jquery';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner, CommonsInfo } from '@mabinso-registry/module-commons';
import './css/style.scss';
import AlertView from '../app/views/AlertView';
import Globals from '../../utils/Globals';
import auth from '../../utils/Auth';


class Login extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            disabled: false,
            remember: false
        };
        const comp = this;
        const { location } = this.props;
        if (this.props.match.params.token) {
            auth.setToken(this.props.match.params.token);
            this.props.history.replace({
                pathname: '/',
                state: { from: props.location }
            });
        }
        this.toggleState = () => this.setState({ remember: !comp.state.remember });
        this.handlePasswordChange = ev => this.setState({ password: ev ? ev.target.value : '' });
        this.handleUsernameChange = ev => this.setState({ username: ev ? ev.target.value : '' });
        this.handleSubmit = e => {
            if (e) e.preventDefault();
            $('#username').validationEngine('hideAll');
            this.setState({ disabled: true });
            const username = this.state.username.trim();
            const password = this.state.password.trim();
            const { remember } = this.state;
            $.ajax({
                url: Globals.baseUrl + 'login',
                dataType: 'json',
                type: 'POST',
                data: { username, password, remember },
                success: (data, textStatus, request) => {
                    if (!data.hasErrors) {
                        const token = request.getResponseHeader('X-Authorisation');
                        let successMsg = null;
                        if (data.success && data.success.length > 0) {
                            successMsg = $._(data.success[0].message, data.success[0].messageKey);
                            AlertView.show({
                                title: $._('alert.login.general'),
                                message: successMsg,
                                okFunc() {}
                            });
                        }
                        $('#login').slideUp({
                            duration: 1200,
                            easing: 'linear',
                            complete() {
                                $('#login').remove();
                            }
                        });

                        auth.setToken(token, remember);
                        console.log('----------------------------------start Token', localStorage.token);
                        localStorage.setItem('isSaml', false);
                        if (location.state && location.state.nextPathname) {
                            this.props.history.replace(location.state.nextPathname);
                        } else {
                            this.props.history.replace('/');
                        }
                    } else {
                        comp.setState({ disabled: false });
                        $('#username').validationEngine('showPrompt', $._(data.errors[0].message, data.errors[0].messageKey), '', 'topRight', true);
                    }
                },
                error() {
                    console.error('XHR Error');
                }
            });
        };
    }

    render() {
        return (
            <div id="login" className="login-wrapper">
                <div id="hauptrahmen">
                    <div className="loginscreen">
                        <Spinner load={this.state.spinner} mode="l" id="Login-Spinner" />
                        <form onSubmit={this.handleSubmit}>
                            <fieldset>
                                <div className="input text">
                                    <label>{$._('secure.username')}</label><input type="text" name="username" id="username" value={this.state.username} onChange={this.handleUsernameChange} />
                                </div>
                                <div className="input text">
                                    <label>{$._('secure.password')}</label><input type="password" name="password" id="password" value={this.state.password} onChange={this.handlePasswordChange} />
                                </div>
                                <div className="input checkbox">
                                    <div className="checkboxGroup">
                                        <div role="presentation" className={'option ' + (this.state.remember ? 'active' : '')} onClick={this.toggleState}>
                                            <input className="remember" checked={this.state.remember} type="checkbox" name="remember" id="remember" value="true" />
                                            <span className="optBeschriftung">{$._('secure.remember')}</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <p className="version" style={{ color: '#01aad3', fontSize: '10px' }}>
                                Version: {Globals.serverConfig.version}&nbsp;
                                <Link to="/info">
                                    {$._('index.licence')}
                                </Link>
                                <br />
                                F: {process.env.REACT_APP_VERSION}/{process.env.REACT_APP_HASH}|C:{CommonsInfo.version}/{CommonsInfo.hash}
                                <br />
                            </p>
                            <input type="submit" id="signin" disabled={this.state.disabled} className="login" value={$._('secure.signin')} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
