/* eslint-disable */
import jQuery from 'jquery';
import Globals from '../../utils/Globals';
const baseUrl = Globals.baseUrl;
(function($){
    $.fn.validationEngineLanguage = function(){
    };
    $.validationEngineLanguage = {
        newLang: function(){
            $.validationEngineLanguage.allRules = {
                "required": { // Add your regex rules here, you can take telephone as an example
                    "regex": "none",
                    "alertText": 'validation.required.alertText',
                    "alertTextCheckboxMultiple": 'validation.required.alertTextCheckboxMultiple',
                    "alertTextCheckboxe": 'validation.required.alertTextCheckboxe'
                },
                "minSize": {
                    "regex": "none",
                    "alertText": 'validation.minSize.alertText',
                    "alertText2": 'validation.minSize.alertText2'
                },
                "maxSize": {
                    "regex": "none",
                    "alertText": 'validation.maxSize.alertText',
                    "alertText2": 'validation.maxSize.alertText2'
                },
				"groupRequired": {
                    "regex": "none",
                    "alertText": 'validation.groupRequired.alertText'
                },
                "min": {
                    "regex": "none",
                    "alertText": 'validation.min.alertText'
                },
                "max": {
                    "regex": "none",
                    "alertText": 'validation.max.alertText'
                },
                "past": {
                    "regex": "none",
                    "alertText": 'validation.past.alertText'
                },
                "future": {
                    "regex": "none",
                    "alertText": 'validation.future.alertText'
                },	
                "maxCheckbox": {
                    "regex": "none",
                    "alertText": 'validation.maxCheckbox.alertText'
                },
                "minCheckbox": {
                    "regex": "none",
                    "alertText": 'validation.minCheckbox.alertText',
                    "alertText2": 'validation.minCheckbox.alertText2'
                },
                "equals": {
                    "regex": "none",
                    "alertText": 'validation.equals.alertText'
                },
                "phone": function(field, rules, i, options){
                	var ex= $._('validation.phone.regex');
                	var alertText = 'validation.phone.alertText';
                	var pattern = new RegExp(ex);                	
    	            if (!pattern.test(field.val()))
    	                return alertText;
                },
                "email": {
                    // Shamelessly lifted from Scott Gonzalez via the Bassistance Validation plugin http://projects.scottsplayground.com/email_address_validation/
                    "regex": /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
                    "alertText": 'validation.email.alertText'
                },
                "integer": {
                    "regex": /^[\-\+]?\d+$/,
                    "alertText": 'validation.integer.alertText'
                },
                "number": function(field, rules, i, options){
                	var ex= $._('validation.number.regex');
                	var alertText = 'validation.number.alertText';
                	var pattern = new RegExp(ex);                	
    	            if (!pattern.test(field.val()))
    	                return alertText;
                },
				"dateISO": {
                    // Date in ISO format. Credit: bassistance
                    "regex": /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/,
                    "alertText": 'validation.dateISO.alertText'
                },
                "ipv4": {
                	"regex": /^((([01]?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))[.]){3}(([0-1]?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))$/,
                    "alertText": 'validation.ipv4.alertText'
                },
                "url": {
                    "regex": /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
                    "alertText": 'validation.url.alertText'
                },
                "onlyLetterSp": {
	                 "regex": /^[a-zA-ZäüöÄÜÖßs\ \\\']+$/,
                     "alertText": 'validation.onlyLetterSp.alertText'
                },
                "onlyLetterNumber": {
                    "regex": /^[0-9a-zA-Z]+$/,
                    "alertText":  'validation.onlyLetterNumber.alertText'
                },
                // --- CUSTOM RULES -- Those are specific to the demos, they can be removed or changed to your likings
                "specialName": {
                    "regex": /^[A-Za-zöäüÖÄÜß0-9_ \-\(\)\.\,\/'"\`]{2,50}$/,
                    "alertText": 'validation.match.name'
                },
                "ajaxUserCall": {
                    "url": "ajaxValidateFieldUser",
                    // you may want to pass extra data on the ajax call
                    "extraData": "name=eric",
                    "alertText": "",
                    "alertTextLoad": ""
                },
                "ajaxUserNameCall": {
                    // remote json service location
                    "url": baseUrl+"users/checkUsername",
                    // error
                    "alertText": 'validation.ajaxUserNameCall.alertText',
                    // if you provide an "alertTextOk", it will show as a green prompt when the field validates
                    "alertTextOk": 'validation.ajaxUserNameCall.alertTextOk',
                    // speaks by itself
                    "alertTextLoad": 'validation.ajaxUserNameCall.alertTextLoad',
                    "extraDataDynamic": ['#id']
                },
                "validate2fields": {
                    "alertText": "Bitte HELLO eingeben"
                },
                "time": {
                	"regex": /^(([0-9])|([0-1][0-9])|([2][0-3])):(([0-9])|([0-5][0-9]))$/,
                    "alertText": 'validation.time.alertText'
                },
                "date": function(field, rules, i, options){
					//console.log(field);
					//console.log(i);
					//console.log(rules);
					//console.log(options);
					if (field.val() != ""){
						var mydate = Date.parseExact(field.val(),Date.CultureInfo.formatPatterns.parseExact);
						if (mydate == null)
							return 'validation.date.alertText';
						else{
							field.val(mydate.toString(Date.CultureInfo.formatPatterns.shortDate));
						}
					}
					
                },
                "emails": function(field, rules, i, options){
    				var replaced = field.val().replace(/;/,",");
    				var splited = replaced.split(',');
    				var error = false;
    				$.each(splited,function(index,item){					
    					if (! options.allrules.email.regex.test(item)){
    						console.log("Fehler Emails");
    						error = true;
    						return false;
    					}
    				});
    				if (error){
    					return $._(options.allrules.email.alertText);
    				}				
    			},
                "path": function(field, rules, i, options){
                    var value = field.val();
                    value = value.replace(/\//g,"\\");
                    var error = false;
                    var reg = /^(((\\)\\)|(\\))?(((\.)|(\.\.)|([^\\/:\*\?"\|<>\. ](([^\\/:\*\?"\|<>\. ])|([^\\/:\*\?"\|<>]*[^\\/:\*\?"\|<>\. ]))?))\\)*[^\\/:\*\?"\|<>\. ](([^\\/:\*\?"\|<>\. ])|([^\\/:\*\?"\|<>]*[^\\/:\*\?"\|<>\. ]))?$/;
                    if(value.charAt(0) == "\\")
                    {
                        error = true;
                    }else {
                        if (!reg.test(value)) {
                            error = true;
                        }
                    }
                    if (error){
                        return $._('validation.path');
                    }
                }
            };
            
        }
    };
    $.validationEngineLanguage.newLang();
})(jQuery);


    
