import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import ProtocolCollection from '../models/ProtocolCollection';
import RowView from './RowView';
import html from '../templates/list_view.template';
import Globals from '../../../utils/Globals';

const ProtocolView = Backbone.View.extend({
    detailView: null,
    collection: null,
    page: 1,
    initialize() {
        this.page = 1;
        this.collection = new ProtocolCollection();
        $('body').scrollTo(0, 0);
    },
    endlessscroll() {
        const tabellenhoehe = parseInt($('.standardtable').height(), 10) + parseInt($('.standardtable').offset().top, 10);
        const fensterhoehe = $(window).height();
        const scrollpos = window.pageYOffset;
        if (tabellenhoehe >= fensterhoehe) {
            if (tabellenhoehe - scrollpos <= fensterhoehe + 10) {
                $('.moreloader').trigger('click');
            }
        }
    },
    render() {
        const { collection } = this;
        const view = this;
        $(this.el).spinner();
        const tstring = _.template(html)({ $, sessionUser: Globals.user });
        view.$el.html(tstring);
        view.renderLevels();
        view.renderMessages();
        view.$('#protocolFrom').datepicker({
            changeMonth: true,
            changeYear: true,
            onSelect(dateText) {
                view.$('#protocolTo').datepicker('option', 'minDate', dateText);
                view.$('#protocol-searchform').validationEngine('updateRule', '#protocolTo', 'past', dateText);
                if (view.$('#protocol-searchform').validationEngine('validate')) {
                    view.search();
                }
                return true;
            }
        });
        view.$('#protocolTo').datepicker({
            changeMonth: true,
            changeYear: true
        });

        collection.fetch({
            data: { page: view.page },
            success(collection2) {
                $(window).scroll(() => {
                    view.endlessscroll();
                });
                view.renderRows(collection2);
                if (collection.size() >= 25) {
                    view.$('.moreloader').show();
                }
            }

        });
    },
    renderRows(collection) {
        const view = this;
        view.$('#logzeilen tr.gerade,#logzeilen tr.ungerade').remove();
        this.addRows(collection);
        if (collection.size() < 25) {
            view.$('.moreloader').hide();
        } else {
            view.$('.moreloader').show();
        }
    },
    renderLevels() {
        const view = this;
        $.ajax({
            url: Globals.baseUrl + 'protocols/getLevels',
            success(data) {
                _.each(data, item => {
                    const option = _.make('option', { value: item.level }, $._('log.table.line.state.' + item.level));
                    view.$('#protocolLevel').append(option);
                });
                view.$('#fieldsetProtocolLevel').uiselectfield('init');
            }
        });
    },
    renderMessages() {
        const view = this;
        $.ajax({
            url: Globals.baseUrl + 'protocols/getMessages',
            success(data) {
                _.each(data, item => {
                    const option = _.make('option', { value: item.message }, $._(item.message + '.filter'));
                    view.$('#protocolMessage').append(option);
                });
                view.$('#fieldsetProtocolMessage').uiselectfield('init');
            }
        });
    },
    addRows(collection) {
        const view = this;
        if (collection.length > 0) {
            let i = 0;
            collection.each(model => {
                const el = _.make('tr', {
                    id: 'protocol' + model.id,
                    'data-id': model.id,
                    class: 'rows ' + model.get('level') + ' ' + (i % 2 === 0 ? 'gerade' : 'ungerade')
                });
                i += 1;
                const rview = new RowView({
                    el,
                    model,
                    protocolView: view
                });
                rview.render();
                view.$('#logzeilen tr:last').after(el);
            });
            // view.reportHasReportsSaved(true);
        }
    },
    events: {
        'click .rows': 'mark',
        'click .delete': 'delete',
        'click .moreloader': 'showMore',
        'keyup #namesearch': 'search',
        'change #protocolLevel': 'search',
        'change #protocolMessage': 'search',
        'change fieldset.protocol-from-to input': 'search',
    },
    mark(event) {
        const target = event.currentTarget;
        $(target).toggleClass('active');
    },
    delete() {
        const ids = [];
        const view = this;
        $('.rows.active').each(function each() {
            const id = $(this).data('id');
            ids.push(id);
        });
        ProtocolCollection.deleteItems({
            items: ids,
            success() {
                view.search();
            }
        });
    },
    showMore() {
        const view = this;
        $(window).unbind('scroll');
        view.$('.moreloader').hide();
        view.$('.table-animate').show();
        view.$('.animate').pnganimation('init', { time: 50 });
        console.log('neue daten');
        const { collection } = view;
        collection.fetch({
            data: {
                page: view.page + 1,
                protocolUsername: ($('#namesearch').val() !== '' ? $('#namesearch').val() : undefined),
                protocolMessage: ($('#protocolMessage').val() !== '' ? $('#protocolMessage').val() : undefined),
                protocolLevel: ($('#protocolLevel').val() !== '' ? $('#protocolLevel').val() : undefined),
                protocolFrom: ($('#protocolFrom').val() !== '' ? $('#protocolFrom').val() : undefined),
                protocolTo: ($('#protocolTo').val() !== '' ? $('#protocolTo').val() : undefined)
            },
            success(collection2) {
                view.$('.table-animate').hide();
                if (collection.size() > 0) {
                    view.addRows(collection2);
                    if (collection2.size() >= 25) {
                        console.debug('more:' + view.page);
                        view.page += 1;
                        view.$('.moreloader').show();
                        $(window).scroll(() => {
                            view.endlessscroll();
                        });
                    }
                }
            }
        });
        return false;
    },
    search() {
        $('body').scrollTo(0, 0);
        const view = this;
        view.page = 1;
        // var ele = $(evt.target);
        this.collection.fetch({
            data: {
                protocolUsername: ($('#namesearch').val() !== '' ? $('#namesearch').val() : undefined),
                protocolMessage: ($('#protocolMessage').val() !== '' ? $('#protocolMessage').val() : undefined),
                protocolLevel: ($('#protocolLevel').val() !== '' ? $('#protocolLevel').val() : undefined),
                protocolFrom: ($('#protocolFrom').val() !== '' ? $('#protocolFrom').val() : undefined),
                protocolTo: ($('#protocolTo').val() !== '' ? $('#protocolTo').val() : undefined)
            },
            success(collection) {
                view.renderRows(collection);
            }
        });
    }
});
export default ProtocolView;
