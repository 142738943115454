import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Multiselect from '../../../app/components/form/multiselect/Multiselect';
import html from '../../templates/edit_report_saved_view/multiselect_cols_view.template';

const MultiSelectView = Backbone.View.extend({
    initialize(options) {
        this.options = options;
        this.render();
    },
    render() {
        const view = this;
        view.$el.spinner();
        view.options.property.fetchPropertyValues({
            success() {
                view.$el.html(_.template(html)({ $, property: view.options.property }));
                view.renderElements(view.options.property);
                if (_.isFunction(view.options.success))view.options.success();
            }
        });
    },
    renderElements(property) {
        // var view = this;
        const data = [];
        let defaultValue = property.get('defaultValue');
        if (defaultValue) {
            defaultValue = defaultValue.split(',');
        }
        _.each(property.get('propertyValues'), item => {
            // view.$('select').append(view.make("option",{'value':item.id},item.value));
            let checked = false;
            if (defaultValue) {
                _.each(defaultValue, dv => {
                    if (dv === item.value) {
                        checked = true;
                    }
                });
            }
            data.push({ value: item.id, text: item.value, checked });
        });
        const multiselect = new Multiselect(property.id);
        multiselect.init(data, 'multi_' + property.id, 'cols');
    },
    events: {

    }
});
export default MultiSelectView;
