import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ui, Document } from '@mabinso-registry/module-commons';
import Login from './components/login/Login';
import App from './components/app/App';
import Init from './utils/Init';
import auth from './utils/Auth';
import Info from './components/info/Info';
import Globals from './utils/Globals';
// import Ajax from './utils/Ajax';
// import MyDocument from './components/document/Document';
import Detail from './components/report/components/detail/Detail';

class Logout extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired
    };

    componentWillMount() {
        const samlEnabled = Globals.saml;
        // Ajax.getJson('saml/doLogout');
        const token = auth.getToken();
        const isSaml = localStorage.getItem('isSaml');
        localStorage.removeItem('saml');
        localStorage.removeItem('isSaml');
        auth.logout(() => {});
        if (samlEnabled && isSaml === 'true') {
            window.location.href = Globals.baseUrl + 'saml/doLogout?redirect=' + Globals.rootUrl + '&token=' + token;
        } else {
            this.props.history.replace({ pathname: '/login' });
        }
        // throw new Error('logout');
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            // navigated to me aga!
        }
    }

    render() {
        return null;
    }
}

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [isInit, initLoaded] = useState(Globals.user !== null);
    const init = ({ location, history }) => {
        Init.init({
            success: () => {
                if (Globals.user === null) {
                    Init.initAfterLogin({
                        success: () => {
                            const firstLink = Globals.actions.getFirstLink();
                            if (location.pathname === '/' && firstLink === 'index.reports' && Globals.user.get('startReportSaved_id') !== null) {
                                history.replace({
                                    pathname: '/index.reports/' + Globals.user.get('startReportSaved_id')
                                });
                            } else if (location.pathname === '/') {
                                history.replace({
                                    pathname: '/' + firstLink
                                });
                            }
                            initLoaded(true);
                        }
                    });
                }
            }
        });
    };
    return (
        <Route
            {...rest}
            render={props => {
                const samlEnabled = Globals.saml;
                const { location } = props;
                const saml = localStorage.getItem('saml');
                const isSaml = localStorage.getItem('isSaml');
                if ((auth.loggedIn() && (!samlEnabled || isSaml === 'false')) || (auth.loggedIn() && samlEnabled && location.pathname !== '/') || (auth.loggedIn() && samlEnabled && saml === 'true')) {
                    if (isInit) {
                        localStorage.removeItem('saml');
                        return <Component {...props} />;
                    }
                    init(props);
                } else if (samlEnabled) {
                    localStorage.setItem('saml', true);
                    localStorage.setItem('isSaml', true);
                    return (<Redirect
                        to={{
                            pathname: '/saml/login',
                            state: { from: props.location }
                        }}
                    />);
                } else {
                    return (<Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />);
                }
                return null;
            }}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
    location: PropTypes.any
};

PrivateRoute.defaultProps = {
    location: ''
};

const InitRoute = ({ component: Component, ...rest }) => {
    const [isInit, initLoaded] = useState(Globals.user !== null);
    return (
        <Route
            {...rest}
            render={props => {
                if (isInit) {
                    return <Component {...props} />;
                }
                Init.init({ success: () => initLoaded(true) });
                return null;
            }}
        />
    );
};

InitRoute.propTypes = {
    component: PropTypes.any.isRequired,
    location: PropTypes.any
};

InitRoute.defaultProps = {
    location: ''
};
if (process.env.NODE_ENV === 'production' && Globals.clientConfig.sentry) {
    const integrations = [];
    if (Globals.clientConfig.sentryTracing) {
        integrations.push(new BrowserTracing({
            tracingOrigins: [Globals.baseUrl]
        }));
    }
    Sentry.init({
        dsn: 'https://4764d0021a0e4bfebda3c9632a4c9a25@sentry.mabinso-server.de/2',
        integrations,
        release: process.env.REACT_APP_VERSION,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

const InfoDoc = (props) => (<Document linkToInfo={false} {...props}><Info /></Document>);
const ReportDoc = (props) => (<Document closeLink="/" {...props}><Detail {...props} /></Document>);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<ui.ThemeProvider theme={ui.theme}>
    <ui.CssBaseline />
    <ui.StyledEngineProvider injectFirst>
        <Router>
            <Switch>
                <InitRoute
                    path="/saml/login"
                    component={() => {
                        window.location.href = Globals.baseUrl + 'saml/login?redirect=' + Globals.rootUrl + '/login';
                        return null;
                    }}
                />
                <InitRoute exact path="/login/:token?" component={Login} />
                <Route exact path="/logout" component={Logout} />
                <InitRoute exact path="/info" component={InfoDoc} />
                <InitRoute exact path="/report/:reportSavedId/:format/:token" component={ReportDoc} />
                <PrivateRoute path="/" component={App} />
            </Switch>
        </Router>
    </ui.StyledEngineProvider>
</ui.ThemeProvider>);
