import _ from 'underscore';
import Backbone from 'backbone';
import { Globals, Ajax } from '@mabinso-registry/module-commons';
import MabinsoModel from '../../app/models/MabinsoModel';

export type DialogType = 'date_from_to' | 'date_singleday' | 'combobox' | 'multiselect' | 'tableselect' | 'multiselect_cols';
export type IType = 'java.util.Date' | 'java.lang.String';

interface IReportPropertyModel {
    dialogSeq: number;
    dialogSlide: number;
    description: string;
    dialogSql?: string;
    dialogType: DialogType;
    reportId: number;
    type: IType;
}

class ReportPropertyModel extends MabinsoModel implements IReportPropertyModel {
    urlRoot = Globals.baseUrl + 'reports/report';
    urlPropertyValues = 'reportProperties/getReportPropertyValues';
    set dialogSeq(value: number) {
        this.set('dialog_seq', value);
    }
    get dialogSeq(): number {
        return parseInt(this.get('dialog_seq'), 10);
    }
    set dialogSql(value: string) {
        this.set('dialog_sql', value);
    }
    get dialogSql(): string {
        return this.get('dialog_sql');
    }
    set dialogElement(value: string) {
        this.set('dialog_sql', value);
    }
    get dialogElement(): string {
        return this.get('dialog_element');
    }
    set reportId(value: number) {
        this.set('report_id', value);
    }
    get reportId(): number {
        return parseInt(this.get('report_id'), 10);
    }
    set description(value: string) {
        this.set('description', value);
    }
    get description(): string {
        return this.get('description');
    }
    get dialogSlide(): number {
        return parseInt(this.get('dialog_slide'), 10);
    }
    get dialogType(): DialogType {
        return this.get('dialog_typ') || this.get('dialog_type');
    }
    get type(): IType {
        return this.get('type');
    }
    fetchPropertyValues(options: any = {}) {
        const model = this;
        const data = model.getSqlData();
        // if data
        data.report_id = model.get('report_id');
        data.property_name = model.id;
        console.log(model.urlPropertyValues, Globals.baseUrl);
        const ajax = Ajax.getJson({
            url: _.isUndefined(options.url) ? model.urlPropertyValues : options.url,
            json: data,
        });
        if (_.isFunction(options.success)) {
            ajax.then((data2) => {
                model.set('propertyValues', data2);
                options.success(model, data2);
            });
        }
        return ajax;
    }
    getSqlData() {
        let data: any = {};
        if (!_.isUndefined(this.get('formValues')) && !_.isUndefined(this.get('dialog_sql_param_0'))) {
            let i = 0;
            do {
                const parameter = this.get('dialog_sql_param_' + i);
                const formValues = this.get('formValues');
                if (!_.isUndefined(formValues[parameter])) {
                    data[parameter] = formValues[parameter];
                }
                i += 1;
            } while (!_.isUndefined(this.get('dialog_sql_param_' + i)));
        } else if (!_.isUndefined(this.get('formValues'))) {
            data = this.get('formValues');
        }
        return data;
    }
}
export default ReportPropertyModel;
