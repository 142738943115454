import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import html from '../templates/row_view.template';
import Globals from '../../../utils/Globals';

const RowView = Backbone.View.extend({
    initialize() {
    },
    render() {
        const view = this;
        const tstring = _.template(html)({ model: view.model, i18n: Globals.i18n });
        view.$el.html(tstring);
    },
    events: {
        'click .benutzerliste li': 'showDetail'
    },
    showDetail(event) {
        const id = $(event.target).closest('li').data('id');
        console.log(id);
        this.detailView.render(id);
    }
});
export default RowView;
