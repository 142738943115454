import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import ActionModel from './ActionModel';
import Globals from '../../../utils/Globals';

const ActionCollection = Backbone.Collection.extend({
    url: Globals.baseUrl + 'actions/action',
    urlReset: Globals.baseUrl + 'actions/reset',
    model: ActionModel,
    resetAll(options, data) {
        const col = this;
        $.ajax({
            url: col.urlReset,
            data,
            dataType: 'JSON',
            success(data2) {
                if (_.isFunction(options.success)) {
                    options.success(data2);
                }
            }
        });
    },
    // // Abschnitt sortiert collection (auch strings)
    // // Erklärende Kommentare in RoleCollection und RoleView
    sort_key: '',
    sort_order: 1,
    comparator(item1, item2) {
        let val1 = item1.get(this.sort_key);
        let val2 = item2.get(this.sort_key);
        if (typeof (val1) === 'string') {
            val1 = val1.toLowerCase();
            val2 = val2.toString().toLowerCase();
        }
        const sortValue = val1 > val2 ? 1 : -1;
        return sortValue ** this.sort_order;
    },
    sortByField(fieldName, orderType) {
        this.sort_key = fieldName;
        this.sort_order = orderType === 'desc' ? -1 : 1;
        this.sort();
    },
});
export default ActionCollection;
