import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import ReportJobModel from '../models/ReportJobModel';
import AlertView from '../../app/views/AlertView';
import html from '../templates/plan_report_view.template';
import Globals from '../../../utils/Globals';

const PlanReportView = Backbone.View.extend({
    initialize() {

    },
    render() {
        const view = this;
        const compiled = (_.template(html)({
            $,
            sessionUser: Globals.user,
            options: new ReportJobModel().everySelect,
            title: $._('report.plan.title'),
            canchel: $._('dialog.cancel'),
            savepath: Globals.serverConfig.reportjob_save_path,
            help: $._('dialog.help'),
            ok: $._('report.plan.new.button.start')
        }));

        $.modal(
            compiled,
            {
                overlayId: 'simplemodal-overlay',

                minWidth: '680px',
                maxWidth: '680px',
                containerId: 'modalWidget',
                persist: true,
                overlayClose: false,
                onShow() {
                    // view.setElement("body");
                    view.setElement('.modalWidget');
                    view.$('#mabinso_start').datepicker({
                        changeMonth: true,
                        changeYear: true
                    });
                    view.$('#plan-report-settings').uiselectfield('init');
                    view.$('#plan-report-settings').uicheckbox('init');

                    view.$('#plan-report-settings .tags').tagsInput({
                        hide: false,
                        height: 'auto',
                        width: '222px',
                        defaultText: $._('wizzard.plan.form.label.email.more')
                    });

                    view.$('.timepicker').timepicker({
                        timeOnlyTitle: $._('wizzard.plan.form.timepicker.timeOnlyTitle'),
                        timeText: $._('wizzard.plan.form.timepicker.timeText'),
                        hourText: $._('wizzard.plan.form.timepicker.hourText'),
                        minuteText: $._('wizzard.plan.form.timepicker.minuteText'),
                        secondText: $._('wizzard.plan.form.timepicker.secondText'),
                        currentText: $._('wizzard.plan.form.timepicker.currentText'),
                        closeText: $._('wizzard.plan.form.timepicker.closeText')
                    });

                    $('html').css('overflow', 'hidden');
                },
                onClose() {
                    $('html').css('overflow', '');
                    $.modal.close();
                    view.$el.validationEngine('hideAll');
                }
            }
        );
    },
    events: {
        'click .modalYes': 'btSave',
        'click .modalHelp': 'openhelp'
    },
    openhelp(e) {
        // var view=this;
        $('#hilfebutton').trigger('click');
        $(e.currentTarget).toggleClass('active');
    },
    btSave() {
        this.model.setForm(this.$('form'));
        this.model.save(formMessage => {
            if (formMessage.showErrors()) {
                $.modal.close();
                // Bestaetigungsfenster
                AlertView.show({
                    message: $._('report.plan.success'),
                    title: $._('alert.action.success')
                });
            }
        });
        return false;
    }
});
export default PlanReportView;
