import MabinsoModel from '../../app/models/MabinsoModel';
import Globals from '../../../utils/Globals';

class SchemaModel extends MabinsoModel {
    backend = true;
    urlRoot = Globals.baseUrl + 'schemas/schema';
    defaults = () => ({
        name: '',
        description: '',
        sclass: '',
        port: '',
        host: '',
        database: '',
        user: '',
        password: '',
    });
}

export default SchemaModel;
