import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import html from '../../templates/edit_report_saved_view/multiselect_view.template';

const MultiSelectView = Backbone.View.extend({
    initialize(options) {
        this.options = options;
        this.render();
    },
    render() {
        const view = this;
        view.$el.spinner('on', { view });
        view.options.property.fetchPropertyValues({
            success() {
                view.$el.html(_.template(html)({ $, property: view.options.property }));
                view.renderElements(view.options.property);
                if (_.isFunction(view.options.success))view.options.success();
            }
        });
    },
    renderElements(property) {
        const view = this;
        let defaultValue = property.get('defaultValue');
        let value = property.get('value');
        if (value) {
            value = value.split(',');
        } else if (defaultValue) {
            defaultValue = defaultValue.split(',');
        }
        _.each(property.get('propertyValues'), item => {
            // view.$('select').append(view.make("option",{'value':item.id},item.value));
            let checked = false;
            if (value) {
                _.each(value, dv => {
                    if (dv === item.id) {
                        checked = true;
                    }
                });
            } else if (defaultValue) {
                _.each(defaultValue, dv => {
                    if (dv === item.value) {
                        checked = true;
                    }
                });
            }
            const div = _.make('div', { class: 'option' });
            const inOptions = {
                type: 'checkbox', name: property.id, id: property.id, value: item.id, class: ''
            };
            if (checked) {
                inOptions.checked = 'checked';
            }
            const input = _.make('input', inOptions);
            const span = _.make('span', { class: 'optBeschriftung' }, item.value);
            $(div).append(input);
            $(div).append(span);
            view.$('.actionsCheckbox').append(div);
        });
        view.$el.uicheckbox('init');
    },
    events: {

    }
});
export default MultiSelectView;
