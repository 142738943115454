import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import Globals from '../../utils/Globals';
import InterfaceListView from './views/InterfaceListView';

class Interface extends React.Component {
    static propTypes = {
        spinner: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const actview = new InterfaceListView();
        $('#interface_component').html($(actview.el));
        actview.render();
        Globals.interfaceRunView.interfaceView = actview;
        this.props.spinner(false);
    }

    componentWillUnmount() {
        console.log('-----------------------Interfaceview is Unmounting');
        Globals.interfaceRunView.interfaceView = null;
    }

    render() {
        return (
            <div id="interface_component" />
        );
    }
}

export default Interface;
