import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import ProtocolModel from './ProtocolModel';
import Globals from '../../../utils/Globals';

const ProtocolCollection = Backbone.Collection.extend({
    url: Globals.baseUrl + 'protocols/protocol',
    model: ProtocolModel
}, {
    deleteItems(options) {
        console.log(options.items);
        $.ajax({
            url: Globals.baseUrl + 'protocols/deleteItems',
            data: { ids: options.items },
            traditional: true,
            type: 'POST',
            dataType: 'JSON',
            success(data) {
                if (_.isFunction(options.success)) {
                    options.success(data);
                }
            }
        });
    }
});
export default ProtocolCollection;
