import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Collection from '../models/InterfaceLogCollection';
import ModalView from '../../app/views/ModalView';
import WaitView from '../../app/views/WaitView';
import RowView from './RowView';
import html from '../templates/finished_log_view.template';

const View = Backbone.View.extend({
    page: 1,
    initialize(options) {
        this.options = options || [];
        this.render();
    },
    endlessscroll() {
        const view = this;
        const tabellenhoehe = parseInt(view.$('.scrollarea .standardtable').height(), 10);
        const fensterhoehe = parseInt(view.$('.scrollarea').css('max-height'), 10);
        const scrollpos = view.$('.scrollarea').scrollTop();
        if (tabellenhoehe >= fensterhoehe) {
            if (tabellenhoehe - scrollpos <= fensterhoehe) {
                $('.moreloader').trigger('click');
            }
        }
    },
    render() {
        const view = this;
        const etlName = _.isUndefined(view.options.name) ? 'AA' : ': ' + view.options.name;
        WaitView.show({
            message: $._('report.list.showPlanned.wait'),
            title: $._('alert.wait.title') + etlName
        });
        view.collection = new Collection();
        view.collection.fetch({
            data: { id: view.model.id, page: 1 },
            success(collection) {
                const compiled = _.template(html)({ col: collection, $ });
                $.modal.close();
                ModalView.show({
                    title: $._('interface.log.title'),
                    message: compiled,
                    closeString: $._('interface.log.close'),
                    success() {
                        view.setElement('#modalModal');
                        view.renderRows(collection);
                        if (collection.size() >= 6) {
                            view.$('.scrollarea .standardtable').after('<div class="spinnerS scrollingloader"><a href="#" class="moreloader">' + $._('general.moreloader.more') + '</a></div>');
                        }
                        view.$('.scrollarea').scroll(() => {
                            view.endlessscroll();
                        });
                    }
                });
            }
        });
    },
    renderRows(collection) {
        const view = this;
        view.$('tr.gerade,tr.ungerade').remove();
        this.addRows(collection);
        if (collection.size() < 3) {
            view.$('.moreloader').hide();
        } else {
            view.$('.moreloader').show();
        }
    },
    addRows(collection) {
        const view = this;
        if (collection.length > 0) {
            collection.each(model => {
                const el = _.make('tr', {
                    id: 'protocol' + model.id,
                    'data-id': model.id,
                    class: model.get('level') + ' ' + (view.$('.scrollarea .standardtable tr').length % 2 === 0 ? 'gerade' : 'ungerade')
                });
                const rview = new RowView({
                    el,
                    model,
                    protocolView: view
                });
                rview.render();
                view.$('.scrollarea .standardtable').append(el);
            });
        }
    },
    showMore() {
        const view = this;
        view.$('.scrollarea').unbind('scroll');
        view.$('.scrollingloader').detach();
        view.$('.scrollarea .standardtable').after('<div class="spinnerS scrollingloader"><div class="animate"></div>' + $._('general.spinner.loading') + '</div>');
        view.$('.animate').pnganimation('init', { time: 50 });
        const { collection } = view;
        collection.fetch({
            data: { id: view.model.id, page: view.page + 1 },
            success(collection2) {
                $('.scrollingloader').detach();
                if (collection2.size() > 0) {
                    view.addRows(collection);
                    if (collection2.size() >= 3) {
                        view.page += 1;
                        view.$('.scrollarea .standardtable').after('<div class="spinnerS scrollingloader"><a href="#" class="moreloader">' + $._('general.moreloader.more') + '</a></div>');
                        view.$('.scrollarea').scroll(() => {
                            view.endlessscroll();
                        });
                    }
                }
            }
        });
        return false;
    },
    events: {
        'click .moreloader': 'showMore'
    }
});
export default View;
