import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import html from '../templates/modal_view.template';

const ModalView = Backbone.View.extend({
    el: 'div#hauptrahmen',
    initialize(options) {
        this.options = options || [];
        this.render();
    },
    render() {
        const view = this;
        if (!_.isUndefined(view.options.closeString)) {
            view.options.yesString = view.options.closeString;
        } else if (_.isUndefined(view.options.noString) || (_.isBoolean(view.options.noString) && view.options.noString)) {
            view.options.closeString = $._('alert.action.cancel');
        } else {
            view.options.closeString = view.options.noString;
        }
        view.options.helpString = $._('dialog.help');
        const compiled = _.template(html)({
            $,
            title: view.options.title,
            message: _.isUndefined(view.options.message) ? '' : view.options.message,
            close: view.options.closeString,
            help: view.options.helpString,
            className: _.isUndefined(view.options.className) ? 'modalModal' : view.options.className,
            // eslint-disable-next-line no-nested-ternary
            no: _.isUndefined(view.options.noString) ? null : (_.isBoolean(view.options.noString) && view.options.noString ? $._('alert.action.cancel') : view.options.noString),
            // eslint-disable-next-line no-nested-ternary
            yes: _.isUndefined(view.options.yesString) ? null : (_.isBoolean(view.options.yesString) && view.options.yesString ? $._('alert.action.yes') : view.options.yesString)
        });
        $.modal(
            compiled,
            {
                overlayId: 'simplemodal-overlay',
                minHeight: '300px',
                maxHeight: '600px',
                minWidth: '680px',
                maxWidth: '680px',
                containerId: 'modalModal',
                overlayClose: false,
                onShow() {
                    // var modal = this;
                    view.setElement($('.simplemodal-container'));
                    view.$('.spinner').spinner();
                    view.delegateEvents(this.events);
                    if (_.isFunction(view.options.view)) {
                        view.options.view.$el.trigger('modalOnShow');
                    } else if (_.isFunction(view.options.success)) {
                        view.options.success();
                    }
                }
            }
        );
        return view;
    },
    setMessage(content) {
        const view = this;
        view.$('.spinner').remove();
        view.$('.modalInner').html(content);
        view.$('.modalButtons').children().removeAttr('disabled');
    },
    yes() {
        const view = this;
        // call the callback

        if (_.isFunction(view.options.success)) {
            view.options.success();
        }
        if (!_.isUndefined(view.options.view)) {
            view.options.view.$el.trigger('modalOnYes');
        } else {
            // close the dialog
            $.modal.close();
        }
    },
    events: {
        'click .modalYes': 'yes',
        'click .modalHelp': 'openhelp'
    },
    openhelp(e) {
        // var view=this;
        $('#hilfebutton').trigger('click');
        $(e.currentTarget).toggleClass('active');
    },
    remove() {
        $.modal.close();
    }
}, {
    show: options => new ModalView(options)
});
export default ModalView;
