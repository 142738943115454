import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import ProtocolView from './views/ProtocolView';

class Protocol extends React.Component {
    static propTypes = {
        spinner: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const actview = new ProtocolView();
        $('#protocol_component').html($(actview.el));
        actview.render();
        this.props.spinner(false);
    }

    render() {
        return (
            <div id="protocol_component" />
        );
    }
}

export default Protocol;
