import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import ReportCategories from '../models/ReportCategoryCollection';
import InputView from '../../app/views/InputView';
import html from '../templates/move_report_view.template';
import Globals from '../../../utils/Globals';

const View = Backbone.View.extend({
    initialize() {

    },
    render() {
        const view = this;
        const cats = new ReportCategories();
        view.inputView = new InputView({
            title: $._('report.alert.title.move.report'),
            yesString: $._('report.list.button.move.report'),
            // content : content,
            yesFunc() {
                view.saveCategory($('.modalMessage'));
                // view.uploadReport();
            }
        });
        cats.fetch({
            success() {
                const content = _.template(html, { categories: cats, categoryId: view.options.reportCategoryView.model.id });
                view.inputView.setContent(content);
                view.setElement('.move-report');
                console.log(view.$('#categories-select'));
                view.$('#categories-select').parent().uiselectfield('init');
            }
        });
    },
    events: {
        'click .modalYes': 'saveCategory',
        'submit form': 'saveCategory'
    },
    saveCategory() {
        const view = this;
        view.setElement('.move-report');
        $.ajax({
            url: Globals.baseUrl + 'reports/move',
            data: { reportId: this.model.id, categoryId: view.$('#categories-select').val() },
            type: 'PUT',
            dataType: 'json',
            success() {
                view.options.reportCategoryView.options.reportListView.render();
                view.remove();
            }
        });
        return false;
    },
    remove() {
        this.inputView.remove();
    }
});
export default View;
