import React from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { i18n } from '@mabinso-registry/module-commons';
import Globals from '../../utils/Globals';
import Init from '../../utils/Init';

export default class InfoUpload extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.style = {
            form: {
                display: 'inline-block'
            },
            input: {
                position: 'absolute',
                opacity: '0.0',
                filter: 'alpha(opacity=0)',
                float: 'right',
                height: '40px',
                marginTop: '-5px',
            }
        };
        this.inputOnChange = this.inputOnChange.bind(this);
        this.formOnSubmit = this.formOnSubmit.bind(this);
    }

    componentDidMount() {

    }

    componentWillReceiveProps() {
        Globals.nextPath = this.props.location;
    }

    formOnSubmit(e) {
        e.preventDefault();
        console.log('upload');
        Init.initJqueryAjaxToken();
        const module = this;
        const fileData = $('#licensefile').prop('files')[0];
        const formData = new FormData();
        formData.append('nfile', fileData);
        $.ajax({
            url: Globals.baseUrl + 'licence', // point to server-side PHP script
            dataType: 'json', // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: formData,
            type: 'post',
            success(response) {
                module.props.success(response);
            }
        });
    }

    inputOnChange(e) {
        this.formOnSubmit(e);
    }

    render() {
        return (
            <div style={{ float: 'left' }}>
                <form id="licenseform" style={this.style.form} onSubmit={this.formOnSubmit}>
                    <input type="file" onChange={this.inputOnChange} id="licensefile" name="licensefile" style={this.style.input} />
                    <button className="defaultButton buttonAutoFlat" type="button">
                        {i18n.t('licence.button.refresh')}
                    </button>
                </form>
            </div>
        );
    }
}
