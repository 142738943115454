import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import RoleCollection from '../models/RoleCollection';
import DetailView from './DetailView';
import html from '../templates/list_view.template';

const RoleView = Backbone.View.extend({
    initialize(options) {
        this.options = options || [];
    },
    render() {
        const collection = new RoleCollection();
        const view = this;
        $(this.el).spinner();
        collection.fetch({
            success(collection2) {
                view.$el.html(_.template(html)({ models: collection2, $ }));
                view.setElement('#role-list-view');
                view.options.detailView = new DetailView({ listView: view });
                $('.spalte_links_280 li').uiAktivAnzeiger('init');
            }
        });
        // sortByField Function aus der RoleCollection
        // Hier werden suchparameter = sort_key und suchrichtung = sort_order (asc oder desc) eingetragen
        collection.sortByField('name', 'asc');
    },
    events: {
        'click .rolelist li': 'showDetail'
    },
    showDetail(event) {
        const id = $(event.target).closest('li').data('id');
        this.options.detailView.render(id);
    }
});
export default RoleView;
