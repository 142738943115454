import _ from 'underscore';
import MabinsoModel from '../../app/models/MabinsoModel';
import Globals from '../../../utils/Globals';

class ReportCategoryModel extends MabinsoModel {
    backend = true;
    urlRoot = Globals.baseUrl + 'reportCategories/reportCategory';

    defaults() {
        return {
            name: '',
        };
    }

    getName() {
        const name = this.get('name');
        return (_ as any).toName(name);
    }
}
export default ReportCategoryModel;
