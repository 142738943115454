import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import html from '../../templates/edit_report_saved_view/combobox_view.template';

const ComboBoxView = Backbone.View.extend({
    initialize(options) {
        this.options = options;
        this.render();
    },
    render() {
        const view = this;
        view.$el.spinner('on', { view });
        view.options.property.fetchPropertyValues({
            success() {
                view.$el.html(_.template(html)({ $, property: view.options.property }));
                view.renderElements(view.options.property);
            }
        });
    },
    renderElements(property) {
        const view = this;
        if (_.size(property.get('propertyValues')) > 0) {
            const defaultValue = property.get('defaultValue');
            const value = property.get('value');
            _.each(property.get('propertyValues'), (item, index) => {
                const args = { value: item.id };
                if (value !== undefined && value === item.id) {
                    args.selected = 'selected';
                    view.$('.hiddenInputText').val(item.value);
                } else if (index === 0 || (defaultValue && defaultValue === item.value)) {
                    if (index !== 0) {
                        args.selected = 'selected';
                    }
                    view.$('.hiddenInputText').val(item.value);
                }
                view.$('select').append(_.make('option', args, item.value));
            });
            if (_.isFunction(view.options.success))view.options.success();
        } else {
            view.$('select').validationEngine('showPrompt', $._('report.new.combobox.empty'), 'error', 'topRight');
        }
        view.$('select').uiselectfield('init', { mark: true });
    },
    events: {
        'change .uiSelect input': 'change'
    },
    change(evt, val) {
        // console.log('change select '+val);
        // var selected = this.$('.uiSelect input').val();
        this.$('.hiddenInputText').val(val);
    }

});

export default ComboBoxView;
