import { ChangeEvent } from 'react';
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import { History } from 'history';
import ReportSavedCollection from '../models/ReportSavedCollection';
import ReportJobModel from '../models/ReportJobModel';
import ReportSavedModel from '../models/ReportSavedModel';
import ReportModel from '../models/ReportModel';
import ConfirmView from '../../app/views/ConfirmView';
import WaitView from '../../app/views/WaitView';
import AlertView from '../../app/views/AlertView';
import ReportSavedView from './ReportSavedView';
import PlanReportView from './PlanReportView';
import PlanReportListView from './PlanReportListView';
import EditReportSavedView from './EditReportSavedView';
import MoveReportView from './MoveReportView';
import html from '../templates/report_view.template';
import Globals from '../../../utils/Globals';
import ReportCategoryView from './ReportCategoryView';

interface BackboneView extends Backbone.View {
    options: any;
}

interface ViewOptions extends Backbone.ViewOptions<ReportModel> {
    afterRender: Function;
    enableHelp: Function;
    history: History;
    editReportCategoryView?: BackboneView;
    reportCategoryView: ReportCategoryView;
    planReportView?: BackboneView;
    reportListView?: BackboneView;
    editReportSavedView?: BackboneView;
}

class ReportView extends Backbone.View<ReportModel> {
    reportSavedViews: any;
    reportSavedItems: number = 0;
    reportSavedNewItems: number = 0;
    reportSavedClass: string = 'gerade';
    options: ViewOptions;
    constructor(options: ViewOptions) {
        super(options);
        this.reportSavedViews = [];
        this.options = options;
    }

    render() {
        const view = this;
        const tstring = _.template(html);
        view.$el.html(tstring({ model: view.model, $, Actions: Globals.actions }));
        view.$el.find('.waitreport').pnganimation('init', { time: 50 });
        view.renderTemplateSuccess();
        view.collection = new ReportSavedCollection();
        view.fetchReports();
        return view;
    }
    fetchReports(useCallback: boolean = true, loadAll: boolean = false, customCallback: any = () => {}) {
        const view = this;
        const reportSaved = new ReportSavedCollection();
        reportSaved.fetch({
            data: { report_id: view.model.id, loadAll },
            success(collection: any) {
                if (collection.length > 0) {
                    // view.collection = collection;
                    collection.eachAsync((model: any, callback: any) => {
                        setTimeout(() => {
                            if ($('#report' + model.id).length === 0) {
                                view.addReportSavedView(model);
                            }
                        }, 500);
                        if (useCallback) {
                            callback();
                        }
                    }, view.options.afterRender);
                    // view.reportHasReportsSaved(true);
                    customCallback();
                } else {
                    view.reportHasReportsSaved(false);
                    view.options.afterRender();
                }
            },
        });
    }
    renderTemplateSuccess() {
        this.$el.uiselectfield('init', { changeButtonTitle: false });
    }
    renderTableColors() {
        // Wechselnde Farben bei Tabellen
        this.$('.wechselnd').each(function each() {
            let even = true;
            $(this)
                .find('tr')
                .each(function each2() {
                    if (!$(this).hasClass('search-hide')) {
                        if (even) {
                            $(this).removeClass('ungerade').addClass('gerade');
                            even = false;
                        } else {
                            $(this).removeClass('gerade').addClass('ungerade');
                            even = true;
                        }
                    }
                });
        });
    }
    removeReportSavedView(reportSavedView: any) {
        const view = this;
        this.reportSavedViews = _.filter(this.reportSavedViews, (item) => {
            if (item.cid === reportSavedView.cid) {
                let reportSavedNewItems = 0;
                if (reportSavedView.isNew()) {
                    reportSavedNewItems = -1;
                }
                reportSavedView.remove();
                view.collection.remove(reportSavedView.model);
                view.reportSavedItems -= 1;
                view.reportSavedNewItems += reportSavedNewItems;
                view.options.reportCategoryView.addReportsSavedCount(-1, reportSavedNewItems);
                if (view.reportSavedItems < 1) {
                    view.triggerReportsSaved(false);
                    view.reportHasReportsSaved(false);
                }
                return false;
            }
            return true;
        });
    }
    addReportSavedView(model: any, mode?: any) {
        const view = this;
        if (_.isUndefined(model.get('description')) || model.get('description') == null) {
            model.set('description', $._('report.list.template.description'));
        }
        if (_.isUndefined(view.collection)) {
            view.collection = new ReportSavedCollection();
        }
        const oldRs = view.collection.get(model.id);
        if (oldRs == null) {
            view.collection.add(model);
        }
        this.reportSavedClass = this.reportSavedClass === 'ungerade' ? 'gerade' : 'ungerade';
        // eslint-disable-next-line no-nested-ternary
        const workClass = model.get('finished') == null && !model.get('fail') ? 'inwork' : model.get('fail') ? 'fail' : 'success';
        const seenClass = _.isUndefined(model.get('seen')) || model.get('seen') == null ? 'new' : 'old';
        const el = _.make('tr', {
            id: 'report' + model.id,
            'data-id': model.id,
            class: 'report hover ' + this.reportSavedClass + ' ' + workClass + ' ' + seenClass,
        });
        if (_.isUndefined(mode) || mode === 'append') {
            view.$('.reports table').append(el);
        } else {
            view.collection.add(model);
            view.$('.reports table tr.reportbeschriftung').after(el);
        }
        const rsiv = new ReportSavedView({
            el,
            model,
            reportView: view,
            enableHelp: view.options.enableHelp,
        });
        model.view = rsiv;
        view.reportSavedViews.push(rsiv);
        rsiv.render();
        this.reportSavedItems += 1;
        let reportSavedNewItems = 0;
        if (rsiv.isNew()) {
            reportSavedNewItems = 1;
        }
        this.reportSavedNewItems += reportSavedNewItems;
        view.options.reportCategoryView.addReportsSavedCount(1, reportSavedNewItems);
        this.reportHasReportsSaved(true);
        this.renderTableColors();
        // view.options.reportCategoryView.options.reportListView.checkInWork();
        return rsiv;
    }
    SavedItems() {
        let reportSavedItems = 0;
        let reportSavedNewItems = 0;
        _.each(this.reportSavedViews, (item) => {
            if (!item.isHidden()) {
                reportSavedItems += 1;
                if (item.isNew()) {
                    reportSavedNewItems += 1;
                }
            }
        });
        // console.log('rep:'+reportSavedItems);
        if (reportSavedItems > 0) {
            this.reportHasReportsSaved(true);
            this.renderTableColors();
        } else {
            this.triggerReportsSaved(false);
            this.reportHasReportsSaved(false);
        }
        const addReportSavedItem = reportSavedItems - this.reportSavedItems;
        const addReportSavedNewItems = reportSavedNewItems - this.reportSavedNewItems;
        this.reportSavedItems = reportSavedItems;
        this.reportSavedNewItems = reportSavedNewItems;
        this.options.reportCategoryView.addReportsSavedCount(addReportSavedItem, addReportSavedNewItems);
    }
    hideReportDescription(value: boolean) {
        if (value) {
            this.$('.reportbeschriftung').addClass('search-hide');
        } else {
            this.$('.reportbeschriftung').removeClass('search-hide');
        }
    }
    reportHasReportsSaved(value: boolean) {
        this.$('div.reporttitel div').removeClass('waitreport');
        this.$('div.reporttitel div').pnganimation('stop', { left: -915, top: -235 });
        if (value) {
            this.$('.reporttitel').addClass('hasReportsSaved');
            this.$el.closest('.reportvorlage').addClass('hasReportsSaved');
            this.$('div.reporttitel div').removeClass('noreport');
        } else {
            this.$('.reporttitel').removeClass('hasReportsSaved');
            this.$('div.reporttitel div').removeClass('waitreport');
            this.$('div.reporttitel div').addClass('noreport');
            this.$('div.reporttitel div').addClass('sprites_main');
        }
    }
    triggerReportsSaved(open: boolean) {
        const reporttitel = this.$('.reporttitel:not(.deleted)');
        if (_.isUndefined(open)) {
            reporttitel.trigger('click');
        } else {
            if (open && !reporttitel.hasClass('active')) {
                reporttitel.find('.openvorlage').trigger('click');
            }
            if (!open && reporttitel.hasClass('active')) {
                reporttitel.find('.openvorlage').trigger('click');
            }
        }
    }
    events() {
        return {
            'click .reporttitel.hasReportsSaved.active:not(.deleted) .openvorlage': 'closeReportsSaved',
            'click .reporttitel.hasReportsSaved:not(.active, .deleted) .openvorlage': 'openReportsSaved',
            'click .deleteReport': 'deleteReport',
            'click .deleteAll': 'deleteAll',
            'click .moveReport': 'moveReport',
            'click .newReport': 'addReportSaved',
            'click .planReport': 'planReport',
            'click .planReportList': 'planReportList',
            'click .loadAll': 'loadAll',
        };
    }
    loadAll(evt) {
        const view = this;
        $(evt.target).parent().find('.loadingAll').show();
        $(evt.target).hide();
        view.fetchReports(false, true, () => {
            $(evt.target).parent().find('.loadingAll').hide();
        });
        // view.$el.find('.waitreport').pnganimation('init', { time: 50 });
        return false;
    }
    planReport(evt: any) {
        const view = this;
        if (_.isUndefined(evt.reportSavedModel) && view.model.get('hasParams')) {
            this.addReportSaved({ planReport: true });
        } else {
            if (_.isUndefined(view.options.planReportView)) {
                view.options.planReportView = new PlanReportView();
            }
            if (view.options.planReportView !== undefined) {
                view.options.planReportView.model = new ReportJobModel({ reportId: view.model.id });
                if (!_.isUndefined(evt.reportSavedModel)) {
                    view.options.planReportView.model.set('reportSaved', evt.reportSavedModel.toJSON());
                }
                view.options.planReportView.render();
            }
        }
    }
    planReportList() {
        const view = this;
        if (_.isUndefined(view.options.reportListView)) {
            view.options.reportListView = new PlanReportListView();
        }
        if (view.options.reportListView !== undefined) {
            view.options.reportListView.model = view.model;
            view.options.reportListView.render();
        }
    }
    addReportSaved(evt: any) {
        const view = this;
        const interActive = view.model.get('interActive');
        const rsModel = new ReportSavedModel();
        if (interActive) {
            window.open(rsModel.urlDetail() + '?report_id=' + view.model.id, Math.floor(Math.random() * 99999999999).toString());
        } else {
            const { newReportView } = Globals.clientConfig;
            if (newReportView) {
                view.options.history.push('/index.reports/newReport/' + this.model.id);
            } else {
                if (_.isUndefined(view.options.editReportSavedView)) {
                    view.options.editReportSavedView = new EditReportSavedView({
                        reportView: view,
                        enableHelp: view.options.enableHelp,
                    });
                }
                if (view.options.editReportSavedView !== undefined) {
                    view.options.editReportSavedView.options.planReport = !_.isUndefined(evt.planReport) ? evt.planReport : false;
                    view.options.editReportSavedView.model = rsModel;
                    view.options.editReportSavedView.render();
                }
            }
        }
    }
    deleteReport() {
        const view = this;
        const cview = new ConfirmView({
            message: $._('report.alert.confirm.delete'),
            title: $._('report.alert.confirm.delete.title'),
            yesString: $._('report.alert.report.delete.confirm'),
            yesFunc() {
                view.model.destroy({
                    success() {
                        cview.remove();
                        view.options.reportCategoryView.options.reportListView.render();
                    },
                });
            },
        });
    }
    deleteAll() {
        const that = this;
        const ids: any = [];
        if (that.reportSavedViews != null) {
            _.each(that.reportSavedViews, (rView) => {
                if (!rView.isHidden()) {
                    ids.push(rView.model.id);
                }
            });
        }
        if (ids == null || _.size(ids) === 0) {
            AlertView.show({
                title: $._('report.deleteFilterItems.noitems.title'),
                message: $._('report.deleteFilterItems.noitems.message'),
            });
        } else {
            ConfirmView.show({
                title: $._('report.deleteFilterItems.delete.title'),
                message: $._('report.deleteFilterItems.delete.message', _.size(ids).toString()),
                yesFunc() {
                    const waitView = new WaitView({
                        message: $._('report.deleteFilterItems.delete.wait'),
                    });
                    ReportModel.deleteItems({
                        items: ids,
                        success() {
                            waitView.remove();
                            AlertView.show({
                                message: $._('report.deleteFilterItems.delete.success'),
                                okFunc() {
                                    _.each(that.reportSavedViews, (item) => {
                                        that.removeReportSavedView(item);
                                    });
                                },
                            });
                        },
                    });
                },
            });
        }
    }
    moveReport() {
        const view = this;
        const mview = new MoveReportView({ model: view.model, reportCategoryView: view.options.reportCategoryView });
        mview.render();
    }
    openReportsSaved(evt: ChangeEvent<HTMLInputElement>) {
        // var view = this;
        const root = $(evt.target).closest('.reportvorlage');
        $('.reports', root).slideDown('fast');
        $('.reporttitel', root).addClass('active');
    }
    closeReportsSaved(evt: ChangeEvent<HTMLInputElement>) {
        // if($(evt.target).parent().hasClass('reporttitle-name') || $(evt.target).parent().hasClass('statusBackground')){
        const root = $(evt.target).closest('.reportvorlage');
        $('.reports', root).slideUp('fast');
        $('.reporttitel', root).removeClass('active');
        // }
    }
    countReportsSaved() {
        let reportsavedcount = 0;
        this.$('tr.report').each(function each() {
            if (!$(this).hasClass('search-hide')) {
                reportsavedcount += 1;
            }
        });
        return reportsavedcount;
    }
    countReportsSavedNew() {
        let reportsavednewcount = 0;
        this.$('tr.report.new').each(function each() {
            if (!$(this).hasClass('search-hide')) {
                reportsavednewcount += 1;
            }
        });
        return reportsavednewcount;
    }
}
export default ReportView;
