// eslint-disable-next-line import/no-amd
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import createClass from 'create-react-class';
import ConfirmView from '../../../views/ConfirmView';
import './css/actionbar.scss';

// Daten für Actionbar.
const Actionbar = function MyActionBar(actionbarName) {
    // Actionbar
    this.actionbar_name = actionbarName;
    // var that = this;
    this.Actionbar = createClass({
        getInitialState() {
            return this.props;
        },
        componentDidMount() {

        },
        handleClick(i) {
            const that = this;
            if (that.props.actions[i].confirm) {
                ConfirmView.show({
                    message: that.props.actions[i].confirm,
                    title: that.props.actions[i].title || $._('report.list.delete.all.title'),
                    yesString: that.props.actions[i].label,
                    yesFunc() {
                        $(that.props.actions[i].target).each((index, element) => {
                            if ($(element).closest('.report').is(':visible')) {
                                $(element).trigger(that.props.actions[i].action);
                            }
                        });
                    }
                });
            } else {
                $.each($(that.props.actions[i].target), function each() {
                    if ($(this).closest('.report').is(':visible')) {
                        $(this).trigger(that.props.actions[i].action);
                    }
                });
            }
        },
        render() {
            const self = this;
            /* eslint-disable react/no-array-index-key */
            const actionButtons = this.props.actions.map((option, i) => (
                <button key={i} className={option.className} onClick={self.handleClick.bind(self, i)}>{option.label}</button>
            ));
            /* eslint-enable */
            return (
                <div id="actionbar">
                    <label>{ self.props.marked_text || $._('report.list.delete.all.marked') }</label>
                    {actionButtons}
                </div>
            );
        }
    });
    // Aktions-Buttons
    this.ActionButtons = createClass({
        getInitialState() {
            return this.props;
        },
        componentDidMount() {

        },
        closeClick() {
            this.props.actions();
        },
        render() {
            return (
                <div id="aa">
                    <button className="close">Close</button>
                </div>
            );
        }
    });
};

Actionbar.prototype.init = function init(holder, actions, props) {
    if (props === undefined) {
        props = [];
    }
    ReactDOM.render(
        // eslint-disable-next-line no-undef
        <this.Actionbar actions={actions} marked_text={props.marked_text} />,
        document.getElementById(holder)
    );
};

export default Actionbar;

