/* eslint-disable class-methods-use-this */
import { i18n, _ } from '@mabinso-registry/module-commons';
import $ from 'jquery';
import MabinsoModel from '../../app/models/MabinsoModel';
import WebSocket from '../../../utils/WebSocket';
import Globals from '../../../utils/Globals';
import auth from '../../../utils/Auth';

class ReportSavedModel extends MabinsoModel {
    url = () => {
        let url = Globals.baseUrl + 'reportsSaved/reportSaved';
        if (this.id) {
            url += '/' + this.id;
        }
        if (this.get('token')) {
            url += '?reporttoken=' + this.get('token');
        }
        return url;
    };
    urlRoot = () => Globals.baseUrl + 'reportsSaved/reportSaved';
    urlSocket = 'reportsSaved/socket/status';
    urlFallback = Globals.baseUrl + 'reportsSaved/status';
    urlContent(type: any) {
        let url = Globals.baseUrl + 'reportsSaved/' + type + 'ReportSaved';
        if (this.id) {
            url += '/' + this.id;
        }
        if (this.get('token')) {
            url += '?reporttoken=' + this.get('token');
        }
        return url;
    }
    urlDetail() {
        let url = Globals.baseUrl + 'reportsSaved/showDetail';
        if (!_.isUndefined(this.id)) {
            url += '/' + this.id;
        }
        return url;
    }
    defaults = () => ({
        name: '',
        description: i18n.t('report.list.template.description'),
    });
    fetchHtml(options: any) {
        const model = this;
        let multiPage = false;
        if (options.page === -1) {
            multiPage = true;
        } else if (options.page + 1 > options.pages) {
            return;
        }
        let i = 0;
        const myAjax = () => {
            const data = {
                page: options.page,
            };
            if (multiPage) {
                data.page = i;
            }
            $.ajax({
                url: model.urlContent('html'),
                data,
                dataType: 'html',
                success(dataRet) {
                    // var djson = JSON.fromHeader(jqXHR);
                    if (_.isFunction(options.success)) {
                        options.success(dataRet, i);
                    }
                    i += 1;
                    if (multiPage && i < options.pages) {
                        myAjax();
                    }
                },
                error(dataRet) {
                    if (_.isFunction(options.error)) {
                        options.error(dataRet);
                    }
                },
            });
        };
        myAjax();
    }
    doReport(options: any) {
        // var model = this;
        let multiPage = true;
        if (_.isUndefined(options.pages)) {
            options.pages = 1;
            multiPage = false;
        }
        let i = 0;
        const myAjax = () => {
            if (multiPage) {
                if (_.isUndefined(options.data)) {
                    options.data = {};
                }
                options.data.page = i;
            }
            $.ajax({
                url: Globals.baseUrl + 'reportsSaved/doReport',
                data: options.data,
                dataType: 'html',
                type: 'POST',
                success(data) {
                    // var djson = JSON.fromHeader(jqXHR);
                    if (_.isFunction(options.success)) {
                        options.success(data, i);
                    }
                    i += 1;
                    if (i < options.pages) {
                        myAjax();
                    }
                },
            });
        };
        myAjax();
    }
    getStatus() {
        const model = this;
        if (model.get('fail')) {
            return { code: 'fehler', msg: i18n.t('alert.error.general') };
        } else if (!model.get('fail') && model.get('finished') === null) {
            return { code: 'inaktion', msg: i18n.t('report.alert.new.report') };
        } else if (model.get('pages') === 0 && !model.get('fail') && model.get('finished') !== null) {
            return { code: 'ok_empty', msg: i18n.t('report.alert.empty.report') };
        }
        return { code: 'ok', msg: i18n.t('report.alert.new.success') };
    }
    hasStatus(status: any) {
        if (status === this.getStatus().code) {
            return true;
        }
        return false;
    }
    socket: any = null;
    pollStatus(onPollSuccess: any) {
        // var model = this;
        this.socket = WebSocket(MabinsoModel.getBaseWS() + this.urlSocket + '?x-authorisation=' + auth.getToken(), {
            fallbackPollURL: this.urlFallback,
        });
        this.socket.onmessage = (message: any) => {
            const data = JSON.parse(message.data);
            if (_.isFunction(onPollSuccess)) {
                onPollSuccess(new ReportSavedModel(data));
            }
        };
    }
    closePollStatus() {
        if (this.socket != null) {
            this.socket.close();
        }
    }
}
export default ReportSavedModel;
