import Backbone from 'backbone';
import RoleModel from './RoleModel';
import Globals from '../../../utils/Globals';

// Liste Blackbone Collection
// Holt sich Daten für die Listenelemente mit RoleModel
const RoleCollection = Backbone.Collection.extend({
    url: Globals.baseUrl + 'roles/role',
    model: RoleModel,

    // // Abschnitt sortiert collection (auch strings)
    // sort_key wird im zur RoleCollection zugehörigem RoleView gesetzt
    sort_key: '',
    // sort_order wird auch in view gesetzt mit asc oder desc
    // das wird dann hier in der sortByField Function Zeile
    sort_order: 1,
    // collection.comparator sowas wie der Vergleicher der Sammlung
    comparator(item1, item2) {
        // val1 ist item1 das sich sort_key holt
        let val1 = item1.get(this.sort_key);
        // val2 ist item2 das sich sort_key holt
        let val2 = item2.get(this.sort_key);
        // Wenn val1 vom type string ist
        // wird val1 auf Kleinbuchstaben gesetzt
        // und wird val2 in einen string umgewandelt und auf Kleinbuchstaben gesetzt
        if (typeof (val1) === 'string') {
            val1 = val1.toLowerCase();
            val2 = val2.toString().toLowerCase();
        }
        // sortValue ist gleich val1 ist größer als val2 ? Dann sortiere nach 1 ansonsten nach -1
        const sortValue = val1 > val2 ? 1 : -1;
        // gebe zurück sortValue Exponentiation operator diese sort_order
        return sortValue ** this.sort_order;
    },
    // Function die wir im view aufrufen um collection zu sortieren wenn view sich updated
    sortByField(fieldName, orderType) {
        // Wonach sortiert wird, wir sortieren hier nach 'name' wie wir es im view festgelegt haben
        this.sort_key = fieldName;
        // Sortierrichtung sort_order wurde in view festgelegt
        // wenn sort_Type gleich desc sortiere nach -1 ansonsten nach 1
        // 1 ist ascending, 0 bleibt gleich, -1 ist descending
        this.sort_order = orderType === 'desc' ? -1 : 1;
        // Ruft collection.sort auf um das sort Ereignis von collection zu triggern
        this.sort();
    },
});
export default RoleCollection;
