import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import ConfirmView from '../../app/views/ConfirmView';
import AlertView from '../../app/views/AlertView';
import SchemaModel from '../models/SchemaModel';
import WaitView from '../../app/views/WaitView';
import html from '../templates/detail_view.template';

const DetailView = Backbone.View.extend({
    model: null,
    _oldPass: '(old Password)',
    initialize(options) {
        this.options = options || {};
    },
    render(id) {
        this.$('form').validationEngine('hideAll');
        $.scrollTo('#hauptrahmen', 300);
        this.model = new SchemaModel({ id });
        const view = this;
        $(this.el).spinner();
        if (_.isUndefined(id)) {
            view.oldPass = '';
            this.renderTemplate(view.model);
        } else {
            view.oldPass = view._oldPass;
            this.model.fetch({
                success() {
                    view.renderTemplate(view.model);
                }
            });
        }
    },
    renderTemplate(model) {
        const view = this;
        view.options.listView.$('div.schema-content-detail').html(_.template(html)({ model: view.model, $ }));
        view.setElement('#schema-detail-view');
        const { oldPass } = this;
        if (model.get('passwordEmpty')) {
            view.$('#passwordText').attr('disabled', 'disabled').hide();
        } else {
            view.$('#password').attr('disabled', 'disabled').hide();
            view.$('#password').val(oldPass);
            view.$('#passwordText').val(oldPass);
        }
        view.$('#schema_detail_sclass_select').parent().uiselectfield('init');
        if (model.get('sclass') === '') {
            view.$('#sclass').val(view.$('#schema_detail_sclass_select').val());
        } else {
            const newVal = view.$('#sclass').val();
            const input = view.$('#schema_detail_sclass_select');
            input.val(newVal);
            const ul = input.prev();
            ul.find('li.choosen').removeClass('choosen');
            const lifind = ul.find('li[id="sclass_select_' + newVal + '"]');
            lifind.addClass('choosen');
            input.next().text(lifind.text());
        }
        view.$el.fadeIn();
    },
    events: {
        'submit #schema-form': 'saveModel',
        'click #delete-schema': 'deleteModel',
        'change #schema_detail_sclass_select': 'changeClass',
        'focus #passwordText': 'focusPasswordText',
        'blur #password': 'blurPassword'
    },
    changeClass() {
        const view = this;
        view.$('#sclass').val(view.$('#schema_detail_sclass_select').val());
    },
    focusPasswordText() {
        const view = this;
        view.$('#password').removeAttr('disabled').show();
        view.$('#password').focus();
        view.$('#passwordText').attr('disabled', 'disabled').hide();
    },
    blurPassword() {
        const view = this;
        if (view.$('#password').val() === view.oldPass) {
            view.$('#password').attr('disabled', 'disabled').hide();
            view.$('#passwordText').removeAttr('disabled').show();
        }
    },
    saveModel() {
        const view = this;
        this.model.clear({ silent: true });
        this.model.setForm(this.$('form'));
        let wait = null;
        const test = this.model.save(formMessage => {
            if (wait != null) { wait.remove(); }
            if (formMessage.hasErrors()) {
                const errorMessage = formMessage.data.errors[0].message;
                console.log('formMessage', errorMessage);

                AlertView.show({
                    message: $._('schema.host.noconnection', errorMessage),
                    title: $._('alert.action.nosuccess'),
                    okFunc() {
                        if (_.isUndefined(view.model.id)) {
                            view.render(formMessage.data.gid);
                        } else {
                            view.render(view.model.id);
                        }
                    }
                });
            } else {
                // view.$("form").validationEngine('hide');
                AlertView.show({
                    message: $._('schema.alert.save.success'),
                    title: $._('alert.action.success'),
                    okFunc() {
                        if (_.isUndefined(view.model.id)) {
                            view.options.listView.render();
                        } else {
                            view.render(view.model.id);
                        }
                    }
                });
            }
        });
        if (test) {
            wait = new WaitView({
                title: $._('scheme.saving'),
                message: $._('scheme.check.db.connection'),
                closeHide: true
            });
        }
        return false;
    },
    deleteModel() {
        const view = this;
        this.$('form').validationEngine('hideAll');
        ConfirmView.show({
            message: $._('schema.form.message.delete'),
            title: $._('alert.action.delete'),
            yesFunc() {
                view.model.destroy({
                    success(mode, data) {
                        AlertView.show({
                            message: data.hasErrors ? data.errors[0].message : $._('schema.alert.delete.success'),
                            title: data.hasErrors ? 'alert.action.nosuccess' : $._('alert.action.success'),
                            okFunc() {
                                if (!data.hasErrors) {
                                    view.options.listView.render();
                                }
                            }
                        });
                    }
                });
            }
        });
    }

});
export default DetailView;
