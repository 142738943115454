/* eslint-disable @typescript-eslint/no-use-before-define */
import $ from 'jquery';

const WebSocketFallback = (url, options) => {
    // Default properties
    const defaults = {
        keepAlive: false, // not implemented - should ping server to keep socket open
        autoReconnect: false, // not implemented - should try to reconnect silently if socket is closed
        fallback: true, // not implemented - always use HTTP fallback if native browser support is missing
        fallbackSendURL: url.replace('ws:', 'http:').replace('wss:', 'https:'),
        fallbackSendMethod: 'POST',
        fallbackPollURL: url.replace('ws:', 'http:').replace('wss:', 'https:'),
        fallbackPollMethod: 'GET',
        fallbackOpenDelay: 100, // number of ms to delay simulated open event
        fallbackPollInterval: 150000, // number of ms between poll requests
        fallbackPollParams: {} // optional params to pass with poll requests
    };

    // Override defaults with user properties
    const opts = $.extend({}, defaults, options);

    /**
     * Creates a fallback object implementing the WebSocket interface
     */
    function FallbackSocketPoll() {
        // WebSocket interface constants
        const CONNECTING = 0;
        const OPEN = 1;
        // var CLOSING = 2;
        // const CLOSED = 3;

        let pollInterval;
        let openTimeout;
        let xhr = null;

        // create WebSocket object
        const fws = {
            // ready state
            readyState: CONNECTING,
            bufferedAmount: 0,
            send(data) {
                let success = true;
                $.ajax({
                    async: false, // send synchronously
                    type: opts.fallbackSendMethod,
                    // eslint-disable-next-line no-use-before-define
                    url: opts.fallbackSendURL + '?' + $.param(getFallbackParams()),
                    data,
                    headers: { 'X-Authorisation': window.indexRouter.token },
                    dataType: 'text',
                    contentType: 'application/x-www-form-urlencoded; charset=utf-8',
                    // eslint-disable-next-line no-use-before-define
                    success: pollSuccess,
                    error() {
                        success = false;
                        $(fws).triggerHandler('error');
                    }
                });
                return success;
            },
            close() {
                xhr.abort();
                clearTimeout(openTimeout);
                clearInterval(pollInterval);
                // this.readyState = CLOSED;
                $(fws).triggerHandler('close');
            },
            onopen() {},
            onmessage() {},
            onerror() {},
            onclose() {},
            previousRequest: null,
            currentRequest: null
        };

        function getFallbackParams() {
            // update timestamp of previous and current poll request
            fws.previousRequest = fws.currentRequest;
            fws.currentRequest = new Date().getTime();

            // extend default params with plugin options
            return $.extend({ previousRequest: fws.previousRequest, currentRequest: fws.currentRequest }, opts.fallbackPollParams);
        }

        function poll() {
            if (xhr != null) {
                xhr.abort();
            }
            xhr = $.ajax({
                type: opts.fallbackPollMethod,
                url: opts.fallbackPollURL,
                dataType: 'text',
                headers: { 'X-Authorisation': window.indexRouter.token },
                data: getFallbackParams(),
                // eslint-disable-next-line no-use-before-define
                success: pollSuccess,
                error() {
                    $(fws).triggerHandler('error');
                }
            });
        }

        /**
         * @param {Object} data
         */
        function pollSuccess(data) {
            // trigger onmessage
            const messageEvent = { data };
            fws.onmessage(messageEvent);
            clearInterval(pollInterval);
            poll();
            pollInterval = setInterval(poll, opts.fallbackPollInterval);
        }
        // simulate open event and start polling
        openTimeout = setTimeout(() => {
            fws.readyState = OPEN;
            // fws.currentRequest = new Date().getTime();
            $(fws).triggerHandler('open');
            poll();
            pollInterval = setInterval(poll, opts.fallbackPollInterval);
        }, opts.fallbackOpenDelay);

        // return socket impl
        return fws;
    }

    // create a new websocket or fallback
    let ws = window.WebSocket ? new WebSocket(url) : new FallbackSocketPoll();
    $(window).on('unload', () => { ws.close(); ws = null; });
    return ws;
};
export default WebSocketFallback;
