import $ from 'jquery';
import _ from 'underscore';
import MabinsoModel from '../../app/models/MabinsoModel';
import Globals from '../../../utils/Globals';
import auth from '../../../utils/Auth';
import WebSocketFallback from '../../../utils/WebSocket';

class Model extends MabinsoModel {
    urlRoot = Globals.baseUrl + 'interfaces/finished';
    urlSocket = 'interfaces/socket/status';
    urlFallback = Globals.baseUrl + 'interfaces/status';
    urlContent(type: string) {
        return Globals.baseUrl + 'interfaces/logs/' + type + '/' + this.id;
    }
    getStatus() {
        const model = this;
        if (model.get('isSuccsessful')) {
            return { code: 'ok', msg: $._('interface.alert.new.success') };
        } else if (model.get('isRun')) {
            return { code: 'inaktion', msg: $._('interface.alert.new.report') };
        }

        /* else if (false) {
            return { code: 'ok_empty', msg: $._('interface.alert.empty.report') };
        } */

        return { code: 'fehler', msg: $._('alert.error.general') };
    }
    hasStatus(status: string) {
        if (status === this.getStatus().code) {
            return true;
        }
        return false;
    }
    socket: any = null;
    pollStatus(onPollSuccess: any) {
        // var model = this;
        this.socket = WebSocketFallback(MabinsoModel.getBaseWS() + this.urlSocket + '?x-authorisation=' + auth.getToken(), {
            fallbackPollURL: this.urlFallback,
        });
        this.socket.onmessage = (message: any) => {
            console.log('Poll Interface message received');
            const data = JSON.parse(message.data);
            if (_.isFunction(onPollSuccess)) {
                onPollSuccess(new Model(data));
            }
        };
    }
    closePollStatus() {
        if (this.socket != null) {
            this.socket.close();
            this.socket = null;
        }
    }
}
export default Model;
