/* eslint-disable */
/* Animiert Hintergründe */
define(['jquery'], function ($) {
	(function( $ ){
		var methods = {
			init : function(options) {
				var settings = {
					'direction'	:	-1,
					'time'	: 80,
					'step' : false
				};
				if ( options ) { 
					$.extend( settings, options );
				}
				$(this).pnganimation('move', {direction : settings.direction, time : settings.time, step : settings.step});
				
			},
			move : function(options) {
				var settings = {
					'direction'	:	-1,
					'time'	: 5000,
					'step' : false
				};
				if ( options ) { 
					$.extend( settings, options );
				}
				
				var welcher=$(this);
				if($(this).css('background-position')!=null){
					
					aktuelleBgPosition=welcher.css('background-position').split(" ");						
					aktuellePositionRounded=parseInt(aktuelleBgPosition[0])*-1;
					
					// Animation ging nach einiger Zeit verloren. Vermutlich weil Wert überlief. Daher jetzt begrenzt
					if(aktuellePositionRounded>1000000){
						aktuellePositionRounded=0;						
					}
				
					if(settings.step==false){
						$(this).css('background-position', (aktuellePositionRounded*settings.direction)-$(this).width()+'px');	
					}
					else{
						$(this).css('background-position', (aktuellePositionRounded*settings.direction)-settings.step+'px');	
					}
					
				}			
				$(this).data('timeOutVar', window.setTimeout(function(){welcher.pnganimation('move', {direction : settings.direction, time : settings.time, step : settings.step});}, settings.time));
			},
			stop : function(options) {
			
				var settings = {
					'left'	:	'left',
					'top'	: 'top'
				};
				if ( options ) { 
					$.extend( settings, options );
				}
				clearTimeout($(this).data('timeOutVar'));
				$(this).css('background-position', settings.left+'px '+settings.top+'px');
			}
		};	
		$.fn.pnganimation = function(method){					
			// Method calling logic
			if ( methods[method] ) {
				return methods[ method ].apply( this, Array.prototype.slice.call( arguments, 1 ));
			}
			else if ( typeof method === 'object' || ! method ) {
				return methods.init.apply( this, arguments );
			}
			else {
				$.error( 'Method ' +  method + ' does not exist on jMaBinso.pnganimation' );
			}  		
		}
	})( $);
});