import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import html from '../templates/upload_view.template';

const UploadView = Backbone.View.extend({
    el: 'div#hauptrahmen',
    initialize(options) {
        this.options = options || [];
        this.render();
    },
    render() {
        const view = this;
        view.$('form').validationEngine('hideAll');
        const compiled = _.template(_.isUndefined(view.options.html) ? html : view.options.html)({
            $,
            title: view.options.title,
            no: _.isUndefined(view.options.cancelString) ? $._('alert.action.cancel') : view.options.cancelString,
            yes: _.isUndefined(view.options.yesString) ? $._('alert.action.yes') : view.options.yesString,
            multiupload: view.options.multiupload ? view.options.multiupload : false
        });
        $.modal(
            compiled,
            {
                overlayId: 'simplemodal-overlay',
                minHeight: '200px',
                minWidth: '400px',
                maxWidth: '400px',
                containerId: 'modalUpload',
                overlayClose: false,
                onShow() {
                    // var modal = this;
                    view.setElement($('.simplemodal-container'));
                    view.delegateEvents(this.events);
                    if (view.options.multiupload) {
                        $('#templateFile').fileupload({
                            url: view.options.url,
                            formData: view.options.formData ? view.options.formData : {},
                            sequentialUploads: true,
                            dataType: 'json',
                            add(e, data) {
                                $.each(data.files, (index, file) => {
                                    console.log('Added file: ' + file.name);
                                    $('<p/>').text('Datei ' + file.name + ' wird hochgeladen.').appendTo('#files');
                                });
                                view.$('#upload-form').hide();
                                data.submit();
                            },
                            done() {
                                /* $.each(data.result.files, function (index, file) {
                                    $('<p/>').text(file.name).appendTo('#files');
                                }); */
                                console.log('done is ready');
                                if (_.isFunction(view.options.done)) {
                                    view.options.done();
                                }
                            },
                            progressall(e, data) {
                                const progress = parseInt(data.loaded / (data.total * 100), 10);
                                console.log('progress' + progress);
                                $('#progress .progress-bar').css(
                                    'width',
                                    progress + '%'
                                ).html('&nbsp;' + progress + '%');
                            }
                        }).prop('disabled', !$.support.fileInput).parent()
                            .addClass($.support.fileInput ? undefined : 'disabled');
                    }
                    if (_.isFunction(view.options.onShow)) {
                        view.options.onShow();
                    }
                },
                onClose() {
                    if (_.isFunction(view.options.cancelFunc)) {
                        view.options.cancelFunc();
                    }
                    view.$('form').validationEngine('hideAll');
                    $.modal.close();
                }
            }
        );
        return view;
    },
    remove() {
        $.modal.close();
    },

    yes() {
        const view = this;
        view.$('.icon').addClass('wait');
        view.$('.icon').pnganimation('init', { time: 50 });
        // call the callback
        if (_.isFunction(view.options.yesFunc)) {
            view.options.yesFunc();
        } else {
            // close the dialog
            $.modal.close();
        }
    },
    events: {
        'click .modalYes': 'yes'
    }
});
export default UploadView;
