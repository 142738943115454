import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import RoleView from './views/RoleView';

class Role extends React.Component {
    static propTypes = {
        spinner: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const actview = new RoleView();
        $('#role_component').html($(actview.el));
        actview.render();
        this.props.spinner(false);
    }

    render() {
        return (
            <div id="role_component" />
        );
    }
}

export default Role;
