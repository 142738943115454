import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormConfig, FormStepper, FormStep, ui, Field, Label } from '@mabinso-registry/module-commons';
import { format } from 'date-fns';
import { RouteComponentProps } from 'react-router';
import ReportModel from '../../models/ReportModel';
import ReportPropertyModel from '../../models/ReportPropertyModel';
import ReportPropertyCollection from '../../models/ReportPropertyCollection';
import ReportSavedModel from '../../models/ReportSavedModel';

interface MatchParams {
    reportId: string;
}

interface NewReportProps extends RouteComponentProps<MatchParams>{
}

const makeFormConfig = (reportProps: ReportPropertyCollection): FormStep[] => {
    const slides = reportProps.getGrouped();
    const formStep: FormStep[] = [];
    Object.values(slides).forEach((slide: any) => {
        const formConfig: FormConfig[] = [];
        Object.values(slide).forEach(async (slideProps) => {
            if (Array.isArray(slideProps)) {
                const [slideProp, slideProp2] = slideProps as ReportPropertyModel[];
                console.log(slideProp);
                const aConfig: FormConfig = {
                    type: slideProp.dialogType,
                    field: slideProp.getId().toString(),
                    label: slideProp.description,
                    validations: [{
                        type: 'required'
                    }]
                };
                switch (aConfig.type) {
                    case 'select':
                    case 'combobox':
                        aConfig.options = async () => {
                            const pValues = await slideProp.fetchPropertyValues();
                            const ret = pValues.map((item: any) => ({ label: item.value, value: item.id }));
                            return ret;
                        };
                        formConfig.push(aConfig);
                        break;
                    case 'dateFromTo':
                    case 'date_from_to':
                        if (slideProp.dialogElement === 'to') {
                            aConfig.field = {
                                from: slideProp2 ? slideProp2.getId().toString() : '',
                                to: slideProp.getId().toString()
                            };
                            aConfig.label = {
                                from: slideProp2 ? slideProp2.description || '' : '',
                                to: slideProp.description || ''
                            };
                        }
                        if (slideProp.dialogElement === 'from') {
                            aConfig.field = {
                                to: slideProp2 ? slideProp2.getId().toString() : '',
                                from: slideProp.getId().toString()
                            };
                            aConfig.label = {
                                to: slideProp2 ? slideProp2.description || '' : '',
                                from: slideProp.description || ''
                            };
                        }
                        formConfig.push(aConfig);
                        break;
                    default:
                        formConfig.push(aConfig);
                }
            }
        });
        formStep.push({
            title: 'Page1',
            content: '',
            formConfig
        });
    });
    return formStep;
};

const NewReport:FunctionComponent<NewReportProps> = ({ match, history }) => {
    const [defaultName, setDefaultName] = useState('Report');
    const [waiter, setWaiter] = useState(true);
    const [stepperConfig, setStepperConfig] = useState<FormStep[]>([]);
    const reportModel = new ReportModel();
    reportModel.setId(parseInt(match.params.reportId, 10));
    useEffect(() => {
        const fetchData = async () => {
            await reportModel.fetchAsync();
            console.log(reportModel.getName());
            const reportProps = await reportModel.fetchProperties();
            if (reportProps.size() < 1) {
                console.log('no reportProps');
            } else {
                const sConfig = makeFormConfig(reportProps);
                console.log('Stepper Config -> ', sConfig);
                setStepperConfig(sConfig);
                setWaiter(false);
            }
            setDefaultName(reportModel.name);
        };
        fetchData();
    }, []);

    const onSubmit = async (values: any) => {
        const reportSavedModel = new ReportSavedModel();
        setWaiter(true);
        Object.entries(values).forEach(([key, value]) => {
            if (value instanceof Date) {
                values[key] = format(value, 'dd.MM.yyyy');
            }
        });
        values.report_id = match.params.reportId;
        const ret = await reportSavedModel.save({
            data: values
        });
        const location = {
            pathname: '/index.reports',
            state: { model: ret, reportId: values.report_id }
        };
        history.replace(location);
    };

    return (
        <>
            { !waiter ?
                <FormStepper steps={stepperConfig} defaultName={defaultName} onSubmit={onSubmit} />
                :
                <ui.Backdrop style={{ zIndex: 9999 }} open>
                    <ui.CircularProgress />
                </ui.Backdrop>
            }
        </>
    );
};
export default NewReport;
