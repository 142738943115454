import $ from 'jquery';

const methods = {
    on(options) {
        const settings = {
        };
        if (options) {
            $.extend(settings, options);
        }
        let el = {};
        if (settings.view) {
            el = settings.view.$el;
        } else {
            el = $(this);
        }
        el.html('<div class="spinnerS"><div class="animate"></div>' + $._('general.spinner.loading') + '</div>');
        return $(this);
    },
    off(options) {
        const settings = {
        };
        if (options) {
            $.extend(settings, options);
        }
        $(this).find('.spinnerS').remove();
        return $(this);
    }
};

$.fn.spinner = function start(method, ...args) {
    // Method calling logic
    if (methods[method]) {
        return methods[method].apply(this, Array.prototype.slice.call(args, 0));
    } else if (typeof method === 'object' || !method) {
        return methods.on.apply(this, args);
    }

    $.error('Method ' + method + ' does not exist on jMaBinso.spinner');
};
