import $ from 'jquery';
import Backbone from 'backbone';
import UploadView from '../../app/views/UploadView';

const EditReportView = Backbone.View.extend({
    initialize(options) {
        this.options = options || [];
    },
    render() {
        const view = this;
        // eslint-disable-next-line no-new
        new UploadView({
            title: $._('report.alert.title.add.report'),
            yesString: $._('report.list.button.add.report'),
            yesFunc() {
                view.setElement($('.modalMessage'));
                view.uploadReport();
            }
        });
    },
    uploadReport() {
        const view = this;
        this.model.setForm(view.$('form'));
        this.model.uploadReport({
            data: { categoryId: view.options.reportCategoryView.model.id },
            success(formMessage) {
                if (formMessage.showErrors()) {
                    $.modal.close();
                    formMessage.showSuccess(null, () => {
                        view.options.reportListView.render();
                    });
                }
            }
        });
    }
});
export default EditReportView;
