import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import html from '../templates/confirm_view.template';

const ConfirmView = Backbone.View.extend({
    el: 'div#hauptrahmen',
    initialize(options) {
        this.options = options || [];
        this.render();
    },
    render() {
        const view = this;
        const compiled = _.template(html)({
            $, title: view.options.title, message: view.options.message, no: _.isUndefined(view.options.cancelString) ? $._('alert.action.cancel') : view.options.cancelString, yes: _.isUndefined(view.options.yesString) ? $._('alert.action.yes') : view.options.yesString
        });
        // var compiled = _.template(html, {title: $._(view.options.title), message: $._(view.options.message), no:'no', yes:'yes'});
        $.modal(
            compiled,
            {
                overlayId: 'simplemodal-overlay',
                minHeight: '200px',
                minWidth: '400px',
                maxWidth: '400px',
                containerId: 'modalConfirm',
                overlayClose: false,
                onShow() {
                    // var modal = this;
                    view.setElement($('.simplemodal-container'));
                    view.delegateEvents(this.events);
                },
                onClose() {
                    if (_.isFunction(view.options.cancelFunc)) {
                        view.options.cancelFunc();
                    }
                    $.modal.close();
                }
            }
        );
        return view;
    },
    yes() {
        const view = this;
        // call the callback
        $.modal.close();
        if (_.isFunction(view.options.yesFunc)) {
            view.options.yesFunc();
        }
        // close the dialog
    },
    events: {
        'click .modalYes': 'yes'
    }
}, {
    show: options => new ConfirmView(options)
});
export default ConfirmView;
