import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import UserModel from '../models/UserModel';
import RoleCollection from '../../role/models/RoleCollection';
import SchemaCollection from '../../schema/models/SchemaCollection';
import ReportCollection from '../../report/models/ReportCollection';
import AlertView from '../../app/views/AlertView';
import FormMessage from '../../../utils/FormMessage';
import html from '../templates/detail_view.template';
import Globals from '../../../utils/Globals';

// Detail View von BenutzerVerwaltung
const DetailView = Backbone.View.extend({
    el: 'div.users-content-detail',
    model: null,
    initialize(options) {
        this.options = options;
        this.isAdmin = !!(Globals.actions.can('user.admin.global') || Globals.actions.can('user.admin.schema'));
    },
    render(id) {
        this.$('form').validationEngine('hideAll');
        $.scrollTo('#hauptrahmen', 300);
        this.model = new UserModel({ id });
        const view = this;
        $(this.el).spinner();
        if (_.isUndefined(id)) {
            this.renderTemplate(view.model);
        } else {
            this.model.fetch({
                success() {
                    view.renderTemplate(view.model);
                }
            });
        }
    },
    renderTemplate(model) {
        const view = this;
        view.options.listView.$('div.users-content-detail').html(_.template(html)({
            model,
            $,
            Actions: Globals.actions,
            baseLangs: Globals.serverConfig.application_langs
        }));
        view.setElement('#user-detail-view');
        view.$el.fadeIn();
        view.$('#username').attr('class', view.$('#username').data('ajax-class'));
        view.$('form').validationEngine({
            autoHidePrompt: true,
            bind: true,
            onAjaxFormElementComplete(jsonResponseFromServer) {
                const formMessage = new FormMessage(jsonResponseFromServer);
                formMessage.showErrors();
            }
        });
        if (view.isAdmin) {
            view.renderRoles();
            view.renderSchemas();
        }
        const roleIds = [];
        _.each(this.model.get('roles'), item => {
            roleIds.push(item.id);
        });
        view.renderReports(roleIds);
        view.$('#userBaseLangs').parent().uiselectfield('init');
        // $(".spalte_rechts_738").uiselectfield('init');
        // $(".numberset").uinumberset('init');
    },
    renderRoles() {
        const roles = new RoleCollection();
        roles.url = Globals.baseUrl + 'users/getRoles';
        const userroles = new RoleCollection(this.model.get('roles'));
        const view = this;
        roles.fetch({
            success() {
                _.renderMultiCheckbox('roles[].id', userroles, roles, view.$('.rolesCheckbox'));
                view.$('.rolesCheckbox').parent().uicheckbox('init');
                view.$('.rolesCheckbox div.option').click(() => {
                    const roleIds = [];
                    view.$('.rolesCheckbox div.option.active').each(function each() {
                        roleIds.push($(this).find('input').val());
                    });
                    view.renderReports(roleIds);
                });
            }
        });
        roles.sortByField('name', 'asc');
    },
    renderSchemas() {
        const schemas = new SchemaCollection();
        const userschemas = new SchemaCollection(this.model.get('schemas'));
        const view = this;
        schemas.fetch({
            success() {
                _.renderMultiCheckbox('schemas[].id', userschemas, schemas, view.$('.schemasCheckbox'));
                view.$('.schemasCheckbox').parent().uicheckbox('init');
            }
        });
        schemas.sortByField('name', 'asc');
    },
    renderReports(roleIds) {
        const reports = new ReportCollection();
        reports.url = Globals.baseUrl + 'users/getReports';
        const userreport = new ReportCollection(this.model.get('startReport'));
        const view = this;
        view.$('.reportsCheckbox').empty();
        reports.fetch({
            data: { roles: roleIds },
            success() {
                _.renderMultiCheckbox('startReport.id', userreport, reports, view.$('.reportsCheckbox'), 'name', {
                    check(model) {
                        if (model.get('showHtml')) {
                            return true;
                        }
                        return false;
                    }
                });
                view.$('.reportsCheckbox').parent().uicheckbox('init');
                view.$('.reportsCheckbox div.option').click(evt => {
                    const id = $(evt.currentTarget).find('input').val();
                    view.model.set('startReport', { id });
                });
                view.$('.reportsCheckbox div.option.active').click(() => {
                    view.model.set('startReport', {});
                });
            }
        });
        reports.sortByField('name', 'asc');
    },
    events: {
        'submit #user-form': 'saveUser',
        'click #reset-user': 'resetUser'
    },
    saveUser() {
        const view = this;
        if (view.isAdmin) {
            view.$('#username').attr('class', view.$('#username').data('no-ajax-class'));
        }
        if (view.$('#passwordString').val() !== '' && view.$('#passwordStringRepeat').val() === '') {
            $('#passwordStringRepeat').validationEngine('showPrompt', $._('validation.required.alertText'), 'error', 'topRight', true);
            return false;
        }
        this.model.clear({ silent: true });
        this.model.setForm(this.$('form'));
        let successMessage = null;
        if (_.isUndefined(view.model.id)) {
            successMessage = $._('user.alert.save.success');
        } else {
            successMessage = $._('user.alert.edit.success');
        }
        console.log('----->ShowMessage start Save');
        this.model.save(formMessage => {
            console.log('----->ShowMessageUserbefore Message');
            if (formMessage.showErrors()) {
                // view.$("form").validationEngine('hide');
                console.log('----->ShowMessageUser');
                AlertView.show({
                    message: successMessage,
                    okFunc() {
                        if (_.isUndefined(view.model.id)) {
                            view.options.listView.render();
                        } else {
                            // view.$("#username").attr('class',view.$("#username").data('ajax-class'));

                            view.options.listView.setName(view.model);
                            view.render(view.model.id);
                        }
                    }
                });
            }
        });
        return false;
    },
    resetUser() {
        $('.benutzerliste .active').trigger('click');
    }
});
export default DetailView;
