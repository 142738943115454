import React, { Suspense, lazy } from 'react';
import { Globals } from '@mabinso-registry/module-commons';
import { Route } from 'react-router-dom';
import Olap from './olap';
import Dashboard from './dashboard';
import Editors from './editors';
import Multiedit from './multiedit';
import Bildfahrplan from './bildfahrplan';

const CrossAnalytics = lazy(() => import(/* webpackIgnore: true */ Globals.modules.crossanalytics ? Globals.modules.crossanalytics.jsUrl : '').then(() => ({ default: window.CrossAnalytics })));

const Editor = lazy(() => import(/* webpackIgnore: true */ Globals.modules.editor ? Globals.modules.editor.jsUrl : '').then(() => { return { default: window.Editor }; }));

const ETL = lazy(() => import(/* webpackIgnore: true */ Globals.modules.etl ? Globals.modules.etl.jsUrl : '').then(() => ({ default: window.etl })));

const ModuleRoutes = ({ ...props }) => (
    <Suspense>
        <Route path="/module.olap" render={() => <Olap {...props} />} />
        <Route path="/module.multiedit" render={() => <Multiedit {...props} />} />
        <Route path="/module.editors" render={() => <Editors {...props} />} />
        <Route path="/module.bildfahrplan" render={() => <Bildfahrplan {...props} />} />
        <Route path="/module.dashboard" render={() => <Dashboard {...props} />} />
        <Route
            path="/module.crossanalytics"
            render={() => <CrossAnalytics {...props} />}
        />
        <Route
            path="/module.editor"
            render={() => <Editor {...props} />}
        />
        <Route
            path="/module.etl"
            render={() => <ETL {...props} />}
        />
    </Suspense>
);
export default ModuleRoutes;
