import $ from 'jquery';
import _ from 'underscore';
import MabinsoModel from '../../app/models/MabinsoModel';
import Globals from '../../../utils/Globals';

class InterfaceModel extends MabinsoModel {
    urlRoot = Globals.baseUrl + 'interfaces/interface';
    urlStart = Globals.baseUrl + 'interfaces/startInterface';
    urlStop = Globals.baseUrl + 'interfaces/stopInterface';
    checkRun = Globals.baseUrl + 'interfaces/stopInterface';
    get(key: any) {
        const value = MabinsoModel.prototype.get.call(this, key);
        if (key === 'name') {
            return _.toName(value);
        }
        return value;
    }
    getConfigByName(name: any) {
        const config = this.get('configuration');
        let rItem = null;
        _.each(config, (item) => {
            if (item.name === name) {
                rItem = item;
                return false;
            }
        });
        return rItem;
    }
    hasConfig() {
        const config = this.get('configuration');
        const intern = 'intern.';
        const onexec = 'on_exec.';
        let hasConfig = false;
        _.each(config, (item) => {
            if (item.name.slice(0, intern.length) !== intern && item.name.slice(0, onexec.length) !== onexec) {
                hasConfig = true;
                return true;
            }
        });
        return hasConfig;
    }
    startInterface(options: any) {
        const model = this;
        $.ajax({
            url: model.urlStart,
            data: { id: model.id },
            dataType: 'JSON',
            success(data) {
                if (_.isFunction(options.success)) {
                    options.success(data);
                }
            },
        });
    }
    stopInterface(options: any) {
        const model = this;
        $.ajax({
            url: model.urlStop,
            data: { id: model.id },
            dataType: 'JSON',
            success(data) {
                if (_.isFunction(options.success)) {
                    options.success(data);
                }
            },
        });
    }
    isRun() {
        if (this.get('isrun')) {
            return $._('interface.active');
        }
        return $._('interface.notactive');
    }
    static deleteItems(options: any) {
        console.log(options.items);
        $.ajax({
            url: Globals.baseUrl + 'interfaces/deleteItems',
            data: { ids: options.items },
            traditional: true,
            type: 'POST',
            dataType: 'JSON',
            success(data) {
                if (_.isFunction(options.success)) {
                    options.success(data);
                }
            },
        });
    }
}
export default InterfaceModel;
