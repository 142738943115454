import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import moment from 'moment';
import dateSingleday from '../../templates/edit_report_saved_view/date_singleday.template';
import dateFromToView from '../../templates/edit_report_saved_view/date_from_to_view.template';

const DateFromToView = Backbone.View.extend({
    initialize(options) {
        this.options = options;
        this.render();
    },
    render() {
        const view = this;
        _.each(view.options.property.get('propertyArray'), (item) => {
            if (item.get('dialog_element') === 'to') {
                view.options.property.set('to', item);
            } else if (item.get('dialog_element') === 'from') {
                view.options.property.set('from', item);
            }
        });
        if (view.options.type === 'date_singleday') {
            view.$el.append(_.template(dateSingleday)({ $, property: view.options.property, planReport: view.options.planReport }));
            view.$('#' + view.options.property.id).datepicker({
                changeMonth: true,
                changeYear: true,
            });
        } else {
            view.$el.append(
                _.template(dateFromToView)({
                    $,
                    property: view.options.property,
                    planReport: view.options.planReport,
                    financialyear: window.config.company.financialyear,
                })
            );
            view.$('#' + view.options.property.get('from').id).datepicker({
                changeMonth: true,
                changeYear: true,
                onSelect(date) {
                    view.onSelectDateFrom(date, view);
                },
            });
            view.$('#' + view.options.property.get('to').id).datepicker({
                changeMonth: true,
                changeYear: true,
                onSelect(date) {
                    view.onSelectDateTo(date, view);
                },
            });
        }
        view.$el.uiselectfield('init');
        view.$el.uicheckbox('init');
        if (_.isFunction(view.options.success)) view.options.success();

        view.$('.hideable').hide();
        view.$('.hideable:first').show();
        this.mabinso_vardate = view.$('#mabinso_vardate').val();
        view.$('#mabinso_vardate').val('');
    },
    events: {
        // 'click #preselectdate .uiSelect li': 'clickPreselect',
        'click .fastselect a': 'fastFastselect',
        'click .option': 'changeDateType',
        'click .variableDate ul': 'selectVariable',
        // 'blur .input input': 'blurInput'
    },
    blurInput() {
        const view = this;
        view.$('#' + view.options.property.id).datepicker('hide');
    },
    selectVariable() {
        const func = $('#mabinso_vardate').val();
        this.setDateFromTo(func);
        return false;
    },
    changeDateType(evt) {
        const view = this;

        let clickedoption = $(evt.target);
        if (clickedoption.hasClass('optBeschriftung')) {
            clickedoption = clickedoption.parent();
        }
        const clickedIndex = clickedoption.find('input').attr('value');

        if (clickedIndex === '1') {
            view.$('#mabinso_vardate').val(view.mabinso_vardate);
            this.setDateFromTo($('#mabinso_vardate').val());
        } else {
            view.mabinso_vardate = view.$('#mabinso_vardate').val();
            view.$('#mabinso_vardate').val('');
        }
        view.$('.hideable').hide();
        view.$('.hideable:eq(' + clickedIndex + ')').show();
    },
    fastFastselect(evt) {
        evt.stopImmediatePropagation();
        const func = $(evt.target).attr('href');
        const view = this;
        view.setDateFromTo(func);
        return false;
    },
    setDateFromTo(func) {
        const voreinstellung = this[func]();
        let dateFieldFrom = null;
        if (typeof this.options.property.get('from') !== 'undefined') {
            dateFieldFrom = this.options.property.get('from').id;
        } else {
            dateFieldFrom = this.options.property.id;
        }
        this.$('#' + dateFieldFrom).datepicker('setDate', voreinstellung[0]);
        if (voreinstellung[1] !== false) {
            if (typeof this.options.property.get('to') !== 'undefined') {
                this.onSelectDateFrom(voreinstellung[0], this);
                this.$('#' + this.options.property.get('to').id).datepicker('setDate', voreinstellung[1]);
            }
        }
    },
    onSelectDateFrom(date, view) {
        view.$('#' + view.options.property.get('to').id).datepicker('option', 'minDate', date);
    },
    onSelectDateTo(date, view) {
        view.$('#' + view.options.property.get('from').id).datepicker('option', 'maxDate', date);
    },
    getActualYear() {
        const rueckgabe = [];

        const now = new Date();
        const startdate = new Date(now.getFullYear(), 0, 1);
        const enddate = new Date(now.getFullYear(), 11, 31);

        rueckgabe[0] = startdate;
        rueckgabe[1] = enddate;

        return rueckgabe;
    },
    getLastYear() {
        const rueckgabe = [];

        const now = new Date();
        const startdate = new Date(now.getFullYear() - 1, 0, 1);
        const enddate = new Date(now.getFullYear() - 1, 11, 31);

        rueckgabe[0] = startdate; // '01.01.'+startdate.getFullYear();
        rueckgabe[1] = enddate; // '31.12.'+startdate.getFullYear();

        // alert(rueckgabe[0]+" "+rueckgabe[1]);
        return rueckgabe;
    },
    getActualQuarter() {
        const rueckgabe = [];

        const now = new Date();

        const aktQuarter = Math.floor((now.getMonth() + 3) / 3);

        if (aktQuarter === 1) {
            rueckgabe[0] = new Date(now.getFullYear(), 0, 1); // '01.01.'+now.getFullYear();
            rueckgabe[1] = new Date(now.getFullYear(), 2, 31); // '31.03.'+now.getFullYear();
        }
        if (aktQuarter === 2) {
            rueckgabe[0] = new Date(now.getFullYear(), 3, 1); // '01.04.'+now.getFullYear();
            rueckgabe[1] = new Date(now.getFullYear(), 5, 30); // '30.06.'+now.getFullYear();
        }
        if (aktQuarter === 3) {
            rueckgabe[0] = new Date(now.getFullYear(), 6, 1); // '01.07.'+now.getFullYear();
            rueckgabe[1] = new Date(now.getFullYear(), 8, 30); // '30.09.'+now.getFullYear();
        }
        if (aktQuarter === 4) {
            rueckgabe[0] = new Date(now.getFullYear(), 9, 1);
            rueckgabe[1] = new Date(now.getFullYear(), 11, 31);
        }

        // alert(rueckgabe[0]+" "+rueckgabe[1]);
        return rueckgabe;
    },
    getLastQuarter() {
        const rueckgabe = [];

        const now = new Date();

        const aktQuarter = Math.floor((now.getMonth() + 3) / 3);
        let lastquarter = aktQuarter - 1;
        if (lastquarter === 0) {
            lastquarter = 4;
        }

        if (lastquarter === 1) {
            rueckgabe[0] = new Date(now.getFullYear(), 0, 1); // '01.01.'+now.getFullYear();
            rueckgabe[1] = new Date(now.getFullYear(), 2, 31); // '31.03.'+now.getFullYear();
        }
        if (lastquarter === 2) {
            rueckgabe[0] = new Date(now.getFullYear(), 3, 1); // '01.04.'+now.getFullYear();
            rueckgabe[1] = new Date(now.getFullYear(), 5, 30); // '30.06.'+now.getFullYear();
        }
        if (lastquarter === 3) {
            rueckgabe[0] = new Date(now.getFullYear(), 6, 1); // '01.07.'+now.getFullYear();
            rueckgabe[1] = new Date(now.getFullYear(), 8, 30); // '30.09.'+now.getFullYear();
        }
        if (lastquarter === 4) {
            const tyear = now.getFullYear() - 1;
            rueckgabe[0] = new Date(tyear, 9, 1);
            rueckgabe[1] = new Date(tyear, 11, 31);
        }

        return rueckgabe;
    },
    getActualMonth() {
        const rueckgabe = [];

        const now = new Date();

        const startdate = new Date(now.getFullYear(), now.getMonth(), 1);
        const enddate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        rueckgabe[0] = startdate;
        rueckgabe[1] = enddate;

        return rueckgabe;
    },
    getLastMonth() {
        const rueckgabe = [];

        const now = new Date();

        let letztermonat = now.getMonth() - 1;
        let jahr = now.getFullYear();

        if (letztermonat === -1) {
            letztermonat = 11;
            jahr -= 1;
        }

        const startdate = new Date(jahr, letztermonat, 1);
        const enddate = new Date(jahr, letztermonat + 1, 0);

        let backMonth = startdate.getMonth() + 1;
        if (backMonth < 10) {
            backMonth = '0' + backMonth;
        }

        let startDay = startdate.getDate();
        if (startDay < 10) {
            startDay = '0' + startDay;
        }

        rueckgabe[0] = startdate;
        rueckgabe[1] = enddate;

        return rueckgabe;
    },
    getActualWeek() {
        const rueckgabe = [];

        const now = new Date();

        let jetzttagMinusEins = now.getDay() - 1;
        if (jetzttagMinusEins < 0) {
            jetzttagMinusEins = 6;
        }

        const startTag = now.getDate() - jetzttagMinusEins;
        const endTag = startTag + 6;

        const startdate = new Date(now.getFullYear(), now.getMonth(), startTag);
        const enddate = new Date(now.getFullYear(), now.getMonth(), endTag);

        let startTagAusgabe = startdate.getDate();
        if (startTagAusgabe < 10) {
            startTagAusgabe = '0' + startTagAusgabe;
        }

        let startMonatAusgabe = startdate.getMonth() + 1;
        if (startMonatAusgabe < 10) {
            startMonatAusgabe = '0' + startMonatAusgabe;
        }
        rueckgabe[0] = startdate;

        let endeTagAusgabe = enddate.getDate();
        if (endeTagAusgabe < 10) {
            endeTagAusgabe = '0' + endeTagAusgabe;
        }

        let endeMonatAusgabe = enddate.getMonth() + 1;
        if (endeMonatAusgabe < 10) {
            endeMonatAusgabe = '0' + endeMonatAusgabe;
        }

        rueckgabe[1] = enddate;

        return rueckgabe;
    },
    getLastWeek() {
        const rueckgabe = [];

        const now = new Date();

        let jetzttagMinusEins = now.getDay() - 1;
        if (jetzttagMinusEins < 0) {
            jetzttagMinusEins = 6;
        }

        const startTag = now.getDate() - 7 - jetzttagMinusEins;
        const endTag = startTag + 6;

        const startdate = new Date(now.getFullYear(), now.getMonth(), startTag);
        const enddate = new Date(now.getFullYear(), now.getMonth(), endTag);

        let startTagAusgabe = startdate.getDate();
        if (startTagAusgabe < 10) {
            startTagAusgabe = '0' + startTagAusgabe;
        }

        let startMonatAusgabe = startdate.getMonth() + 1;
        if (startMonatAusgabe < 10) {
            startMonatAusgabe = '0' + startMonatAusgabe;
        }
        rueckgabe[0] = startdate;

        let endeTagAusgabe = enddate.getDate();
        if (endeTagAusgabe < 10) {
            endeTagAusgabe = '0' + endeTagAusgabe;
        }

        let endeMonatAusgabe = enddate.getMonth() + 1;
        if (endeMonatAusgabe < 10) {
            endeMonatAusgabe = '0' + endeMonatAusgabe;
        }

        rueckgabe[1] = enddate;
        console.log('enddate', enddate);

        return rueckgabe;
    },
    getToday() {
        const rueckgabe = [];

        rueckgabe[0] = new Date();
        rueckgabe[1] = false;

        return rueckgabe;
    },
    getYesterday() {
        const rueckgabe = [];
        const now = new Date();
        rueckgabe[0] = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
        rueckgabe[1] = false;

        return rueckgabe;
    },
    getDayBeforeYesterday() {
        const rueckgabe = [];
        const now = new Date();
        rueckgabe[0] = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);
        rueckgabe[1] = false;

        return rueckgabe;
    },
    getActualFinancialYear() {
        const rueckgabe = [];
        const actualYear = moment().format('YYYY');
        const financialyearStart = actualYear + '-' + window.config.company.financialyear.month + '-' + window.config.company.financialyear.day;
        let businessYearStart;
        let businessYearEnd;
        if (moment() > moment(financialyearStart)) {
            businessYearStart = moment(financialyearStart).toDate();
            businessYearEnd = moment(financialyearStart).subtract(1, 'd').add(1, 'y').toDate();
        } else {
            businessYearStart = moment(financialyearStart).subtract(1, 'y').toDate();
            businessYearEnd = moment(financialyearStart).subtract(1, 'd').toDate();
        }
        rueckgabe[0] = businessYearStart;
        rueckgabe[1] = businessYearEnd;
        return rueckgabe;
    },
    getLastFinancialYear() {
        const rueckgabe = [];
        const actualYear = moment().format('YYYY');
        const financialyearStart = actualYear + '-' + window.config.company.financialyear.month + '-' + window.config.company.financialyear.day;
        let businessYearStart;
        let businessYearEnd;
        if (moment() > moment(financialyearStart)) {
            businessYearStart = moment(financialyearStart).subtract(1, 'y').toDate();
            businessYearEnd = moment(financialyearStart).subtract(1, 'd').toDate();
        } else {
            businessYearStart = moment(financialyearStart).subtract(2, 'y').toDate();
            businessYearEnd = moment(financialyearStart).subtract(1, 'y').subtract(1, 'd').toDate();
        }
        rueckgabe[0] = businessYearStart;
        rueckgabe[1] = businessYearEnd;
        return rueckgabe;
    },
});
export default DateFromToView;
