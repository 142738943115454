import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';

// Backbone.emulateHTTP = true;
// Backbone.emulateJSON = true;
// Backbone.sync
//-------------

// Override this function to change the manner in which Backbone persists
// models to the server. You will be passed the type of request, and the
// model in question. By default, uses makes a RESTful Ajax request
// to the model's `url()`. Some possible customizations could be:
//
//* Use `setTimeout` to batch rapid-fire updates into a single request.
//* Send up the models as XML instead of JSON.
//* Persist models via WebSockets instead of Ajax.
//
// Turn on `Backbone.emulateHTTP` in order to send `PUT` and `DELETE` requests
// as `POST`, with a `_method` parameter containing the true HTTP method,
// as well as all requests with the body as `application/x-www-form-urlencoded` instead of
// `application/json` with the model in a param named `model`.
// Useful when interfacing with server-side languages like **PHP** that make
// it difficult to read the body of `PUT` requests.

Backbone.originalSync = Backbone.sync;
Backbone.mabinsoSync = (method, model, options) => {
    // Map from CRUD to HTTP for our default `Backbone.sync` implementation.
    const methodMap = {
        create: 'POST',
        update: 'PUT',
        delete: 'DELETE',
        read: 'GET'
    };
    // Helper function to get a URL from a Model or Collection as a property
    // or as a function.
    const getUrl = object => {
        if (!(object && object.url)) return null;
        return _.isFunction(object.url) ? object.url() : object.url;
    };

    // Throw an error when a URL is needed, and none is supplied.
    const urlError = () => {
        throw new Error('A "url" property or function must be specified');
    };

    const type = methodMap[method];

    // console.log(options);

    // Default JSON-request options.
    const params = _.extend({
        type,
        dataType: 'json'
    }, options);

    // Ensure that we have a URL.
    if (!params.url) {
        params.url = getUrl(model) || urlError();
    }

    // Ensure that we have the appropriate request data.
    if (!params.data && model && (method === 'create' || method === 'update')) {
        const newData = {};
        $.each(model.toJSON(), (key, value) => {
            newData[model.modelName + '.' + key] = value;
        });
        // params.contentType = 'application/json';
        params.data = newData;
    } else if (params.data && model && (method === 'create' || method === 'update')) {
        params.data = _.extend(params.data, model.toJSON());
    }
    // Make the request.
    return $.ajax(params);
};
Backbone.sync = (method, model, options) => {
    const backend = model.backend || (model.collection && model.collection.backend);
    // console.log("Backbone custom Sync started",method,options,model);
    const params = _.extend({
    }, options);
    if (backend) {
        return Backbone.originalSync(method, model, params);
    }
    return Backbone.mabinsoSync(method, model, params);
};
