/* eslint-disable */
/* Baut Macht Abblender für modale Fenster */
define(['jquery'], function ($) {
		var preventDefault = function(e) {
            e = e || window.event;
            if (e.preventDefault)
                e.preventDefault();
            e.returnValue = false;
        };

		var methods = {
			init : function(options) {
				var settings = {
					'color'	: '#0a193a',
                    'scrolling' : true,
					'onClick': function(){
						$(this).abblender('close');
					}
				};
				if ( options ) {
					$.extend( settings, options );
				}
                //Disable Scrolling
                if(!settings.scrolling) {
                    var keys = {37: 1, 38: 1, 39: 1, 40: 1};
                    var preventDefaultForScrollKeys = function(e) {
                        if (keys[e.keyCode]) {
                            preventDefault(e);
                            return false;
                        }
                    };
                    if (window.addEventListener) // older FF
                        window.addEventListener('DOMMouseScroll', preventDefault, false);
                    window.onwheel = preventDefault; // modern standard
                    window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
                    window.ontouchmove  = preventDefault; // mobile
                    document.onkeydown  = preventDefaultForScrollKeys;
                    //get current scroll position
                    var xPosition = window.scrollX || window.pageXOffset || document.body.scrollLeft;
                    var yPosition = window.scrollY || window.pageYOffset || document.body.scrollTop;
					//disable scrolling
                    window.onscroll = function() {
                        window.scrollTo(xPosition, yPosition);
                    };
                }
				$("body").append('<div id="uiAbblender" class="overlayElement"></div>');
				$("#uiAbblender").css('height', $("body").height()+'px').css('width', $("body").width()+'px').css('background', settings.color).css('opacity','0.5').css('left',0).css('top',0).css('position','fixed').css('z-index','1001');
				$("#uiAbblender").click(options.onClick);
							
			},
			close : function(options) {

                var settings = {
					'mycallback'	: function(){}
				};
				if ( options ) { 
					$.extend( settings, options );
				}
                if(!settings.scrolling) {
                    if (window.removeEventListener)
                        window.removeEventListener('DOMMouseScroll', preventDefault, false);
                    window.onmousewheel = document.onmousewheel = null;
                    window.onwheel = null;
                    window.ontouchmove = null;
                    document.onkeydown = null;
                    window.onscroll=function(){};
                }
				
				$('.overlayElement').detach();
				$(document).validationEngine('hideAll');
				settings.mycallback();
				
			},

		};	
		$.fn.abblender = function(method){					
			// Method calling logic
			if ( methods[method] ) {
				return methods[ method ].apply( this, Array.prototype.slice.call( arguments, 1 ));
			}
			else if ( typeof method === 'object' || ! method ) {
				return methods.init.apply( this, arguments );
			}
			else {
				$.error( 'Method ' +  method + ' does not exist on jMaBinso.abblender' );
			}  		
		}

});