import Backbone from 'backbone';
import ReportJobModel from '../models/ReportJobModel';
import Globals from '../../../utils/Globals';
import MabinsoCollection from '../../app/models/MabinsoCollection';

class ReportJobCollection extends MabinsoCollection<ReportJobModel> {
    url = Globals.baseUrl + 'reportJobs/jobs';
    model = ReportJobModel;
}
export default ReportJobCollection;
