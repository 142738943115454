import React from 'react';
import PropTypes from 'prop-types';
import './Moduleholder.scss';

class Moduleholder extends React.Component {
    static propTypes = {
        children: PropTypes.any.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="Moduleholder default">
                {this.props.children}
            </div>
        );
    }
}
export default Moduleholder;
