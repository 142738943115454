import $ from 'jquery';
import _ from 'underscore';
import Globals from './Globals';
import AlertView from '../components/app/views/AlertView';


const FormMessage = class FormMessage {
    static getText(messageKey, key) {
        return Globals.i18n.data[messageKey + '.' + key] ? $._(messageKey + '.' + key) : $._(key) + ' ' + $._(messageKey);
    }

    errorDiv = 'error-div';
    messageDiv = 'message-div';

    constructor(data, modelName) {
        this.data = data;
        this.modelName = modelName;
    }

    hasErrors() {
        return this.data.hasErrors;
    }

    getErrors() {
        return this.data.errors;
    }

    getSuccess() {
        return this.data.success;
    }

    getGid() {
        return this.data.gid;
    }

    show(successMsgCode, successCallBack) {
        // var mythis = this;
        // console.log(this.hasErrors());
        if (this.hasErrors()) {
            return this.showErrors();
        }
        return this.showSuccess(successMsgCode, successCallBack);
    }

    showSuccess(successMsgCode, successCallBack) {
        $('#' + FormMessage.errorDiv)
            .empty();
        if (!this.hasErrors()) {
            let message = '';
            if (!successMsgCode) {
                _.each(this.getSuccess(), item => {
                    message += item.message;
                });
            }
            AlertView.show({
                message: successMsgCode ? $._(successMsgCode) : message,
                title: $._('alert.action.success'),
                okFunc() {
                    if (successCallBack !== undefined) {
                        successCallBack();
                    }
                }
            });
        } else {
            return true;
        }
    }

    getMessageKey(key) {
        if (!_.isUndefined(this.modelName)) {
            const patt = new RegExp(this.modelName + '.');
            key = key.replace(patt, '');
        }
        return key;
    }

    showErrors() {
        const mythis = this;
        if (this.hasErrors()) {
            const myul = $('<ul></ul>');
            $.each(this.getErrors(), (index, item) => {
                const key = mythis.getMessageKey(item.key);
                const text = FormMessage.getText(item.messageKey, key);
                myul.append('<li>' + text + '</li>');
                // console.log($('input[name="'+key+'"]'));
                if ($('input[name="' + key + '"]').length > 0) {
                    $('input[name="' + key + '"]')
                        .validationEngine('showPrompt', text, 'error', 'topRight', true);
                } else {
                    $('input[name="' + item.messageKey + '"]')
                        .validationEngine('showPrompt', text, 'error', 'topRight', true);
                }
            });
            // $('#'+FormMessage.errorDiv).html(myul);
            return false;
        }
        if (this.getSuccess()) {
            $.each(this.getSuccess(), (index, item) => {
                const text = FormMessage.getText(item.messageKey, item.key);
                // console.log($('input[name="'+item.key+'"]'));
                $('input[name="' + item.key + '"]')
                    .validationEngine('showPrompt', text, 'pass', 'topRight', true);
            });
        }
        return true;
    }
};

export default FormMessage;
