/* eslint-disable no-new */
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import WaitView from '../../app/views/WaitView';
import ComboBoxView from './editReportSavedView/ComboBoxView';
import DateView from './editReportSavedView/DateView';
import MultiSelectView from './editReportSavedView/MultiSelectView';
import MultiSelectColsView from './editReportSavedView/MultiSelectColsView';
import TableSelectView from './editReportSavedView/TableSelectView';
import html from '../../app/templates/widget_view.template';


$.expr[':'].icontains = $.expr.createPseudo((arg) => {
    // eslint-disable-next-line func-names
    return function (elem) {
        return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
});

const EditReportSavedView = Backbone.View.extend({
    el: 'body',
    initialize(options) {
        this.options = options;
    },
    render() {
        const view = this;
        view.setElement($('body'));

        delete view.actualSlide;
        view.$('.widgetHolder').detach();

        // machen des WidgetHolders am Ende des DOM
        let el = _.make('div', { class: 'widgetHolder' });
        view.$el.append(el);
        view.setElement($('.widgetHolder'));

        // form machen
        el = _.make('form', { id: 'do-form-parameter' });
        view.$el.append(el);

        view.options.reportView.model.fetchProperties({
            success(properties) {
                view.properties = properties;
                if (properties.size() < 1) {
                    view.saveReportSaved();
                } else {
                    view.nextStep();
                }
            }
        });
    },
    lastStep() {
        const view = this;
        if (view.actualSlide === 0) {
            $.modal.close();
            view.close();
        } else {
            $.modal.close();
            const documentnamedefault = view.$('#documentname' + (view.actualSlide)).val();
            view.actualSlide -= 1;
            view.$('#documentname' + (view.actualSlide)).val(documentnamedefault);
            view.openModal();
        }
        return false;
    },
    nextStep() {
        const view = this;
        let documentnamedefault = '';
        $.modal.close();
        if (_.isUndefined(view.actualSlide)) {
            view.actualSlide = 0;
        } else {
            view.actualSlide += 1;
        }
        if (view.actualSlide > 0) {
            documentnamedefault = view.$('#documentname' + (view.actualSlide - 1)).val();
        }
        if (view.actualSlide < view.properties.slides()) {
            if (view.$('#reportSavedFormNr' + view.actualSlide).length > 0) {
                view.$('#documentname' + (view.actualSlide)).val(documentnamedefault);
                if (view.properties.size() > 0) {
                    view.renderProperties(view.properties, view.actualSlide);
                    // $("#do-form-parameter").validationEngine({validationEventTrigger: 'change'});
                } else {
                    view.$('.modalYes').trigger('click');
                }
            } else {
                let nextButtonText = '';
                let lastButtonText = '';
                if (view.actualSlide === view.properties.slides() - 1) {
                    nextButtonText = (!_.isUndefined(this.options.planReport) && this.options.planReport ? $._('report.new.button.next') : $._('report.new.button.start'));
                } else {
                    nextButtonText = $._('report.new.button.next');
                }
                if (view.actualSlide === 0) {
                    lastButtonText = $._('dialog.cancel');
                } else {
                    lastButtonText = $._('report.new.button.last');
                }
                const compiled = _.template(html)({
                    $,
                    actualSlide: view.actualSlide,
                    title: $._('report.new.title'),
                    message: $._('wizzard.h1'),
                    close: $._('dialog.cancel'),
                    help: $._('dialog.help'),
                    documentname: $._('dialog.documentname.report'),
                    documentnamedefault,
                    next: nextButtonText,
                    last: lastButtonText
                });
                view.$('#do-form-parameter').append(compiled);

                if (view.properties.size() > 0) {
                    view.renderProperties(view.properties, view.actualSlide);
                    // $("#do-form-parameter").validationEngine({validationEventTrigger: 'change'});
                } else {
                    view.$('.modalYes').trigger('click');
                }
                view.$('button.modalBack').removeAttr('disabled');
            }
        } else {
            view.saveReportSaved();
        }


        return false;
    },
    renderProperties(properties, slide) {
        const view = this;
        const rp = view.$('#reportSavedFormNr' + view.actualSlide + ' .formBody');
        // var asyncCount = properties.slideSize(slide);
        const success = () => {
            view.$('button').removeAttr('disabled');
            $(window).trigger('resize.simplemodal');
        };
        const formValues = _.serializeObject(view.$('form'));
        properties.each(slide, property => {
            const myType = property.get('dialog_typ') || property.get('dialog_type');
            if (myType) {
                property.set('formValues', formValues);
                const className = property.id + '_' + myType;
                let el = $('.' + className);
                if (el.length < 1 || (!_.isUndefined(property.get('dialog_sql_param_0') && property.get('dialog_sql_param_0') !== ''))) {
                    if (myType === 'date_from_to' || myType === 'date_singleday') {
                        if (el.length < 1) {
                            el = _.make('fieldset', { class: 'col_100 date_from_to ' + className });
                            rp.append(el);
                        }
                        new DateView({
                            el,
                            property,
                            type: myType,
                            planReport: view.options.planReport,
                            success
                        });
                    } else if (myType === 'combobox') {
                        if (el.length < 1) {
                            el = _.make('fieldset', { class: 'col_50 combobox ' + className });
                            rp.append(el);
                        }
                        new ComboBoxView({
                            el,
                            property,
                            success
                        });
                    } else if (myType === 'multiselect') {
                        if (el.length < 1) {
                            el = _.make('fieldset', { class: 'col_50 combobox ' + className });
                            rp.append(el);
                        }
                        new MultiSelectView({
                            el,
                            property,
                            success
                        });
                    } else if (myType === 'tableselect') {
                        console.log('h');
                        if (el.length < 1) {
                            el = _.make('fieldset', { class: 'col_100 combobox ' + className });
                            rp.append(el);
                        }
                        new TableSelectView({
                            el,
                            property,
                            success
                        });
                    } else if (myType === 'multiselect_cols') {
                        if (el.length < 1) {
                            el = _.make('fieldset', {
                                class: 'col_100 combobox ' + className,
                                style: 'clear:both'
                            });
                            rp.append(el);
                        }
                        new MultiSelectColsView({
                            el,
                            property,
                            success
                        });
                    }
                }
                if (el.length < 1 && property.get('dialog_seq') !== 0) {
                    rp.append(_.make('div', { class: 'trenner' }));
                }
            }
        });
        view.openModal();
    },
    openModal() {
        const view = this;
        $.modal(
            view.$('#reportSavedFormNr' + view.actualSlide),
            {
                overlayId: 'simplemodal-overlay',
                focus: false,
                // minHeight: '100%',
                // maxHeight: '800px',
                minWidth: '680px',
                maxWidth: '680px',
                containerId: 'modalWidget',
                persist: true,
                overlayClose: false,
                // autoResize: true,
                autoPosition: true,
                onShow(dlg) {
                    view.setElement('body');
                    $(dlg.container).css('height', 'auto');
                    // $("#simplemodal-container").css('height', 'auto'); //To reset the container.
                    $('html').css('overflow', 'hidden');
                    $('.simplemodal-wrap').css('overflow', 'visible');
                    $('.simplemodal-wrap').attr('data-help', 'general.help.editreport');
                },
                onClose() {
                    $('html').css('overflow', 'auto');
                    $('.simplemodal-wrap').removeAttr('data-help');
                    $.modal.close();
                    view.$el.validationEngine('hideAll');
                }


            }
        );
    },
    events: {
        'click .modalYes': 'validateStep',
        'click .modalBack': 'lastStep',
        'click .modalClose': 'close',
        'click .modalHelp': 'openhelp',
        'click .modalMeta': 'modalMetaActive',
        'focusout .modalMeta input': 'modalMetaInActive'
    },
    modalMetaActive(e) {
        $(e.currentTarget).addClass('active');
        $(e.currentTarget).find('input').focus();
    },
    modalMetaInActive(e) {
        $(e.currentTarget).closest('.modalMeta').removeClass('active');
    },
    openhelp() {
        this.options.enableHelp('general.help.editreport');
    },
    close() {
        const view = this;
        view.setElement($('.widgetHolder'));
        $('.simplemodal-wrap').removeAttr('data-help');
        view.remove();
    },
    validateStep() {
        const view = this;
        if (view.$('.modalWidget .spinnerS').length > 0) {
            return false;
        }
        /* $(window).scroll(function(){
            view.$(".formBody").validationEngine('detach');
            //view.$(".formBody").validationEngine('attach', {scroll: false});
            view.$(".formBody").validationEngine('validate');
        }); */
        if (view.$('.modalWidget').validationEngine('validate') === true) {
            view.nextStep();
        }
        return false;
    },
    saveReportSaved() {
        const view = this;
        this.model.setForm(view.$('form'));
        if (this.model.isValid()) {
            if (!_.isUndefined(this.options.planReport) && this.options.planReport) {
                view.options.reportView.planReport({ reportSavedModel: this.model });
                delete view.actualSlide;
                view.close();
            } else {
                let noCancel = true;
                const wait = new WaitView({
                    message: $._('report.alert.new.report'),
                    closeString: $._('report.alert.new.report.cancel'),
                    closeHide: true,
                    success(id) {
                        noCancel = false;
                        // console.log(id);
                        const oldRs = view.options.reportView.collection.get(id);
                        // console.log(oldRs);
                        delete view.actualSlide;
                        view.close();
                        oldRs.view.deleteSavedReport();
                    }
                });
                const myTimeout = setTimeout(() => {
                    if (noCancel) {
                        wait.remove();
                        view.close();
                    }
                }, 10000);
                this.model.save({
                    data: { report_id: view.options.reportView.model.id },
                    success(formMessage, model) {
                        if (noCancel) {
                            clearTimeout(myTimeout);
                            if (formMessage.showErrors()) {
                                wait.remove();
                                let oldRs = null;
                                if (!_.isUndefined(view.options.reportView.collection)) { oldRs = view.options.reportView.collection.get(model.id); }
                                let reportSavedView = null;
                                if (oldRs != null) {
                                    oldRs.view.setStatus(model);
                                    reportSavedView = oldRs.view;
                                } else {
                                    reportSavedView = view.options.reportView.addReportSavedView(model, 'after');
                                }
                                view.options.reportView.triggerReportsSaved(true);
                                if (model.get('finished') != null && model.get('fail') === false && model.get('pages') > 0) {
                                    reportSavedView.triggerReport(model.get('showDefault'));
                                } else if (model.get('finished') == null) {
                                    console.log('Finished');
                                }
                            }
                            delete view.actualSlide;
                            view.close();
                        }
                    }
                });
            }
        }
        return false;
    }
}, {
    getPropertyElement(property, success) {
        const myType = property.get('dialog_typ') || property.get('dialog_type');
        let el = null;
        if (myType) {
            if (myType === 'date_from_to' || myType === 'date_singleday') {
                el = _.make('fieldset', { class: 'col_100 date_from_to' });
                new DateView({
                    el, property, type: myType, success
                });
            } else if (myType === 'combobox') {
                el = _.make('fieldset', { class: 'col_50 combobox' });
                new ComboBoxView({
                    el,
                    property,
                    success
                });
            } else if (myType === 'multiselect') {
                el = _.make('fieldset', { class: 'col_50 combobox' });
                new MultiSelectView({
                    el,
                    property,
                    success
                });
            } else if (myType === 'multiselect_cols') {
                el = _.make('fieldset', { class: 'col_100 combobox', style: 'clear:both' });
                new MultiSelectColsView({
                    el,
                    property,
                    success
                });
            }
            if (property.get('dialog_seq') !== 0 && el != null) {
                $(el).after(_.make('div', { class: 'trenner' }));
            }
        }
        return el;
    }
});
export default EditReportSavedView;
