import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import { TableSelector } from '@mabinso-registry/module-commons';
import React from 'react';
import ReactDOM from 'react-dom';
import html from '../../templates/edit_report_saved_view/tableselect_view.template';

const ComboBoxView = Backbone.View.extend({
    initialize(options) {
        this.options = options;
        this.render();
    },
    render() {
        const view = this;
        view.$el.spinner('on', { view });
        view.options.property.fetchPropertyValues({
            success() {
                view.$el.html(_.template(html)({ $, property: view.options.property }));
                view.renderElements(view.options.property);
                if (_.isFunction(view.options.success))view.options.success();
            }
        });
    },
    convertData(property) {
        const data = [];
        _.each(property.get('propertyValues'), item => {
            data.push(item);
        });
        return data;
    },
    convertCols(property) {
        const columns = [];
        const values = property.get('propertyValues');
        _.each(values[0], (elem, key) => {
            columns.push({ Header: key, accessor: key });
        });
        return columns;
    },
    renderElements(property) {
        const view = this;
        let defaultValue = property.get('defaultValue');
        let value = property.get('value');
        if (value) {
            value = value.split(',');
        } else if (defaultValue) {
            defaultValue = defaultValue.split(',');
        }
        ReactDOM.render(
            <TableSelector
                data={this.convertData(property)}
                columns={this.convertCols(property)}
                callback={ids => { view.$el.find('.hiddenInputText').val(ids); }}
                showPageSizeOptions={false}
                defaultPageSize={5}
            />,
            document.getElementsByClassName('tableSelectHolder')[0]
        );
    },
    events: {},
});

export default ComboBoxView;
