import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import ReportJobCollection from '../models/ReportJobCollection';
import ReportJobModel from '../models/ReportJobModel';
import ModalView from '../../app/views/ModalView';
import WaitView from '../../app/views/WaitView';
import ConfirmView from '../../app/views/ConfirmView';
import html from '../templates/plan_report_list_view.template';

const PlanReportListView = Backbone.View.extend({
    initialize() {
        this.reportJobs = new ReportJobCollection();
    },
    reportJobs: null,
    render() {
        const view = this;


        WaitView.show({
            message: $._('report.list.showPlanned.wait'),
            title: $._('alert.wait.title')
        });


        view.reportJobs.fetch({
            data: { report_id: view.model.id },
            success(collection) {
                const compiled = _.template(html)({ $, reports: collection, eventMap: new ReportJobModel().everySelect });
                $.modal.close();
                ModalView.show({
                    title: $._('wizzard.planned.title'),
                    message: compiled,
                    closeString: $._('wizzard.planned.close'),
                    success() {
                        view.setElement('#modalModal');
                    }
                });
            }
        });
    },
    events: {
        'click span.loeschen': 'btDel',
        'click span.bearbeiten': 'btEdit'
    },
    btDel(event) {
        const view = this;
        view.setElement('body');
        const id = $(event.target).parents('tr.row').data('id');
        $.modal.close();
        ConfirmView.show({
            message: $._('wizzard.planned.alert.planned.delete.wait'),
            title: $._('wizzard.planned.alert.planned.delete.wait'),
            yesString: $._('wizzard.planned.delete'),
            yesFunc() {
                const item = view.reportJobs.get(id);
                view.reportJobs.remove(item);
                item.destroy({
                    data: { report_id: view.model.id },
                    success() {
                        view.render();
                    }
                });
            },
            cancelFunc() {
                view.render();
            }
        });
        return false;
    },
    btEdit() {
        // var id=$(event.target).parents("tr").attr('id');
        alert('hier muss der Wizzard mit den entsprechenden Einstellungen geöffnet werden.');
        return false;
    }
});
export default PlanReportListView;
