import $ from 'jquery';
import _ from 'underscore';

/* eslint-disable */
declare global {
    namespace _ {
        interface UnderscoreStatic {
            make: (tagName: string, attributes: any, content?: any) => any;
            renderMultiCheckbox: (inputName: any, selectModels: any, models: any, append: any, selectHTML: any, options: any) => void;
            toName: (key: string) => string;
        }
    }
}
/* eslint-enable */

_.mixin({
    renderMultiCheckbox(inputName: any, selectModels: any, models: any, append: any, selectHTML: any, options: any) {
        const settings: any = {};
        if (options) {
            _.extend(settings, options);
        }
        if (_.isObject(inputName)) {
            // console.log("YES");
            _.extend(settings, inputName);
            ({ inputName, selectModels, models } = settings);
            append = settings.appendEle;
            selectHTML = settings.valueName;
        }
        let lastCatId = 0;
        let lastCatDiv: any = null;
        models.each((model: any, idx: number) => {
            if (_.isUndefined(options) || _.isUndefined(options.check) || (_.isFunction(options.check) && options.check(model))) {
                if (_.isUndefined(selectHTML)) {
                    selectHTML = 'name';
                }
                const div = _.make('div', { class: 'option' });
                let checked: any = '';
                let inName = inputName;
                if (_.isObject(inputName)) {
                    inName = inputName.oname + '[' + idx + '].' + inputName.name;
                }
                const input = _.make('input', {
                    type: 'checkbox',
                    name: inName,
                    id: inName + model.id,
                    value: model.id,
                });
                let spanHtml = model.get(selectHTML);
                if (settings.showIdent) {
                    spanHtml += ' (' + model.get('fileCategory') + '_' + model.get('ident') + '_' + model.get('version') + ')';
                }

                checked = false;
                if (selectModels != null && selectModels.length > 0 && selectModels.get(model.id) != null) {
                    $(input).attr({ checked: 'checked' });
                    checked = true;
                }
                $(div).append(input);
                let appendSpanClass = '';
                if (settings.inputHidden && _.isArray(settings.inputHidden)) {
                    _.each(settings.inputHidden, (item) => {
                        if (_.isObject(inputName)) {
                            inName = inputName.oname + '[' + idx + '].' + item.name;
                        }
                        const moptions: any = { type: 'hidden', name: inName, id: inName + model.id };
                        if (!checked) {
                            moptions.disabled = 'disabled';
                        } else {
                            moptions.value = selectModels.get(model.id).get(item.value);
                            if (moptions.value === 1) {
                                appendSpanClass += 'optRead ';
                            }
                            if (moptions.value === 2) {
                                appendSpanClass += 'optWrite ';
                            }
                        }
                        const input2 = _.make('input', moptions);
                        $(div).append(input2);
                    });
                }
                const span = _.make('span', { class: 'optBeschriftung ' + appendSpanClass }, spanHtml);
                $(div).append(span);
                if (settings.category) {
                    const category = model.get(settings.category);
                    if (lastCatId !== category.id) {
                        lastCatId = category.id;
                        lastCatDiv = _.make('div', { class: 'optionCategory' });
                        const spanCat = _.make('span', { class: 'optBeschriftung' }, category.name);
                        $(lastCatDiv).append(spanCat);
                        $(append).append(lastCatDiv);
                    }
                    $(lastCatDiv).append(div);
                } else {
                    $(append).append(div);
                }
            }
        });
    },
    make(tagName: string, attributes: any, content?: any) {
        const el = document.createElement(tagName);
        if (attributes) $(el).attr(attributes);
        if (content) $(el).html(content);
        return el;
    },
    serializeObject(form: any) {
        const o: any = {};
        const a = $(form).serializeArray();
        $.each(a, function serialize() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    },
    toName(name: string) {
        const theSplit = '#';
        const split = name.split(theSplit);
        if (_.isUndefined(split) || _.isNull(split)) {
            return null;
        }
        if (split.length === 1) {
            return split[0];
        }
        let retName = '';
        _.each(split, (item, index) => {
            if (index > 0) {
                if (index === 1) {
                    retName += item;
                } else {
                    retName += theSplit + item;
                }
            }
        });
        return retName;
    },
});
