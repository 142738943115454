import MabinsoModel from '../../app/models/MabinsoModel';
import Globals from '../../../utils/Globals';

class ReportJobModel extends MabinsoModel {
    urlRoot = Globals.baseUrl + 'reportJobs/job';
    save(success: any) {
        this.set({ start: this.get('start') + ' ' + this.get('time'), baseUrl: Globals.rootUrl });
        return MabinsoModel.prototype.save.call(this, success);
    }
    everySelect = {
        '1o': 'wizzard.plan.form.every.onetime',
        '1m': 'wizzard.plan.form.every.monthly',
        '1w': 'wizzard.plan.form.every.weekly',
        '1d': 'wizzard.plan.form.every.daily',
    };
}
export default ReportJobModel;
