import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import InterfaceFinishedModel from '../models/InterfaceFinishedModel';
import Globals from '../../../utils/Globals';

const InterfacerunView = Backbone.View.extend({
    model: null,
    interfaceView: null,
    isdown: false,
    initialize() {
        this.model = new InterfaceFinishedModel();
        this.poll();
        this.render();
    },
    render() {
    },
    poll() {
        const view = this;
        const showForAll = Globals.serverConfig.datainterface_isRun_showForAll;
        this.model.pollStatus(model => {
            if (!_.isUndefined(model.get('schema')) && (showForAll || model.get('schema').id === Globals.schemaUserModel.id)) {
                if (!_.isUndefined(model.get('isRun')) && model.get('isRun')) {
                    view.rolldown();
                } else {
                    view.rollup();
                }
                if (view.interfaceView !== null) {
                    console.log('The Model from Poll:', model);
                    const { interfaceView } = view;
                    const category = interfaceView.collection.find(fm => fm.get('name') === model.get('category_id'));
                    if (category !== null) {
                        const minterface = category.view.collection.get(model.get('interface_id'));
                        if (minterface !== null) {
                            if (!_.isUndefined(minterface.view.collection)) {
                                const oldRs = minterface.view.collection.get(model.id);
                                if (!_.isUndefined(oldRs)) {
                                    oldRs.set(model.attributes);
                                } else {
                                    minterface.view.collection.add(model, { at: 0 });
                                }
                            }
                        }
                    }
                }
            }
        });
    },
    rollup() {
        const view = this;
        if (view.isdown) {
            view.isdown = false;
            const irf = $('#InterfaceRunningView');
            irf.find('.zahnrad').pnganimation('stop', { left: 0, top: 0 });
            irf.animate(
                { 'margin-top': '-21px' },
                300,
                () => {
                    /* var alert_view = new AlertView({
                        title : $._('interfacerun.finished.alert.title'),
                        message : $._('interfacerun.finished.alert.message')
                    }); */
                }
            );
        }
    },
    rolldown() {
        const view = this;
        if (view.isdown !== true) {
            view.isdown = true;
            const irf = $('#InterfaceRunningView');
            irf.animate(
                { 'margin-top': 0 },
                300,
                () => {
                    irf.find('.zahnrad').pnganimation('init', { time: 50 });
                    view.delegateEvents(view.events);
                }
            );
        }
    }
});
export default InterfacerunView;
