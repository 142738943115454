import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import SchemaCollection from '../models/SchemaCollection';
import DetailView from './DetailView';
import html from '../templates/list_view.template';

const SchemaView = Backbone.View.extend({
    initialize(options) {
        $('body').scrollTo(0, 0);
        this.options = options || {};
    },
    render() {
        const collection = new SchemaCollection();
        const view = this;
        view.$el.spinner();
        collection.fetch({
            success(collection2) {
                view.$el.html(_.template(html)({ models: collection2, $ }));
                view.options.detailView = new DetailView({ listView: view });
                $('.spalte_links_280 li').uiAktivAnzeiger('init');
            }
        });
        collection.sortByField('name', 'asc');
    },
    events: {
        'click .schemaliste li': 'showDetail'
    },

    showDetail(event) {
        const id = $(event.target).closest('li').data('id');
        this.options.detailView.render(id);
    }
});
export default SchemaView;
