import React from 'react';
import Bundle from '../../utils/Bundle';

const loadMod = mod => {
    import('./Multiedit').then(mod);
};

const ModBundle = props => (
    <Bundle load={loadMod}>
        {Mod => <Mod {...props} />}
    </Bundle>
);

export default ModBundle;
