import _ from 'underscore';
import { Globals, Ajax } from '@mabinso-registry/module-commons';
import MabinsoModel from '../../app/models/MabinsoModel';
import ReportPropertyCollection from './ReportPropertyCollection';
import FormMessage from '../../../utils/FormMessage';

export interface IReportModel {
    name: string;
}

class ReportModel extends MabinsoModel implements IReportModel {
    urlRoot = Globals.baseUrl + 'reports/report';
    urlUpload = () => Globals.baseUrl + 'reports/uploadReport';
    defaults = () => ({
        name: '',
    });

    set name(value: string) {
        this.set('name', value);
    }
    get name(): string {
        const name = this.get('name');
        return _.toName(name);
    }
    getName() {
        return this.name;
    }
    uploadReport(options: any) {
        const model = this;
        if (model.form != null) {
            if (_.isUndefined(model.validate())) {
                console.log(this.urlUpload());
                model.form.attr('action', this.urlUpload());
                model.form.ajaxForm({
                    url: this.urlUpload(),
                    data: options.data,
                    dataType: 'json',
                    success(data: any) {
                        console.log('success');
                        const formMessage = new FormMessage(data);
                        if (_.isFunction(options.success)) {
                            options.success(formMessage);
                        }
                    },
                });
                model.form.submit();
            }
        }
    }
    async fetchProperties(options?: any): Promise<ReportPropertyCollection> {
        const model = this;
        if (options == null) {
            options = {};
        }
        const properties = new ReportPropertyCollection();
        if (!_.isUndefined(options.url)) {
            properties.url = Globals.baseUrl + options.url;
        }
        await properties.fetchAsync({
            data: _.isUndefined(options.data) ? { report_id: model.id } : options.data,
        });
        model.set('properties', properties);
        if (_.isFunction(options.success)) {
            options.success(properties);
        }
        return properties;
    }
    static deleteItems(options: any) {
        console.log(options.items);
        new Ajax(undefined)
            .post({
                url: Globals.baseUrl + 'reports/deleteItems',
                json: { ids: options.items },
            })
            .then((data) => {
                if (_.isFunction(options.success)) {
                    options.success(data);
                }
            });
    }
}
export default ReportModel;
