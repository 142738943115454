import $ from 'jquery';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { match } from 'react-router';
import { History, Location } from 'history';
import { Document } from '@mabinso-registry/module-commons';
import ReportSavedModel from 'components/report/models/ReportSavedModel';
import Globals from '../../utils/Globals';
import ReportListView from './views/ReportListView';
import Detail from './components/detail/Detail';
// import DocumentComponent from '../document/Document';
import NewReport from './components/newReport/NewReport';
import ReportCategoryView from './views/ReportCategoryView';

type LState = {
    modelValues: Object,
    reportId: string | number
}

type ReportProps = {
    spinner: any,
    history: History,
    location: Location<LState>,
    match: match,
    enableHelp: any
}

class Report extends React.Component<ReportProps> {
    constructor(props: ReportProps) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.actview = new ReportListView({ history: this.props.history, enableHelp: this.props.enableHelp });
        $('#report_component').html(this.actview.el);
        this.actview.render();
        this.props.spinner(false);
        /* setTimeout(() => {
            const location = {
                pathname: '/index.reports',
                state: {
                    modelValues: { id: 222, description: 'hallo' },
                    reportId: 166
                }
            };
            this.props.history.replace(location);
        }, 2000); */
    }

    componentWillReceiveProps(props: ReportProps) {
        const { state } = props.location;
        console.log(props, this.actview.reportCategoryViews);
        if (state && state.modelValues && state.reportId) {
            this.addNewReportSaved(state.reportId, state.modelValues);
        }
        Globals.nextPath = this.props.location as any;
    }

    actview: any

    addNewReportSaved(reportId: string | number, reportSavedValues: Object) {
        this.actview.reportCategoryViews.forEach((reportCategoryView: ReportCategoryView) => {
            reportCategoryView.reportViews.forEach((reportView: any) => {
                const reportModel = reportView.model;
                if (reportModel.id === reportId) {
                    const reportSavedModel = new ReportSavedModel(reportSavedValues);
                    const reportSavedView = reportView.addReportSavedView(new ReportSavedModel(reportSavedValues), 'after');
                    reportCategoryView.triggerReports(true);
                    reportView.triggerReportsSaved(true);
                    if (reportSavedModel.get('finished') != null && reportSavedModel.get('fail') === false && reportSavedModel.get('pages') > 0) {
                        reportSavedView.triggerReport(reportSavedModel.get('showDefault'));
                    } else if (reportSavedModel.get('finished') == null) {
                        console.log('Finished');
                    }
                }
            });
        });
    }

    render() {
        return (
            <div id="report_component_wrapper">
                <div id="report_component" />
                <Switch>
                    <Route
                        path={this.props.match.url + '/newReport/:reportId'}
                        render={(props:any) => (
                            <NewReport {...props} />
                        )}
                    />
                    <Route
                        path={this.props.match.url + '/:reportSavedId'}
                        render={(props: any) => (
                            <div id="report_detail">
                                <Document closeLink="/index.reports" enableHelp={props.enableHelp} {...props} >
                                    <Detail {...props} />
                                </Document>
                            </div>
                        )}
                    />
                </Switch>
            </div>
        );
    }
}

export default Report;
