import _ from 'underscore';
import MabinsoModel from '../../app/models/MabinsoModel';
import Globals from '../../../utils/Globals';

class ProtocolModel extends MabinsoModel {
    urlRoot = Globals.baseUrl + 'protocols/protocol';
    getAParameter(count: any) {
        const model = this;
        if (_.isUndefined(model.get('sParameter'))) {
            if (_.isUndefined(model.get('parameter')) || model.get('parameter') == null) {
                return null;
            }
            const splited2 = model.get('parameter').split(';');
            model.set('sParameter', splited2);
        }
        const splited = model.get('sParameter');
        if (_.isUndefined(splited[count])) {
            return null;
        }
        return splited[count];
    }
}
export default ProtocolModel;
