import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import JobCollection from '../models/InterfaceJobCollection';
import JobModel from '../models/InterfaceJobModel';
import ModalView from '../../app/views/ModalView';
import WaitView from '../../app/views/WaitView';
import ConfirmView from '../../app/views/ConfirmView';
import html from '../../report/templates/plan_report_list_view.template';

const PlanReportListView = Backbone.View.extend({
    initialize(options) {
        this.collection = new JobCollection();
        this.options = options || [];
    },
    render() {
        const view = this;
        const etlName = _.isUndefined(view.options.name) ? '' : ': ' + view.options.name;

        WaitView.show({
            message: $._('interface.list.showPlanned.wait'),
            title: $._('alert.wait.title')
        });


        view.collection.fetch({
            data: { interface_id: view.model.id },
            success(collection) {
                const compiled = _.template(html)({ reports: collection, eventMap: new JobModel().everySelect, $ });
                $.modal.close();
                ModalView.show({
                    title: $._('interface.planned.title') + etlName,
                    message: compiled,
                    closeString: $._('interface.planned.close'),
                    success() {
                        view.setElement('#modalModal');
                    }
                });
            }
        });
    },
    events: {
        'click span.loeschen': 'btDel',
        'click span.bearbeiten': 'btEdit'
    },
    btDel(event) {
        const view = this;
        view.setElement('body');
        const id = $(event.target).parents('tr.row').data('id');
        $.modal.close();
        ConfirmView.show({
            message: $._('interface.planned.alert.planned.delete.wait'),
            title: $._('interface.planned.alert.planned.delete.wait'),
            yesString: $._('interface.planned.delete'),
            yesFunc() {
                const item = view.collection.get(id);
                view.collection.remove(item);
                item.destroy({
                    data: { interface_id: view.model.id },
                    success() {
                        view.render();
                    }
                });
            },
            cancelFunc() {
                view.render();
            }
        });
        return false;
    },
    btEdit() {
        alert('hier muss der Wizzard mit den entsprechenden Einstellungen geöffnet werden.');
        return false;
    }
});
export default PlanReportListView;
