import { ChangeEvent } from 'react';
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import { History } from 'history';
import ReportCollection from '../models/ReportCollection';
import ReportModel from '../models/ReportModel';
import ReportCategoryModel from '../models/ReportCategoryModel';
import ConfirmView from '../../app/views/ConfirmView';
import ReportView from './ReportView';
import EditReportView from './EditReportView';
import EditCategoryView from './EditReportCategoryView';
import html from '../templates/report_category_view.template';
import Globals from '../../../utils/Globals';

interface BackboneView extends Backbone.View {
    options: any;
    onlyone: Function;
}

interface ViewOptions extends Backbone.ViewOptions<ReportCategoryModel> {
    afterRender: Function;
    enableHelp: Function;
    editReportView?: BackboneView;
    reportListView: BackboneView;
    history: History;
}

class ReportCategoryView extends Backbone.View<ReportCategoryModel> {
    constructor(options: ViewOptions) {
        super(options);
        this.reportViews = [];
        this.options = options;
    }
    options: ViewOptions;
    reportViews: any;
    render() {
        const report = new ReportCollection();
        const view = this;
        const tstring = _.template(html);
        view.$el.html(tstring({ reportcategory: view.model, $, Actions: Globals.actions }));
        view.$el.find('.waitreport').pnganimation('init', { time: 50 });
        view.renderTemplateSuccess();
        report.fetch({
            data: { report_category_id: view.model.id },
            success(collection: any) {
                if (collection.length > 0) {
                    view.setReportsCount(collection.length);
                    view.categoryHasReports(true);
                    collection.eachAsync((model: any, callback: any) => {
                        view.addReportView(model, () => {
                            callback();
                        });
                    }, view.options.afterRender);
                    view.collection = collection;
                } else {
                    view.categoryHasReports(false);
                    view.options.afterRender();
                }
            },
        });
        report.sortByField('name', 'asc');
        return view;
    }
    addReportView(model: any, callback: any) {
        const view = this;
        const el = _.make('div', {
            id: 'reportvorlage' + model.id,
            'data-id': model.id,
            class: 'reportvorlage',
        });
        view.$('.reportvorlagensammlung').append(el);
        const reportView = new ReportView({
            el,
            model,
            reportCategoryView: view,
            history: view.options.history,
            afterRender: callback,
            enableHelp: view.options.enableHelp,
        });
        model.view = reportView;
        view.reportViews.push(reportView);
        reportView.render();
    }
    renderTemplateSuccess() {}
    addReportsSavedCount(count: number, countNew: number) {
        const oldCounts = this.getReportsSavedCount();
        count = oldCounts.count + count;
        countNew = oldCounts.countNew + countNew;
        this.setReportsSavedCount(count, countNew);
        if (_.size(this.reportViews) > 0) {
            this.categoryHasReports(true);
        } else {
            this.categoryHasReports(false);
        }
    }
    getReportsSavedCount() {
        return {
            count: parseInt(this.$('.reportsSaved-count').html(), 10),
            countNew: parseInt(this.$('.reportsSavedNew-count').html(), 10),
        };
    }
    setReportsSavedCount(count: number, countNew: number) {
        this.$('.reportsSaved-count').html(count.toString());
        this.$('.reportsSavedNew-count').html(countNew.toString());
    }
    setReportsCount(count: number) {
        this.$('.reports-count').html(count.toString());
    }
    categoryHasReports(value: boolean) {
        if (value) {
            this.$el.show();
            this.$('.typbeschriftung .waitreport').pnganimation('stop', { left: -915, top: -205 });
            this.$('.typbeschriftung .waitreport').addClass('noreport').removeClass('waitreport');
            this.$('.typbeschriftung').addClass('hasReports');
            // Es gibt mindestens eine Sammlung, die weitere Unterpunkte enthält. Die Mathode zum öffnen, wenn es nur einen gibt wird der aufgeklappt
            this.options.reportListView.onlyone();
        } else {
            // this.$el.hide();
            // this.$('.typbeschriftung').removeClass('hasReports');

            this.$('.typbeschriftung .waitreport').pnganimation('stop', { left: -915, top: -205 });
            this.$('.typbeschriftung .waitreport').removeClass('waitreport').addClass('noreport').addClass('sprites_main');
        }
    }
    triggerReports(open: boolean) {
        const opener = this.$('.typbeschriftung .opentype');
        if (_.isUndefined(open)) {
            opener.trigger('click');
        } else {
            if (open && !opener.hasClass('active')) {
                opener.trigger('click');
            }
            if (!open && opener.hasClass('active')) {
                opener.trigger('click');
            }
        }
    }
    events() {
        return {
            'click .typbeschriftung.hasReports .opentype.active': 'closeReports',
            'click .typbeschriftung.hasReports .opentype:not(.active)': 'openReports',
            'click .deleteCategory': 'deleteCategory',
            'click .editCategory': 'editCategory',
            'click .addReport': 'addReport',
            'click .buttonsammlung_reportzeilen .listSelector': 'toggleSelectReportAllSaved',
            'click .typbeschriftung .listSelector': 'toggleSelectReportAllCategory',
        };
    }
    toggleSelectReportAllSaved(e: ChangeEvent<HTMLInputElement>) {
        const view = this;
        if ($(e.target).hasClass('true')) {
            view.updateSelectStatusReportcategory($(e.target).closest('.reportvorlage'), 'true');
        } else {
            view.updateSelectStatusReportcategory($(e.target).closest('.reportvorlage'), 'false');
        }
        return false;
    }
    toggleSelectReportAllCategory(e: ChangeEvent<HTMLInputElement>) {
        const view = this;
        if ($(e.target).hasClass('true')) {
            view.updateSelectStatusReportcategory($(e.target).closest('.reportcategory'), 'true');
        } else {
            view.updateSelectStatusReportcategory($(e.target).closest('.reportcategory'), 'false');
        }
        return false;
    }
    updateSelectStatusReportcategory(vorlage: any, status: string) {
        if (status === 'true') {
            vorlage.find('.reports .listSelector.true').trigger('click');
        } else {
            vorlage.find('.reports .listSelector.false').trigger('click');
        }
        return false;
    }
    addReport() {
        const view = this;
        if (view.options.editReportView === undefined) {
            view.options.editReportView = new EditReportView({
                reportCategoryView: view,
                reportListView: view.options.reportListView,
            });
        }
        if (view.options.editReportView !== undefined) {
            view.options.editReportView.model = new ReportModel();
            view.options.editReportView.render();
        }
    }
    editCategory() {
        const view = this;
        if (_.isUndefined(view.options.reportListView.options.editReportCategoryView)) {
            view.options.reportListView.options.editReportCategoryView = new EditCategoryView({
                reportListView: view.options.reportListView,
            });
        }
        view.options.reportListView.options.editReportCategoryView.model = view.model;
        view.options.reportListView.options.editReportCategoryView.render();
    }
    deleteCategory() {
        const view = this;
        ConfirmView.show({
            message: $._('report.alert.reportcategory.confirm.delete'),
            title: $._('alert.action.delete'),
            yesString: $._('alert.action.delete'),
            yesFunc() {
                view.model.destroy({
                    success() {
                        view.options.reportListView.render();
                    },
                });
            },
        });
    }
    openReports(evt: ChangeEvent<HTMLInputElement>) {
        // var view = this;
        const root = $(evt.target).closest('.reportcategory');
        $('.typinfo_outer', root).fadeOut('fast', () => {
            $('.reportvorlagensammlung', root).slideDown('fast');
            $('.typbeschriftung', root).addClass('active');
            $(evt.target).addClass('active');
            // eslint-disable-next-line dot-notation
            if (window['scroll']) {
                $.scrollTo($('.typbeschriftung', root).offset()!.top - 170, 200);
            }
        });
    }
    closeReports(evt: ChangeEvent<HTMLInputElement>) {
        const root = $(evt.target).closest('.reportcategory');
        $('.reportvorlagensammlung', root).slideUp('fast', () => {
            $('.typinfo_outer', root).fadeIn('fast');
            $('.typbeschriftung', root).removeClass('active');
            $(evt.target).removeClass('active');
        });
    }
}

export default ReportCategoryView;
