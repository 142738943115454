/* eslint-disable react/no-danger */
import $ from 'jquery';
import { Spinner } from '@mabinso-registry/module-commons';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import React from 'react';
import ReportSavedModel from '../../models/ReportSavedModel';
import Globals from '../../../../utils/Globals';
import './css/style.scss';
import Download from '../../../../utils/Download';

class Detail extends React.Component {
    static init(props, ele) {
        ReactDOM.render(<Detail zoom={props.zoom} id={props.id} />, ele);
    }

    static propTypes = {
        zoom: PropTypes.number,
        page: PropTypes.number,
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        // historyCounter: PropTypes.func,
    };

    static defaultProps = {
        page: 0,
        zoom: 1,
        // historyCounter: () => {},
    };

    constructor(props, defaultProps) {
        super(props, defaultProps);
        const detail = this;
        this.state = {
            zoom: this.props.zoom || '1',
            hideWorkbar: true,
            hideShowAll: false,
            hideShowPages: true,
            hideNextPage: true,
            hidePrevPage: true,
            hideShowPdf: true,
            hideShowXls: true,
            load: true,
            page: this.props.page || 0,
            reportHtml: [],
        };
        this.model = new ReportSavedModel({ id: this.props.match.params.reportSavedId });
        if (this.props.match.params.token) {
            this.model.set('token', this.props.match.params.token);
        }
        this.fetchHtml = this.fetchHtml.bind(this);
        this.model.fetch({
            success() {
                const { format } = detail.props.match.params;
                if (detail.model.get('showHtml')) {
                    detail.fetchHtml(detail.state.page);
                }
                if (detail.model.get('showPdf')) {
                    detail.setState({ hideShowPdf: false });
                    if (format === 'pdf') {
                        detail.embedSavedReport('pdf');
                    }
                }
                if (detail.model.get('showXls')) {
                    detail.setState({ hideShowXls: false });
                    if (format === 'xls') {
                        detail.embedSavedReport('xls');
                    }
                }
            },
        });
    }

    componentWillReceiveProps() {
        Globals.nextPath = this.props.location;
    }

    fetchHtml(page) {
        const detail = this;
        const pages = detail.model.get('pages');
        this.setState({ page, load: true });
        this.model.fetchHtml({
            page,
            pages,
            success(data, i) {
                // view.renderAfterFetch(data);
                if (i === 0) {
                    let { hideNextPage, hidePrevPage, hideShowAll } = detail.state;
                    if (!hideShowAll) {
                        if (page + 1 < pages) {
                            hideNextPage = false;
                        } else {
                            hideNextPage = true;
                        }
                        if (page > 0) {
                            hidePrevPage = false;
                        } else {
                            hidePrevPage = true;
                        }
                    } else {
                        hideNextPage = true;
                        hidePrevPage = true;
                    }
                    if (pages < 2) {
                        hideShowAll = true;
                    }
                    detail.setState({
                        load: false,
                        hideShowAll,
                        hideWorkbar: false,
                        reportHtml: [data],
                        hideNextPage,
                        hidePrevPage,
                    });
                } else {
                    const { reportHtml } = detail.state;
                    reportHtml.push(data);
                    detail.setState({ load: false, reportHtml });
                }
            },
        });
    }

    historyProtocol() {
        // const { historyCount, historyCounter } = this.props.parent;
        // historyCounter(this.props.parent);
    }

    clickNextPage(event) {
        // this.historyProtocol();
        event.preventDefault();
        this.fetchHtml(this.state.page + 1);
    }

    clickPrevPage(event) {
        // this.historyProtocol();
        event.preventDefault();
        this.fetchHtml(this.state.page - 1);
    }

    clickShowAll(event) {
        // this.historyProtocol();
        event.preventDefault();
        this.fetchHtml(-1);
        this.setState({ hideShowAll: true, hideShowPages: false });
    }

    clickShowPage(event) {
        event.preventDefault();
        // this.historyProtocol();
        this.fetchHtml(0);
        this.setState({ hideShowAll: false, hideShowPages: true });
    }

    clickShowPdf(event) {
        event.preventDefault();
        this.embedSavedReport('pdf');
    }

    clickShowXls(event) {
        event.preventDefault();
        this.embedSavedReport('xls');
    }

    changeZoom(event) {
        const { value } = event.target;
        this.setState({ zoom: value });
    }

    embedSavedReport(type) {
        const that = this;
        const url = this.model.urlContent(type);
        that.setState({ load: true });
        Download.do({
            url,
            success() {
                that.setState({ load: false });
            },
        });
    }

    render() {
        const children = [];
        const { load } = this.state;
        const display = (state) => {
            const style = {};
            if (load) {
                style.PointerEvent = 'none';
                style.opacity = '0.5';
            }
            if (state) {
                style.display = 'none';
            }
            return style;
        };
        for (let i = 0; i < this.state.reportHtml.length; i += 1) {
            children.push(<div key={i} dangerouslySetInnerHTML={{ __html: this.state.reportHtml[i] }} />);
        }
        return (
            <div id="content-detail">
                <Spinner mode="l" load={this.state.load} id="Report-Detail-Spinner" />
                <div id="workbar" hidden={this.state.hideWorkbar}>
                    <div id="workbar-header">
                        <div id="pages">
                            <a id="prev-page" href="#prev" onClick={this.clickPrevPage.bind(this)} style={display(this.state.hidePrevPage)} title={$._('reportSaved.showdetail.prevpage')}>
                                {$._('reportSaved.showdetail.prevpage')}
                            </a>
                            <a id="next-page" href="#next" onClick={this.clickNextPage.bind(this)} style={display(this.state.hideNextPage)} title={$._('reportSaved.showdetail.nextpage')}>
                                {$._('reportSaved.showdetail.nextpage')}
                            </a>
                        </div>
                        <div id="showall">
                            <a id="show-all" href="#sall" onClick={this.clickShowAll.bind(this)} style={display(this.state.hideShowAll)} title={$._('reportSaved.showdetail.allpage')}>
                                {$._('reportSaved.showdetail.allpage')}
                            </a>
                            <a id="show-page" href="#spage" onClick={this.clickShowPage.bind(this)} style={display(this.state.hideShowPages)} title={$._('reportSaved.showdetail.pageview')}>
                                {$._('reportSaved.showdetail.pageview')}
                            </a>
                        </div>
                        <div id="export">
                            {$._('reportSaved.showdetail.export')}
                            <a id="show-pdf" onClick={this.clickShowPdf.bind(this)} style={display(this.state.hideShowPdf)} href="#pdf" title={$._('reportSaved.showdetail.pdf.title')}>
                                {$._('reportSaved.showdetail.pdf')}
                            </a>
                            <span className="divider" style={display(this.state.hideShowXls || this.state.hideShowPdf)}>
                                |
                            </span>
                            <a id="show-xls" onClick={this.clickShowXls.bind(this)} style={display(this.state.hideShowXls)} href="#xls" title={$._('reportSaved.showdetail.xls.title')}>
                                {$._('reportSaved.showdetail.' + Globals.serverConfig.jasper_export_excel)}
                            </a>
                        </div>
                        <div id="zoom" style={{ float: 'left' }}>
                            &nbsp; Zoom
                            <select value={this.state.zoom} onChange={this.changeZoom.bind(this)}>
                                <option value="0.5">50%</option>
                                <option value="0.75">75%</option>
                                <option value="1">100%</option>
                                <option value="1.25">125%</option>
                                <option value="1.5">150%</option>
                                <option value="1.75">175%</option>
                                <option value="2">200%</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div
                    id="report"
                    style={{
                        zoom: this.state.zoom,
                        transformOrigin: '0px 0px 0px',
                        msZoom: this.state.zoom,
                        MozTransform: 'scale(' + this.state.zoom + ')',
                    }}
                >
                    {children}
                </div>
            </div>
        );
    }
}

export default Detail;
