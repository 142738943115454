import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import { Ajax } from '@mabinso-registry/module-commons';
import InterfaceModel from '../models/InterfaceModel';
import InterfaceCategoryCollection from '../models/InterfaceCategoryCollection';
import InterfaceCollection from '../models/InterfaceCollection';
import CategoryView from './CategoryView';
import AlertView from '../../app/views/AlertView';
import ConfirmView from '../../app/views/ConfirmView';
import WaitView from '../../app/views/WaitView';
import Actionbar from '../../app/components/documentexplorer/actionbar/Actionbar';
import html from '../templates/list_view.template';
import Globals from '../../../utils/Globals';

const InterfaceView = Backbone.View.extend({
    _collectionBinder: undefined,
    initialize() {
        $('body').scrollTo(0, 0);
    },
    render() {
        const view = this;
        view.collection = new InterfaceCategoryCollection();
        view.$el.html(_.template(html)({ $, sessionUser: Globals.user, Actions: Globals.actions }));
        view.$('#reportzeilen').spinner();
        this.$('#datesearchfrom').datepicker({
            changeMonth: true,
            changeYear: true,
            onSelect(dateText) {
                view.$('#datesearchto').datepicker('option', 'minDate', dateText);
                view.$('#report-searchform').validationEngine('updateRule', '#datesearchto', 'past', dateText);
                view.doSearch();
                return true;
            }
        });
        this.$('#datesearchto').datepicker({
            changeMonth: true,
            changeYear: true

        });
        view.$('#fieldsetStatussearch').uiselectfield('init');
        view.collection.fetch({
            success(collection) {
                view.collectionLength = collection.length;
                // console.log("collection get");
                const viewCreator = model => { model.view = new CategoryView({ model, interfaceListView: view }); return model.view; };
                // The managerFactory helps to generate element managers - an el manager creates/removes elements when models are added to a collection
                const elManagerFactory = new Backbone.CollectionBinder.ViewManagerFactory(viewCreator);
                view._collectionBinder = new Backbone.CollectionBinder(elManagerFactory);
                // This is very similar to the ModelBinder.bind() function but the collectionBinder will also create nested element views
                view.$('#reportzeilen').spinner('off');
                view._collectionBinder.bind(collection, $('#reportzeilen'));
                $('#reportzeilen').prepend('<div id="actionbarHolder"></div>');
                const actionbar = new Actionbar();
                const actions = [
                    {
                        className: 'delete', label: $._('report.list.delete'), action: 'click', target: '.report.selected .reportactions .loeschen', confirm: $._('report.list.delete.all.message')
                    },
                    // {className: 'open', label: 'Öffnen', action: "click", target: '.report.selected .reportactions .link-html', confirm: false},
                    {
                        className: 'close', label: $._('report.list.close'), action: 'click', target: '.reportactions .listSelector.true', confirm: false
                    }
                ];
                actionbar.init('actionbarHolder', actions);
            }
        });
        view.collection.sortByField('name', 'asc');
    },
    events: {
        'keyup input[name="search"]': 'searchKey',
        'change #statussearch': 'searchKey',
        'change fieldset.datesearch-from-to input': 'searchKey',
        // 'click button.addReportCategory': 'addCategory',
        'click span#deleteFilterItems': 'deleteFilterItems',
        'click span#clearFilter': 'clearFilters',
        'click span#closeAll': 'closeAll',
        'click #datesearchfrom': 'triggerDatepicker',
        'focus #datesearchfrom': 'triggerDatepicker',
        'click #datesearchto': 'triggerDatepicker',
        'focus #datesearchto': 'triggerDatepicker',
        'click #refreshInterfaces': 'refreshInterfaces'
    },
    async refreshInterfaces() {
        Ajax.getJson('interfaces/refresh');
        AlertView.show({
            title: $._('interface.list.searchbar.refresh.title'),
            message: $._('interface.list.searchbar.refresh.message'),
            okFunc: () => {
                window.location.reload();
            }
        });
    },
    triggerDatepicker(evt) {
        if (evt.type === 'focus') {
            $(evt.target).data('focus', true);
        }
        let pageXfrom = 879;
        const width = 18;
        const pageYfrom = 136;
        const height = 15;
        if (evt.target.id === 'datesearchto') {
            pageXfrom = 1056;
        }
        if ($(evt.target).data('focus') === false && $(evt.target).datepicker('widget').is(':visible') && evt.pageX >= pageXfrom && evt.pageX <= pageXfrom + width && evt.pageY >= pageYfrom && evt.pageY <= pageYfrom + height) {
            this.$(evt.target).datepicker('hide');
        } else {
            this.$(evt.target).datepicker('show');
        }
        if (evt.type === 'click') {
            $(evt.target).data('focus', false);
        }
    },
    clearFilters() {
        const view = this;
        // view.$('#namesearch').attr('value', '');
        view.$('#statussearch').uiselectfield('setValue', '');
        view.$('#datesearchfrom').datepicker('setDate', null);
        view.$('#datesearchto').datepicker('setDate', null);
        view.$('#datesearchto').datepicker('option', 'minDate', null);
        view.$('#report-searchform').validationEngine('updateRule', '#datesearchto', 'past', null);
        view._filteredIds = [];
        view.closeAll();
        view.doSearch(false);
        $.scrollTo(0, 200);
    },
    closeAll() {
        console.log('close all');
        $('.typbeschriftung.hasReports.active button.opentype').trigger('click');
        $('.reporttitel.hasReportsSaved.active button.openvorlage').trigger('click');
        $.scrollTo(0, 200);
    },
    /* addReportCategory:function(){
        var view = this;
        if(_.isUndefined(view.options.editReportCategoryView)){
            view.options.editReportCategoryView = new EditReportCategoryView({
                reportListView:view
            });
        }
        view.options.editReportCategoryView.model = new ReportCategoryModel();
        view.options.editReportCategoryView.render();
    }, */
    onlyone() {
        const view = this;
        if (view.collectionLength === 1) {
            view.$('.typbeschriftung.hasReports .opentype:not(.active)').trigger('click');
        }
    },
    _checkStatus(model) {
        const search = this.$('#statussearch').val();
        if (search === '' || model.hasStatus(search)) {
            return true;
        }
        return false;
    },
    _checkName(model) {
        const search = this.$('input[name="search"]').val();
        const regExp = new RegExp('^' + search, 'i');
        const name = model.get('description');
        if (search === undefined || search === '' || (name !== undefined && name.search(regExp) !== -1)) {
            return true;
        }
        return false;
    },
    _checkDate(model) {
        let dateFrom = this.$('#datesearchfrom').val();
        let dateTo = this.$('#datesearchto').val();
        let regString = '';
        let i = 0;
        if (dateFrom !== '' && dateFrom.length < 3) {
            const date = Date.today();
            for (i = 0; i < dateFrom; i += 1) {
                if (i !== 0) {
                    regString += '|';
                }
                regString += '^' + date.toString(Date.CultureInfo.formatPatterns.shortDate);
                date.addDays(-1);
            }
        } else if (dateFrom !== '' && dateTo !== '') {
            dateFrom = Date.parse(dateFrom);
            dateTo = Date.parse(dateTo);
            if (dateFrom.compareTo(dateTo) === -1) {
                for (i = 0; dateFrom.compareTo(dateTo) !== 1; i += 1) {
                    if (i !== 0) {
                        regString += '|';
                    }
                    regString += '^' + dateFrom.toString(Date.CultureInfo.formatPatterns.shortDate);
                    dateFrom.addDays(1);
                }
            } else {
                regString = this.$('#datesearchfrom').val();
            }
        } else if (dateFrom !== '') {
            regString = dateFrom;
        } else if (dateTo !== '') {
            regString = dateTo;
        }
        const regExp = new RegExp('^' + regString, 'i');
        const name = model.get('startTime');
        if (regString === '' || name.search(regExp) !== -1) {
            return true;
        }
        return false;
    },
    _checkFields(model) {
        const that = this;
        if (that._checkStatus(model) && that._checkName(model) && that._checkDate(model)) {
            return true;
        }
        return false;
    },
    _filteredIds: [],
    searchKey() {
        this.doSearch(true);
    },
    doSearch(open) {
        const that = this;
        if (that.$('#report-searchform').validationEngine('validate')) {
            that._filteredIds = [];
            this.collection.each(categoryModel => {
                let hasResultsTyp = false;
                categoryModel.view.collection.each(interfaceModel => {
                    let hasResults = false;
                    interfaceModel.view.collection.each(finishedModel => {
                        finishedModel.view.hideForSearch(false);
                        if (that._checkFields(finishedModel)) {
                            that._filteredIds.push(finishedModel.id);
                            hasResults = true;
                        } else {
                            finishedModel.view.hideForSearch(true);
                        }
                    });
                    if (hasResults) {
                        hasResultsTyp = true;
                        categoryModel.view.triggerCategory(true);
                        if (open) {
                            interfaceModel.view.open();
                        }
                        interfaceModel.view.hasFinished(true);
                        // interfaceModel.view.hideReportDescription(false);
                    } else {
                        // interfaceModel.view.hideReportDescription(true);
                        interfaceModel.view.hasFinished(false);
                        interfaceModel.view.close();
                    }
                });
                if (hasResultsTyp) {
                    categoryModel.view.hasInterface(true);
                    if (open) {
                        categoryModel.view.triggerCategory(true);
                    }
                } else {
                    categoryModel.view.hasInterface(false);
                    categoryModel.view.triggerCategory(false);
                }
            });
        }
        $.scrollTo(0, 200);
    },
    deleteFilterItems() {
        const that = this;
        if (that._filteredIds == null || _.size(that._filteredIds) === 0) {
            AlertView.show({ title: $._('interface.deleteFilterItems.noitems.title'), message: $._('interface.deleteFilterItems.noitems.message') });
        } else {
            ConfirmView.show({
                title: $._('interface.deleteFilterItems.delete.title'),
                message: $._('interface.deleteFilterItems.delete.message', _.size(that._filteredIds)),
                yesFunc() {
                    const waitView = new WaitView({
                        message: $._('interface.deleteFilterItems.delete.wait')
                    });
                    InterfaceModel.deleteItems({
                        items: that._filteredIds,
                        success() {
                            waitView.remove();
                            AlertView.show({
                                message: $._('interface.deleteFilterItems.delete.success'),
                                okFunc() {
                                    that.render();
                                }
                            });
                        }
                    });
                }
            });
        }
    },
    remove() {
        this.$el.remove();
    }
}, {
    getForRole(view, appendEl) {
        const interfaces = new InterfaceCollection();
        const url = Globals.baseUrl + 'interfaces/listRoles';
        interfaces.url = url;
        const roleInterfaces = new InterfaceCollection();
        roleInterfaces.url = url;
        const roleId = view.model.id;
        let rI = 0;
        if (!roleId) {
            rI = 1;
        }
        const div = $(_.make('div', { class: 'input checkbox listed searchable' }));
        div.append(_.make('label', {}, $._('role.form.interface')));
        const innerDiv = _.make('div', { class: 'checkboxGroup olapCheckbox' });
        div.append(innerDiv);
        appendEl.append(div);
        const renderCheckbox = () => {
            if (rI >= 1) {
                _.renderMultiCheckbox({
                    inputName: 'interface[].id',
                    selectModels: roleInterfaces,
                    models: interfaces,
                    appendEle: $(innerDiv),
                    // category:"olapCubeCategory",
                    valueName: 'name'
                });
                $(innerDiv).parent().uicheckbox('init');
            } else {
                rI += 1;
            }
        };
        interfaces.fetch({
            success() {
                renderCheckbox();
            }
        });
        interfaces.sortByField('name', 'asc');
        if (roleId) {
            roleInterfaces.fetch({
                data: { roleId },
                success() {
                    renderCheckbox();
                }
            });
        }
    },
    saveToRole(model, options) {
        const ids = [];
        _.each(model.get('interface'), item => {
            ids.push(item.id);
        });
        InterfaceCollection.updateRole(model.id, ids, options);
    }
});
export default InterfaceView;
