import Backbone from 'backbone';
import _ from 'underscore';
import async from 'async';
import { Ajax } from '@mabinso-registry/module-commons';
import Model from './MabinsoModel';

const getUrl = (object) => {
    if (!(object && object.url)) return null;
    return _.isFunction(object.url) ? object.url() : object.url;
};

const urlError = () => {
    throw new Error('A "url" property or function must be specified');
};

class MabinsoCollection<TModel extends Model = Model> extends Backbone.Collection<TModel> {
    eachAsync(fn, done, aLimit) {
        const myLimit = aLimit || 10;
        async.eachLimit(this.models, myLimit, fn, done);
    }
    eachAsync2(fn, maxTimePerChunk, cxt) {
        const context = cxt || window;
        maxTimePerChunk = maxTimePerChunk || 200;
        const _array = this.models || [];
        let index = 0;

        function now() {
            return new Date().getTime();
        }

        function doChunk() {
            const startTime = now();
            while (index < _array.length && now() - startTime <= maxTimePerChunk) {
                // callback called with args (value, index, array)
                fn.call(context, _array[index], index, _array);
                index += 1;
            }
            if (index < _array.length) {
                // set Timeout for async iteration
                setTimeout(doChunk, 1);
            }
        }
        doChunk();
    }
    async fetchAsync(options?: Backbone.CollectionFetchOptions | undefined): Promise<this> {
        if (options == null) {
            options = {};
        }
        let url = getUrl(this) || urlError();
        if (options.data) {
            url += '?' + new URLSearchParams(options.data).toString();
        }
        const ajax = await new Ajax({ baseUrl: '' }).get({
            url,
        });
        const data = await ajax.json();
        const method = options.reset ? 'reset' : 'set';
        this[method](data, options);
        // if (success) success.call(options.context, collection, resp, options);
        this.trigger('sync', this, data, options);
        return this;
    }
}
export default MabinsoCollection;
