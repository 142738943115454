import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import UploadView from '../../app/views/UploadView';
import Globals from '../../../utils/Globals';

const MyUploadView = Backbone.View.extend({
    initialize(options) {
        this.options = options || [];
        this.urlUpload = Globals.baseUrl + 'interfaces/upload';
    },
    render() {
        const view = this;
        const { model } = view;
        view.uploadView = new UploadView({
            title: $._('interface.upload.title'),
            yesString: $._('interface.upload.yes'),
            multiupload: false,
            formData: { id: model.id },
            url: this.urlUpload,
            yesFunc() {
                view.setElement($('.modalMessage'));
                view.uploadReport();
            }
        });
    },
    uploadReport() {
        const view = this;
        const { model } = view;
        model.setForm(view.$('form'));
        if (model.form != null) {
            if (_.isUndefined(model.validate())) {
                model.form.attr('action', view.urlUpload);
                model.form.ajaxForm({
                    url: view.urlUpload,
                    data: { id: model.id },
                    dataType: 'json',
                    success() {
                        console.log('success');
                        // new FormMessage(data);
                        view.uploadView.remove();
                        if (_.isFunction(view.options.success)) {
                            view.options.success();
                        }
                    }
                });
                model.form.submit();
            }
        }
    }
});
export default MyUploadView;
