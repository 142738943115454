import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Report from './report/Report';
import User from './user/User';
import Role from './role/Role';
import Interface from './interface/Interface';
import Action from './action/Action';
import Protocol from './protocol/Protocol';
import Schema from './schema/Schema';

const AppRoutes = ({ match, spinner, enableHelp }) =>
    (
        <div>
            <Route path={match.url + 'index.reports'} render={props => <Report {...props} spinner={spinner} enableHelp={enableHelp} />} />
            <Route path="/index.users" render={props => <User {...props} spinner={spinner} />} />
            <Route path="/index.roles" render={props => <Role {...props} spinner={spinner} />} />
            <Route path="/index.interfaces" render={props => <Interface {...props} spinner={spinner} />} />
            <Route path="/index.actions" render={props => <Action {...props} spinner={spinner} />} />
            <Route path="/index.logs" render={props => <Protocol {...props} spinner={spinner} />} />
            <Route path="/index.schemas" render={props => <Schema {...props} spinner={spinner} />} />
        </div>
    );

AppRoutes.propTypes = {
    match: PropTypes.object.isRequired,
    spinner: PropTypes.func.isRequired,
    enableHelp: PropTypes.func.isRequired
};


export default AppRoutes;
