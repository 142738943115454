/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-find-dom-node */
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import createClass from 'create-react-class';

// Daten für Multiselect.

function Multiselect(multiselectName) {
    // Multiselect
    this.multiselect_name = multiselectName;
    const that = this;
    this.Multiselect = createClass({
        getInitialState() {
            this.keyIsPressed = 0;
            return this.props;
        },
        componentDidMount() {
            this.adjustHeight();
            if (this.props.mode === 'cols') {
                this.adjustWidths();
            }
        },
        adjustWidths() {
            const $this = $(ReactDOM.findDOMNode(this));
            const completewidth = $this.width();
            const widthFields = (completewidth - 32) / 2;
            $this.find('.source, .destination').css('width', widthFields);
        },
        adjustHeight() {
            const $this = $(ReactDOM.findDOMNode(this));
            const $optionHolder = $(ReactDOM.findDOMNode(this)).find('.optionHolder');
            // var $optionHolderInner = $(ReactDOM.findDOMNode(this)).find('.optionHolderInner');
            const $option = $this.find('.option:first');
            const heightOptions =
                $option.height() +
                parseInt($option.css('padding-top'), 10) +
                parseInt($option.css('padding-bottom'), 10) +
                parseInt($option.css('margin-bottom'), 10);
            const heightNew = heightOptions * (parseInt(this.props.height, 10) + 0.5);
            $optionHolder.css({ height: heightNew });
            if (this.props.mode === 'cols') {
                const $actionBar = $this.find('.actionBar');
                const heightActionBar = $actionBar.height();
                const topActionBar = (heightNew - heightActionBar) / 2;
                $actionBar.css({ 'margin-top': topActionBar });
            }
        },
        range(i, markermode) {
            const newData = this.state.data;
            let start = 0;
            let end = 0;
            let newState = false;
            let newStatePre = false;
            if (typeof (this.lastItemPressed) === 'undefined') {
                this.lastItemPressed = 0;
                newState = true;
            }
            if (this.lastItemPressed < i) {
                start = this.lastItemPressed;
                end = i;
            } else {
                start = i;
                end = this.lastItemPressed;
            }
            if (newData[this.lastItemPressed].checked === false && typeof (newState) === 'undefined') {
                newState = false;
            } else {
                newState = true;
            }
            if (newData[this.lastItemPressed].preselected === false && typeof (newStatePre) === 'undefined') {
                newStatePre = false;
            } else {
                newStatePre = true;
            }
            for (let u = start; u <= end; u += 1) {
                if (newData[u].visible !== false) {
                    switch (markermode) {
                        case 'pre':
                            if (newData[u].checked === newData[start].checked) {
                                newData[u].preselected = newStatePre;
                            }
                            break;
                        case 'check':
                            newData[u].checked = newState;
                            break;
                        default:
                            break;
                    }
                }
            }
            this.setState({ data: newData });
        },
        changeHandler(i, markermode) {
            const newData = this.state.data;
            this.lastItemPressed = parseInt(i, 10);
            if (this.state.data[i].checked === false) {
                switch (markermode) {
                    case 'pre':
                        if (newData[i].preselected === true) {
                            newData[i].preselected = false;
                        } else {
                            newData[i].preselected = true;
                        }
                        break;
                    case 'check':
                        newData[i].checked = true;
                        break;
                    default:
                        break;
                }
            } else {
                switch (markermode) {
                    case 'pre':
                        newData[i].preselected = true;
                        break;
                    case 'check':
                        newData[i].checked = false;
                        break;
                    default:
                }
            }
            this.setState({ data: newData });
        },
        checkPreselected(direction) {
            const newData = this.state.data;
            for (let u = 0; u < newData.length; u += 1) {
                if (newData[u].preselected === true) {
                    if (direction === 'rtl') {
                        newData[u].checked = false;
                    } else {
                        newData[u].checked = true;
                    }
                    newData[u].preselected = false;
                }
            }
            this.setState({ data: newData });
        },
        selectAll(checked) {
            const newData = this.state.data;
            for (let u = 0; u < newData.length; u += 1) {
                newData[u].checked = checked;
                newData[u].preselected = false;
            }
            this.setState({ data: newData });
        },
        render() {
            const self = this;
            if (this.props.mode === 'cols') {
                return (
                    <div className={'multiselect ' + this.props.mode} id={this.props.name}>
                        <div className="source">
                            <that.MultiselectOptions data={this.state} parent={this} />
                        </div>
                        <that.MultiselectColButtons parent={this} />
                        <div className="destination">
                            <that.MultiselectOptions data={this.state} parent={this} />
                        </div>
                        <div className="clearBoth" />
                    </div>
                );
            }

            return (
                <div className={'multiselect ' + this.props.mode} id={this.props.name} onMouseOut={self.handleMouseOut}>
                    <that.MultiselectOptions data={this.state} parent={this} />
                </div>
            );
        }
    });
    // MultiselectOptions
    this.MultiselectOptions = createClass({
        render() {
            const self = this;
            const optionNodes = this.props.data.data.map((option, i) =>
                (
                    <div role="presentation" className={'option ' + option.checked + ' visible' + option.visible + ' preselected' + option.preselected} key={i} onClick={self.optionChange.bind(self, i)} onMouseOver={self.handleMouseOver} onMouseOut={self.handleMouseOut}>
                        <input type="checkbox" onChange={() => false} checked={option.checked} name={self.props.parent.props.name} value={option.value} />
                        <span className="optBeschriftung" dangerouslySetInnerHTML={{ __html: option.text }} />
                    </div>
                ));
            return (
                <div className="optionHolder">
                    <that.MultiselectSearch parent={this.props.parent} />
                    <div className="optionHolderInner">
                        {optionNodes}
                    </div>
                </div>
            );
        },
        optionChange(i) {
            let markermode = 'pre';
            switch (this.props.parent.props.mode) {
                case 'cols':
                    markermode = 'pre';
                    break;
                default:
                    markermode = 'check';
            }
            switch (this.props.parent.keyIsPressed) {
                case 1:
                    this.props.parent.range(i, markermode);
                    break;
                default:
                    this.props.parent.changeHandler(i, markermode);
            }
        },
        handleMouseOver() {
            window.addEventListener('keydown', this.handleKeyDown);
            window.addEventListener('keyup', this.handleKeyUp);
        },
        handleMouseOut() {
            window.removeEventListener('keydown', this.handleKeyDown);
            window.removeEventListener('keyup', this.handleKeyUp);
        },
        handleKeyDown(e) {
            if (e.keyCode === 16) {
                this.props.parent.keyIsPressed = 1;
            }
        },
        handleKeyUp() {
            this.props.parent.keyIsPressed = 0;
        }
    });

    this.MultiselectColButtons = createClass({
        render() {
            return (
                <div className="actionBar">
                    <button className="ltr" onClick={() => this.props.parent.checkPreselected('ltr')}>&rarr;</button>
                    <button className="alltrue" onClick={() => this.props.parent.selectAll(true)}>&#8608;</button>
                    <button className="allfalse" onClick={() => this.props.parent.selectAll(false)}>&#8606;</button>
                    <button className="rtl" onClick={() => this.props.parent.checkPreselected('rtl')}>&larr;</button>
                </div>
            );
        }
    });

    this.MultiselectSearch = createClass({
        handleKeyup(event) {
            const needle = event.target.value;
            const $this = $(ReactDOM.findDOMNode(this));
            switch (this.props.parent.props.mode) {
                case 'cols':
                    if ($this.parent().parent().hasClass('destination')) {
                        this.searchField(needle, true);
                    }
                    if ($this.parent().parent().hasClass('source')) {
                        this.searchField(needle, false);
                    }
                    if (!$this.parent().parent().hasClass('source') && !$this.parent().parent().hasClass('destination')) {
                        this.searchField(needle);
                    }
                    break;
                default:
                    this.searchField(needle);
            }
        },
        searchField(needle, checked = null) {
            const myregex = new RegExp(needle, 'i');
            const newData = this.props.parent.state.data;
            for (let i = 0; i < newData.length; i += 1) {
                if (newData[i].text.search(myregex) !== -1) {
                    switch (checked) {
                        case false:
                            if (newData[i].checked === checked) {
                                newData[i].visible = true;
                            }
                            break;
                        case true:
                            if (newData[i].checked === checked) {
                                newData[i].visible = true;
                            }
                            break;
                        default:
                            newData[i].visible = true;
                    }
                } else {
                    switch (checked) {
                        case false:
                            if (newData[i].checked === checked) {
                                newData[i].visible = false;
                            }
                            break;
                        case true:
                            if (newData[i].checked === checked) {
                                newData[i].visible = false;
                            }
                            break;
                        default:
                            newData[i].visible = false;
                    }
                }
            }
            this.props.parent.setState({ data: newData });
        },
        handleClick(event) {
            $(event.target).parent().toggleClass('active');
            $(event.target).parent().find('input').focus();
            $(event.target).parent().find('input').val('');
            if (this.props.parent.props.mode === 'cols') {
                this.searchField('', $(event.target).parent().parent().parent()
                    .hasClass('destination'));
            } else {
                this.searchField('');
            }
        },
        render() {
            return (
                <div className="searchbar">
                    <input type="text" onKeyUp={this.handleKeyup} />
                    <span role="presentation" className="opener" onClick={this.handleClick} />
                </div>
            );
        }
    });
}

Multiselect.prototype.init = function init(data, holder, mode, height) {
    if (height === undefined) {
        height = '4';
    }
    ReactDOM.render(
        // eslint-disable-next-line no-undef
        <this.Multiselect data={data} height={height} name={this.multiselect_name} mode={mode} />,
        document.getElementById(holder)
    );
};

export default Multiselect;
