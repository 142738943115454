/* eslint-disable */
define(['jquery'], function ($) {

		var methods = {
			init : function(options) {
				var settings = {
					'callbackFunc'	: function(){return false;}
				};
				if ( options ) {
					$.extend( settings, options );
				}	
						
				// Anzeiger verstecken
				$("#aktivzeiger").append('<div class="spitze"><div class="spitzeinner"></div></div>');
				$("#aktivzeiger").hide();
				
				// bei Klick auf element animieren
				function inViewport($el) {
					const elH = $el.outerHeight();
					const H = $(window).height();
					const r = $el[0].getBoundingClientRect(); const t = r.top; const
						b = r.bottom;
					return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
				}
				var div = $(this).parent().parent();
				var allHeight = $(window).height() - 300;
				var divHeight = inViewport(div);
				div.css('overflow-y', 'auto');
                div.css('overflow-x', 'hidden');
				div.css('height',divHeight);
				div.scroll(() => {
					const selected = div.parent().find('li.active');
					if(selected != null && selected.offset() != null ){
						var vonoben=selected.offset()['top']-1;
						if((divHeight + 60) < vonoben){
							$("#aktivzeiger").hide();
						} else {
							$('#aktivzeiger').css('top', vonoben+'px');
							$("#aktivzeiger").show();
						}					
						
					}
				})
				$(this).click(function(){
					var domNode = $(this);
					$("#aktivzeiger").show();
					// aktiven Status verbergen
					$(this).parent().find('li').removeClass('active');
					
					// geklickten aktiv setzen
					$(this).addClass('active');
	
				
					var vonoben=$(this).offset()['top']-1;
	
					if($("#aktivzeiger").css('top')=='0px'){
						$("#aktivzeiger").css('top', vonoben+'px');
						$("#aktivzeiger").fadeIn('fast');
					}
					
					$('#aktivzeiger').animate(
						{top: vonoben+'px'},
						200,
						function() {
					    	// Animation complete.
					    	settings.callbackFunc(domNode);
						}
					);
				});
				
							
			}
		};	
		$.fn.uiAktivAnzeiger = function(method){					
			// Method calling logic
			if ( methods[method] ) {
				return methods[ method ].apply( this, Array.prototype.slice.call( arguments, 1 ));
			}
			else if ( typeof method === 'object' || ! method ) {
				return methods.init.apply( this, arguments );
			}
			else {
				$.error( 'Method ' +  method + ' does not exist on jMaBinso.uiAktivAnzeiger' );
			}  		
		}
});