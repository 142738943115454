/* eslint-disable */
/* Baut Checkboxen, multiselectable und durchsuchbar bei bedarf */
define(['jquery'], function ($) {

		var methods = {
			init : function(options) {
				var settings = {
					'foo'	: 'bar'
				};
				if ( options ) {
					$.extend( settings, options );
				}
				// Wenn durchsuchbare Liste
				var searchable=$(this).filter('.input.checkbox.listed.searchable');
				if (searchable.length == 0){
					searchable=$(this).find('.input.checkbox.listed.searchable');
				};
				$.each(searchable, function(index, value) {
					$(this).find('.checkboxGroup').prepend('<div class="searchbar"><input type="text" /><span class="opener sprites_main"></span></div>');
					
					$(this).find('.searchbar .opener').click(
  					function(){
							if(!$(this).hasClass('active')){
							  	$(this).addClass('active');
                				$(this).parent().find('input').uicheckbox("keysearch");
                				$(this).parent().animate(
								  {'margin-top': '0'},
                  300,
                  function() {
							    	// Animation complete.
								  }
                );
              }
              else{
                $(this).removeClass('active');
								$(this).parent().find('input').attr('value', '');
								$(this).parent().parent().find('.option').show();
								$(this).parent().animate(
									{'margin-top': -24},
									300,
									function() {
							    		// Animation complete.
							    }
							   );
              }
						}
				  );
				});
				
				var selber=$(this);
				
				
				// Checkt ob die Schift-Taste gedrückt ist und speichert es. Außerdem setzte es letztes geklicktes Element auf false
				selber.data('shiftdown', '0');
				selber.data('lastClickedOption', false); 				
				$('body').keydown(function(event){
				  if(event.keyCode==16){
  				 selber.data('shiftdown', '1'); 
				  }				  
				});
				$('body').keyup(function(eventa){
  				 selber.data('shiftdown', '0'); 
				});
				
				
				
				$.each($(this).find(".option"), function(index, value) {
					var optionBeschriftung=$(this).find('.optBeschriftung').html();
					var optionValue=$(this).find('.optBeschriftung').html();
					
					$(this).attr('title', optionValue);
					$(this).click(function(){
					
					  // Shift-Click-Funktion
					  if(selber.data('shiftdown')!=1){
                        selber.data('lastClickedOption', $(this));
					  }
					  if(selber.data('shiftdown')==1 && selber.data('lastClickedOption')!=false){
					    
					    if(selber.data('lastClickedOption').parent().html()==$(this).parent().html()){
  					    
                var erstergeklickt=$(".checkboxGroup .option").index(selber.data('lastClickedOption'));
                var zweitergeklickt=$(".checkboxGroup .option").index($(this));
                
                selber.data('shiftdown', '0');
                if(erstergeklickt<zweitergeklickt){
                  for(i=erstergeklickt+1; i<zweitergeklickt; i++){
                    $(".checkboxGroup .option:eq("+i+")").trigger('click');
                  }
                }
                if(erstergeklickt>zweitergeklickt){
                  for(i=zweitergeklickt+1; i<erstergeklickt; i++){
                    $(".checkboxGroup .option:eq("+i+")").trigger('click');
                  }
                }
					    }
					  }
					  // Ende Shift-Click-Funktion
					  
					
						if(!$(this).hasClass('active')){
							// Wenn nur eines auswählbar
							if($(this).closest('.checkbox').hasClass('single')){
								$(this).closest('.checkbox').find('.active').find('input').removeAttr('checked');
								$(this).closest('.checkbox').find('.active').removeClass('active');
							}
							$(this).addClass('active');

                            var cinput = $(this).find('input:first');
							cinput.attr('checked', 'checked');
                            cinput.next().removeAttr("disabled");
                            if($(this).closest('.checkboxGroup').hasClass('checkboxRW')) {
                                $(this).find('.optBeschriftung').addClass('optRead');
                                cinput.next().val(1);
                            }
						}
						else{
							if(!$(this).closest('.checkbox').hasClass('notuncheck')){
                                var optBesch = $(this).find('.optBeschriftung');
                                optBesch.removeClass('optRead');
                                var cinput = $(this).find('input:first');
                                if(cinput.next().val()=="1"){
                                    optBesch.addClass('optWrite');
                                    cinput.next().val(2);
                                }else {
                                    if(cinput.next().val()){
                                        cinput.next().val(0);
                                        cinput.next().attr("disabled","disabled");
                                    };
                                    $(this).removeClass('active');
                                    optBesch.removeClass('optWrite');
                                    $(this).find('input').removeAttr('checked');
                                }
							}
						}
					});
					if($(this).find('input').attr('checked')=='checked'){
						$(this).addClass('active');
					}
				});														
			},
			keysearch : function(options) {
				var settings = {
					'foo'	: 'bar'
				};
				if ( options ) {
					$.extend( settings, options );
				}
								
				$(this).focus();
				$(this).data('phrase', '');
				
				$(this).unbind('keyup');
				$(this).keyup(function(event){
					$(this).show();
					var suchstring=$(event.target).val();
					$(this).parent().parent().find('.option:not(:icontains(' + suchstring + '))').hide();
					$(this).parent().parent().find('.option:icontains(' + suchstring + ')').show();
				});
				
			},
		};	
		$.fn.uicheckbox = function(method){					
			// Method calling logic
			if ( methods[method] ) {
				return methods[ method ].apply( this, Array.prototype.slice.call( arguments, 1 ));
			}
			else if ( typeof method === 'object' || ! method ) {
				return methods.init.apply( this, arguments );
			}
			else {
				$.error( 'Method ' +  method + ' does not exist on jMaBinso.uicheckbox' );
			}  		
		}
});