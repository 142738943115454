import _ from 'underscore';
import Backbone from 'backbone';
import ActionUserModel from './ActionUserModel';
import Globals from '../../../utils/Globals';

const ActionUserCollection = Backbone.Collection.extend({
    url: Globals.baseUrl + 'actions/getUserAction',
    model: ActionUserModel,
    can(code) {
        const found = this.find(model => model.get('code') === code);
        if (_.isUndefined(found)) {
            return false;
        }
        return true;
    },
    getMenuEntries() {
        const model = this;
        const menuEntries = [];
        model.each(action => {
            if (action.get('code').indexOf('index.') !== -1) {
                menuEntries.push(action);
            }
            if (action.get('code').indexOf('module.') !== -1) {
                // Get Modules to Menu
                _.each(Globals.user.get('modules'), item => {
                    if (action.get('code') === 'module.' + item.name) {
                        menuEntries.push(action);
                    }
                });
            }
        });
        return menuEntries;
    },
    getFirstLink() {
        const firstMenu = _.first(this.getMenuEntries());
        return firstMenu.get('code');
    }
});
export default ActionUserCollection;
