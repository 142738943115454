
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import InterfaceJobModel from '../models/InterfaceJobModel';
import AlertView from '../../app/views/AlertView';
import html from '../templates/plan_view.template';
import Globals from '../../../utils/Globals';

const PlanView = Backbone.View.extend({
    initialize(options) {
        this.options = options || [];
    },
    render() {
        const view = this;
        const etlName = _.isUndefined(view.options.name) ? 'AAA' : ': ' + view.options.name;

        const compiled = (_.template(html)({
            $,
            options: new InterfaceJobModel().everySelect,
            title: $._('interface.plan.title') + etlName,
            canchel: $._('dialog.cancel'),
            ok: $._('interface.plan.new.button.start'),
            sessionUser: Globals.user
        }));

        $.modal(
            compiled,
            {
                overlayId: 'simplemodal-overlay',
                minWidth: '680px',
                maxWidth: '680px',
                containerId: 'modalWidget',
                persist: true,
                overlayClose: false,
                onShow() {
                    // view.setElement("body");
                    view.setElement('.modalWidget');
                    view.$('#mabinso_start').datepicker({
                        changeMonth: true,
                        changeYear: true
                    });
                    view.$('#plan-report-settings').uiselectfield('init');
                    view.$('#plan-report-settings').uicheckbox('init');

                    view.$('#plan-report-settings .tags').tagsInput({
                        height: 'auto',
                        width: '222px',
                        defaultText: $._('wizzard.plan.form.label.email.more')
                    });

                    view.$('.timepicker').timepicker({
                        timeOnlyTitle: $._('wizzard.plan.form.timepicker.timeOnlyTitle'),
                        timeText: $._('wizzard.plan.form.timepicker.timeText'),
                        hourText: $._('wizzard.plan.form.timepicker.hourText'),
                        minuteText: $._('wizzard.plan.form.timepicker.minuteText'),
                        secondText: $._('wizzard.plan.form.timepicker.secondText'),
                        currentText: $._('wizzard.plan.form.timepicker.currentText'),
                        closeText: $._('wizzard.plan.form.timepicker.closeText')
                    });
                },
                onClose() {
                    $.modal.close();
                    view.$el.validationEngine('hideAll');
                }
            }
        );
    },
    events: {
        'click .modalYes': 'btSave'
    },
    btSave() {
        this.model.setForm(this.$('form'));
        this.model.save(formMessage => {
            if (formMessage.showErrors()) {
                $.modal.close();
                // Bestaetigungsfenster
                AlertView.show({
                    message: $._('interface.plan.success'),
                    title: $._('alert.action.success'),
                });
            }
        });
        return false;
    }
});
export default PlanView;
