import $ from 'jquery';
import _ from 'underscore';
import MabinsoModel from '../../app/models/MabinsoModel';
import Globals from '../../../utils/Globals';

class Model extends MabinsoModel {
    urlRoot = Globals.baseUrl + 'interfaces/category';
    get(key: string) {
        const that = this;
        const getValue = (key2: string) => {
            const value = MabinsoModel.prototype.get.call(that, key2);
            if (key === 'name') {
                return _.toName(value);
            }
            return value;
        };

        if (key.charAt(0) === '_') {
            key = key.substring(1);
            return $._(getValue(key));
        }
        return getValue(key);
    }
}
export default Model;
