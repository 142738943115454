import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import html from '../templates/alert_view.template';

const AlertView = Backbone.View.extend({
    el: 'div#hauptrahmen',
    initialize(options) {
        this.options = options || [];
        this.render();
    },
    render() {
        const view = this;
        let mstring = '';
        if (_.isString(view.options.html)) {
            mstring = view.options.html;
        } else {
            mstring = view.options.message;
        }

        const compiled = _.template(html)({
            $, title: _.isUndefined(view.options.title) ? $._('alert.action.success') : view.options.title, message: mstring, yes: $._('alert.action.ok')
        });

        $.modal(
            compiled,
            {
                overlayId: 'simplemodal-overlay',
                minHeight: '200px',
                minWidth: '400px',
                maxWidth: '400px',
                containerId: 'modalConfirm',
                overlayClose: false,
                onShow() {
                    view.setElement($('.simplemodal-container'));
                    view.delegateEvents(this.events);
                }
            }
        );
        return view;
    },
    yes() {
        const view = this;
        // call the callback
        if (_.isFunction(view.options.okFunc)) {
            view.options.okFunc();
        }
        // close the dialog
        $.modal.close();
    },
    events: {
        'click .modalYes': 'yes'
    }
}, {
    show: options => new AlertView(options)
});

export default AlertView;
