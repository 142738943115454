import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import html from '../templates/wait_view.template';

const WaitView = Backbone.View.extend({
    el: 'div#hauptrahmen',
    initialize(options) {
        // this.remove();
        this.options = options || [];
        this.render();
    },
    render() {
        const view = this;
        const compiled = _.template(html)({
            $,
            title: _.isUndefined(view.options.title) ? $._('alert.wait.title') : view.options.title,
            message: view.options.message,
            close: view.options.closeString ? view.options.closeString : false,
            closeHide: view.options.closeHide ? view.options.closeHide : false
        });
        $.modal(
            compiled,
            {
                overlayId: 'simplemodal-overlay',
                zIndex: 2001,
                minHeight: '200px',
                minWidth: '400px',
                maxWidth: '400px',
                containerId: 'modalWait',
                overlayClose: false,
                onShow() {
                    // var modal = this;
                    view.setElement($('.simplemodal-container'));
                    view.$('.icon').pnganimation('init', { time: 50 });
                }
            }
        );
        return view;
    },
    remove() {
        $.modal.close();
    },
    yes(event) {
        const ele = event.currentTarget;
        const id = $(ele).data('id');
        const view = this;
        // close the dialog
        this.remove();
        // call the callback
        if (_.isFunction(view.options.success)) {
            view.options.success(id);
        }
    },
    events: {
        'click .modalYes': 'yes'
    }
}, {
    show: options => new WaitView(options)
});
export default WaitView;
