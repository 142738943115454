import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import AlertView from '../../app/views/AlertView';
import ConfirmView from '../../app/views/ConfirmView';
import ActionModel from '../models/ActionModel';
import html from '../templates/detail_view.template';

const detailView = Backbone.View.extend({
    model: null,
    initialize(options) {
        this.options = options;
    },
    render(id) {
        this.$('form').validationEngine('hideAll');
        this.model = new ActionModel({ id });
        $.scrollTo('#hauptrahmen', 300);
        const view = this;
        $(this.el).spinner();
        if (_.isUndefined(id)) {
            view.renderTemplate(html);
        } else {
            view.model.fetch({
                success() {
                    view.renderTemplate(html);
                }
            });
        }
    },
    renderTemplate() {
        const view = this;
        view.options.listView.$('div.action-content-detail').html(_.template(html)({ model: view.model, $ }));
        view.setElement('#action-detail-view');
        view.$el.fadeIn();
    },
    events: {
        'click .actionsave': 'saveAction',
        'click #delete-action': 'deleteAction'
    },
    saveAction() {
        this.model.setForm(this.$('form'));
        const view = this;
        this.model.save(formMessage => {
            if (formMessage.showErrors()) {
                AlertView.show({
                    message: $._('action.alert.save.success'),
                    okFunc() {
                        if (_.isUndefined(view.model.id)) {
                            view.options.listView.render();
                        } else {
                            view.render(view.model.id);
                        }
                    }
                });
            }
        });
        return false;
    },
    deleteAction() {
        const view = this;
        ConfirmView.show({
            message: $._('action.message.delete'),
            title: $._('alert.action.delete'),
            yesFunc() {
                view.model.destroy({
                    success(mode, data) {
                        AlertView.show({
                            message: data.hasErrors ? $._('action.alert.delete.nosuccess', data.errors[0].message) : $._('action.alert.delete.success'),
                            title: data.hasErrors ? $._('alert.action.nosuccess') : $._('alert.action.success'),
                            okFunc() {
                                if (!data.hasErrors) {
                                    view.options.listView.render();
                                }
                            }
                        });
                    }
                });
            }
        });
    }
});
export default detailView;
